<template>
    <Card class="p-my-3">
        <template #title>
            <i class="pi pi-circle-on p-mr-2" v-if="!notification.isRead"/>
            <h2>{{ summary }}</h2>
        </template>
        <template #content>
            <div class="p-text-italic">{{ prettyDate }}</div>
            <j-notification-details :details="details"/>
        </template>
        <template v-if="button" #footer>
            <j-notification-button :notification="notification"/>
        </template>
    </Card>
</template>

<script>
import { onUnmounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import JNotificationButton from "@/components/notification/JNotificationButton.vue";
import JNotificationDetails from "@/components/notification/JNotificationDetails.vue";
import getNotificationDisplayData from "@/utils/notificationsDisplayData";
import renderDate from "@/utils/prettyDate";

export default {
    components: { JNotificationButton, JNotificationDetails, Card },
    name: "JNotification",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { summary, details, button } = getNotificationDisplayData(props.notification);
        const prettyDate = renderDate(props.notification.createdAt);

        const store = useStore();
        onUnmounted(() => store.dispatch("markAsRead", props.notification));

        return {
            summary,
            details,
            button,
            prettyDate,
        };
    },
};
</script>

<style scoped lang="scss">
    @import "src/assets/scss/theme/_variables";
    .pi-circle-on {
        color: $primaryColor;
    }

    h2 {
        display: inline;
        font-size: inherit;
        font-weight: inherit;
        margin: inherit;
        margin-bottom: 0;
    }

    .p-card {
        background-color: #FAFAFA;

        ::v-deep(.p-card-content) {
            padding: 0;
        }

        ::v-deep(.p-card-footer) {
            text-align: right;
        }
    }
</style>
