<template>
    <Dialog header="Scritture" :visible="visible" @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)" :draggable="false"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid" :dismissableMask="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <JOrderList :modelValue="kanji" @update:modelValue="$emit('update:kanji', $event)"
                    :emptyItem="emptyKanji" :disabled="disabled || reviewing"
                    :enableInsert="!disabled && !reviewing" addButtonLabel="Aggiungi scrittura"
                    keyField="_id" @emptyItemAdded="onEmptyKanjiAdded">
            <template #description>
                <p>
                    Inserisci qui le scritture del termine.
                    La prima è considerata la scrittura principale e verrà mostrata in grande,
                    mentre le altre sono considerate come alternative.
                    Questa sezione è modificabile solo se il termine non proviene da JMdict.
                </p>
            </template>
            <template #header="slotProps">
                <h3>{{ renderTitle(slotProps.item, slotProps.index) }}</h3>
            </template>
            <template #item="slotProps">
                <JKanjiEditor :modelValue="slotProps.item"
                              @update:modelValue="onKanjiEdited($event, slotProps.key)"
                              :itemKey="slotProps.key" :disabled="disabled || reviewing"/>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!disabled && !reviewing ? 'Salva' : 'Chiudi'"
                    :icon="!disabled && !reviewing ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled && !reviewing }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { computed, ref } from "vue";
import JKanjiEditor from "@/components/admin/entries/JKanjiEditor.vue";
import JOrderList from "@/components/JOrderList.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import useVuelidate from "@vuelidate/core";

export default {
    name: "JKanjiListEditor",
    components: {
        Button,
        Dialog,
        JKanjiEditor,
        JOrderList,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        reviewing: {
            type: Boolean,
            default: false,
        },
        kanji: {
            type: Object,
            required: true,
        },
        tempId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:kanji", "update:visible", "updateTempId", "onDialogClose"],
    setup(props, { emit }) {
        const entry = ref(props.modelValue);

        const v$ = useVuelidate();

        const emptyKanji = computed(() => ({
            _id: props.tempId,
            term: null,
            orthographicInfo: [],
            priorities: [],
        }));

        const renderTitle = (kanji, index) => ((kanji.term ?? "") !== ""
            ? kanji.term
            : `Scrittura ${index + 1}`);

        const onEmptyKanjiAdded = () => {
            // We added a new element and we need to tell the EntryDetail component to give us
            // a new fresh temporary ID (we'll get it from the updated tempId prop)
            emit("updateTempId");
        };
        const onKanjiEdited = (newKanji, key) => {
            const clonedKanji = cloneDeep(props.kanji);
            const foundKanji = props.kanji.find((kanji) => kanji._id === key);
            const foundKanjiIndex = props.kanji.indexOf(foundKanji);
            clonedKanji[foundKanjiIndex] = newKanji;
            emit("update:kanji", clonedKanji);
        };

        return {
            v$,
            entry,
            emptyKanji,
            renderTitle,
            onEmptyKanjiAdded,
            onKanjiEdited,
        };
    },
};
</script>

<style lang="scss" scoped>
.p-fluid .p-button {
    width: unset;
}
</style>
