<template>
    <Dialog :header="dialogTitle" v-model:visible="isDialogVisible" :closable="false"
            :modal="true">
        <p> {{ dialogText }}</p>
        <template #footer>
            <Button label="Ok" @click="isDialogVisible = false"
                    class="p-button-text" autofocus/>
        </template>
    </Dialog>

    <Message severity="warn" v-if="!state.isActive" :closable="false">
        <p class="p-pl-6 p-pr-0 p-py-0">Per poter utilizzare il profilo è necessario cliccare
            sul link inviato al tuo indirizzo di posta elettronica. <br />
            Se non ti è arrivata la email, clicca sul pulsante sottostante per generarne una
            nuova.</p>
        <Button label="Invia nuovamente la email di conferma" v-on:click.prevent="generateToken"
                :loading="isTokenGenerationLoading"
                class="p-button-warning p-mx-auto p-mt-2
                p-d-block"/>
    </Message>

    <Card class="p-p-3">
        <template #title>
            <h1 class="p-my-0">Profilo</h1>
        </template>
        <template #content>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="email" class="form-label p-text-bold">Email</label>
                    <InputText id="email"
                               :class="{ 'p-invalid': v$.email.$invalid && isSubmitted }"
                               type="text" v-model="v$.email.$model"
                               :disabled="isInputDisabled"/>
                    <small class="p-error p-d-block"
                           v-if="((v$.email.$error || v$.email.$invalid) && isSubmitted)">
                        Email inserita non valida
                    </small>
                </div>

                <div class="p-field p-col-12 p-md-6">
                    <label for="name" class="form-label p-text-bold">Nome</label>
                    <InputText id="name" type="text" autocomplete="given-name"
                               :class="{ 'p-invalid': v$.firstName.$invalid &&
                               isSubmitted }" :disabled="isInputDisabled || !state.isActive"
                               v-model="v$.firstName.$model" />
                    <small v-if="v$.firstName.$invalid && isSubmitted"
                           class="p-error p-d-block">
                        Il campo è obbligatorio
                    </small>
                </div>

                <div class="p-field p-col-12 p-md-6">
                    <label for="surname" class="form-label p-text-bold">Cognome</label>
                    <InputText
                        id="surname" type="text" autocomplete="family-name"
                        v-model="v$.lastName.$model" :disabled="isInputDisabled || !state.isActive"
                        :class="{ 'p-invalid': v$.lastName.$invalid && isSubmitted }" />
                    <small v-if="v$.lastName.$invalid && isSubmitted"
                           class="p-error p-d-block">
                        Il campo è obbligatorio
                    </small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="region" class="form-label p-text-bold">Regione</label>
                    <Dropdown :options="regions" optionLabel="name" optionValue="value"
                          placeholder="Nessuna regione specificata"
                          :disabled="isInputDisabled || !state.isActive" id="region"
                          :filter="true" v-model="v$.region.$model"/>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="university" class="form-label p-text-bold">Università</label>
                    <Dropdown :options="universities" optionLabel="name" optionValue="name"
                              placeholder="Nessuna università specificata"
                              :disabled="isInputDisabled || !state.isActive" id="university"
                              :filter="true" v-model="v$.university.$model"
                              :class="{ 'p-invalid': v$.university.$invalid && isSubmitted }" />
                    <small v-if="v$.university.$invalid && isSubmitted"
                           class="p-error p-d-block">
                        Il campo è obbligatorio
                    </small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="badgeNumber" class="form-label p-text-bold">Matricola</label>
                    <InputText id="badgeNumber" v-model="v$.badgeNumber.$model"
                               :disabled="isInputDisabled || !state.isActive"
                               placeholder="Nessuna matricola specificata"
                               :class="{ 'p-invalid': v$.badgeNumber.$invalid && isSubmitted }" />
                    <small v-if="v$.badgeNumber.$invalid && isSubmitted"
                           class="p-error p-d-block">
                        Il campo è obbligatorio
                    </small>
                </div>
                <div class="p-field p-col-12 p-d-flex p-ai-center">
                    <InputSwitch id="wantsSerif" v-model="v$.wantsSerif.$model"
                                 :disabled="isInputDisabled || !state.isActive"/>
                    <label for="wantsSerif" class="p-ml-3">
                        Testo giapponese con grazie?
                    </label>
                </div>
            </div>
            <div class="p-text-right">
                <Button v-bind:label="modifyProfileButtonLabel" class="p-m-2"
                        :icon="modifyProfileButtonIcon"
                        :class="modifyProfileButtonClass"
                        v-on:click.prevent="modifyProfile(!v$.$invalid)"
                        :loading="isEditLoading"/>
                <Button label="Modifica Password" class="p-m-2"
                        :disabled="!state.isActive" icon="pi pi-pencil"
                        v-on:click.prevent="resetPassword" :loading="isPasswordLoading"/>
            </div>
            <div v-if="state.role !== 'user'">
                <Divider />
                <j-profile-statistics v-if="isChartVisible" :user-stats="statistics"
                                      :user-role="state.role"/>
                <p class="p-my-6 p-text-center" v-else-if="errorMessage">{{ errorMessage }}</p>
            </div>
        </template>
    </Card>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { email, required, requiredIf } from "@vuelidate/validators";
import Button from "primevue/button";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import Message from "primevue/message";
import JProfileStatistics from "@/components/JProfileStatistics.vue";
import Universities from "@/enums/universities";
import Regions from "@/enums/regions";

export default {
    name: "JProfile",
    components: {
        Button,
        Card,
        Dropdown,
        Dialog,
        Divider,
        InputText,
        InputSwitch,
        Message,
        JProfileStatistics,
    },
    setup() {
        const store = useStore();
        const isEditLoading = ref(false);
        const isPasswordLoading = ref(false);
        const isTokenGenerationLoading = ref(false);

        const dialogText = ref("");
        const dialogTitle = ref("");
        const isDialogVisible = ref(false);

        const editLabel = "Modifica Profilo";
        const saveLabel = "Salva";

        const universities = ref(Object.values(Universities));
        const regions = ref(Object.values(Regions));
        const user = store.getters.loggedUser;

        const state = reactive({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            wantsSerif: !!user.wantsSerif,
            university: user.university,
            region: user.region,
            isActive: user.isActive,
            role: user.role,
            badgeNumber: user.badgeNumber,
        });

        const rules = {
            email: { required, email },
            firstName: { required },
            lastName: { required },
            wantsSerif: { required },
            region: {},
            university: {
                required: requiredIf(() => state.badgeNumber !== null
                    && state.badgeNumber !== undefined && state.role !== "user"),
            },
            role: {},
            isActive: {},
            badgeNumber: {
                required: requiredIf(() => state.university !== ""
                    && state.university !== undefined),
            },
        };

        const isSubmitted = ref(false);
        const isInputDisabled = ref(true);

        const v$ = useVuelidate(rules, state);
        const modifyProfileButtonLabel = ref(editLabel);
        const modifyProfileButtonIcon = ref("pi pi-pencil");
        const modifyProfileButtonClass = ref("p-button-primary");

        const showDialogError = (text) => {
            dialogText.value = text;
            dialogTitle.value = "Errore";
            isDialogVisible.value = true;
        };
        const showDialogConfirmation = (message) => {
            dialogText.value = message;
            dialogTitle.value = "Conferma";
            isDialogVisible.value = true;
        };

        const generateToken = async () => {
            isTokenGenerationLoading.value = true;
            try {
                await store.dispatch("generateConfirmationToken");
                showDialogConfirmation("Email inviata con successo");
            } catch (err) {
                showDialogError();
            } finally {
                isTokenGenerationLoading.value = false;
            }
        };
        const modifyProfile = async (isInputValid) => {
            isSubmitted.value = true;
            if (modifyProfileButtonLabel.value === editLabel) {
                modifyProfileButtonLabel.value = saveLabel;
                modifyProfileButtonIcon.value = "pi pi-save";
                modifyProfileButtonClass.value = "p-button-success";
                isInputDisabled.value = false;
            } else if (modifyProfileButtonLabel.value === saveLabel && isInputValid) {
                isEditLoading.value = true;
                try {
                    await store.dispatch("updateProfile", state);
                    showDialogConfirmation("Modifica avvenuta con successo");
                } catch (err) {
                    if (err.response.data.error.includes("Email not valid")) {
                        showDialogError("La mail inserita non è valida");
                    } else if (err.response.data.error.includes("University")) {
                        showDialogError("L'università inserita non è valida");
                    } else {
                        showDialogError("Si è verificato un errore, riprova più tardi");
                    }
                } finally {
                    modifyProfileButtonLabel.value = editLabel;
                    modifyProfileButtonIcon.value = "pi pi-pencil";
                    modifyProfileButtonClass.value = "p-button-primary";
                    isInputDisabled.value = true;
                    isEditLoading.value = false;
                }
            }
        };
        const resetPassword = async () => {
            isPasswordLoading.value = true;
            try {
                await store.dispatch("generatePasswordToken", state.email);
                showDialogConfirmation("Ti verrà recapitata una mail contenente il link necessario per cambiare la password");
            } catch (err) {
                showDialogError("Si è verificato un errore, riprova più tardi");
            } finally {
                isPasswordLoading.value = false;
            }
        };

        const isChartVisible = ref(false);
        const statistics = ref("");
        const errorMessage = ref("");
        onMounted(async () => {
            try {
                if (state.role !== "user") {
                    const res = await store.dispatch("getUserStatistics");
                    statistics.value = res.data;
                    isChartVisible.value = true;
                }
            } catch (err) {
                console.log(err);
                errorMessage.value = "Si è verificato un errore durante il caricamento delle statistiche, "
                        + "prova ad aggiornare la pagina.";
            }
        });

        return {
            v$,
            isSubmitted,
            isInputDisabled,

            isDialogVisible,
            dialogTitle,
            dialogText,

            isPasswordLoading,
            isTokenGenerationLoading,
            isEditLoading,

            modifyProfile,
            resetPassword,
            modifyProfileButtonLabel,
            modifyProfileButtonIcon,
            modifyProfileButtonClass,
            state,
            regions,
            universities,
            generateToken,

            isChartVisible,
            errorMessage,
            statistics,
        };
    },
};
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 2rem;
    }
</style>
