<template>
    <template v-for="part in details">
        <template v-if="part.type === 'text'">{{ part.text }}</template>
        <router-link v-else-if="part.type === 'link'"
                     :to="part.route" :key="part.route">
            {{ part.label }}
        </router-link>
    </template>
</template>

<script>
export default {
    name: "JNotificationDetails",
    props: {
        details: {
            type: Array,
            required: true,
            default() {
                return [];
            },
        },
    },
};
</script>
