/* eslint-disable lines-between-class-members */
/**
 * A Paginator that handles the details of loading new elements upon request.
 */
class Paginator {
    #getter;
    #page;
    #isOver;

    /**
     * @param getter a getter that takes as parameters a page number and, optionally, a number
     *               of elements to skip.
     */
    constructor(getter) {
        this.#getter = getter;
        this.#page = 0;
        this.#isOver = false;
    }

    /**
     * @returns true if there are no more elements to download
     */
    get isOver() {
        return this.#isOver;
    }

    /**
     * Resets the state of the paginator
     */
    reset() {
        this.#page = 0;
        this.#isOver = false;
    }

    /**
     * Loads and returns te next batch of elements to be loaded
     * @param skip a number of elements to skip in the pagination process
     * @returns the loaded elements (or an empty array if the elements are over)
     */
    async loadNext(skip) {
        if (this.#isOver) {
            return [];
        }
        const result = await this.#getter(this.#page + 1, skip);
        if (result.length === 0) {
            this.#isOver = true;
        } else {
            this.#page += 1;
        }
        return result;
    }
}

export default {
    from(getter) {
        return new Paginator(getter);
    },
};
