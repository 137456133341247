<template>
    <Button icon="pi pi-plus" label="Nuova collezione" class="p-button-success"
            @click="showCreationDialog" :loading="isLoading"/>
    <j-simple-popup-form
        v-model:visible="isCreationDialogVisible" id="creationForm" header="Scegli un nome"
        placeholder="Nome della collezione" submitLabel="Crea" submitIcon="pi pi-plus"
        errorMessagge="Devi specificare un nome" @submit="createCollection"/>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import Button from "primevue/button";
import JSimplePopupForm from "@/components/JSimplePopupForm.vue";

export default {
    name: "JCollectionCreationButton",
    components: { JSimplePopupForm, Button },
    emits: ["newCollection", "creationError"],
    setup(props, { emit }) {
        const store = useStore();
        const isLoading = ref(false);
        const createCollection = async (name) => {
            try {
                isLoading.value = true;
                const _id = await store.dispatch("createCollection", name);
                emit("newCollection", { _id, name });
            } catch (error) {
                emit("creationError");
            } finally {
                isLoading.value = false;
            }
        };
        const isCreationDialogVisible = ref(false);
        const showCreationDialog = () => {
            isCreationDialogVisible.value = true;
        };
        return {
            createCollection,
            isLoading,
            isCreationDialogVisible,
            showCreationDialog,
        };
    },
};
</script>

<style scoped lang="scss">
    .p-button-success {
        width: 100%;
    }
</style>
