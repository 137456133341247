const LanguageCode = {
    AFA: {
        label: "Lingue Afro-Asiatiche (Altre)",
        value: "afa",
    },
    AFH: {
        label: "Afrihili",
        value: "afh",
    },
    AFR: {
        label: "Afrikaans",
        value: "afr",
    },
    AIN: {
        label: "Ainu",
        value: "ain",
    },
    AKA: {
        label: "Akan",
        value: "aka",
    },
    AKK: {
        label: "Akkadian",
        value: "akk",
    },
    ALB: {
        label: "Albanese",
        value: "alb",
    },
    ALE: {
        label: "Aleut",
        value: "ale",
    },
    ALG: {
        label: "Lingue algonquine",
        value: "alg",
    },
    ALT: {
        label: "Altaiche meridionali",
        value: "alt",
    },
    AMH: {
        label: "Amarico",
        value: "amh",
    },
    ANG: {
        label: "Inglese Antico (ca. 450-1100)",
        value: "ang",
    },
    APA: {
        label: "Apache",
        value: "apa",
    },
    ARA: {
        label: "Arabo",
        value: "ara",
    },
    ARC: {
        label: "Aramaico",
        value: "arc",
    },
    ARG: {
        label: "Aragonese",
        value: "arg",
    },
    ARM: {
        label: "Armeno",
        value: "arm",
    },
    ARN: {
        label: "Araucaniano",
        value: "arn",
    },
    ARP: {
        label: "Arapaho",
        value: "arp",
    },
    ART: {
        label: "Artificiale (Altre)",
        value: "art",
    },
    ARW: {
        label: "Arawak",
        value: "arw",
    },
    ASM: {
        label: "Assamese",
        value: "asm",
    },
    AST: {
        label: "Asturiano (Bable)",
        value: "ast",
    },
    ATH: {
        label: "Lingue Athapascane",
        value: "ath",
    },
    AUS: {
        label: "Lingue Aborigene Australiane",
        value: "aus",
    },
    AVA: {
        label: "Avarico",
        value: "ava",
    },
    AVE: {
        label: "Avestano",
        value: "ave",
    },
    AWA: {
        label: "Awadhi",
        value: "awa",
    },
    AYM: {
        label: "Aymara",
        value: "aym",
    },
    AZE: {
        label: "Azero",
        value: "aze",
    },
    BAD: {
        label: "Banda",
        value: "bad",
    },
    BAI: {
        label: "Lingue Bamileke",
        value: "bai",
    },
    BAK: {
        label: "Bashkir",
        value: "bak",
    },
    BAL: {
        label: "Baluchi",
        value: "bal",
    },
    BAM: {
        label: "Bambara",
        value: "bam",
    },
    BAN: {
        label: "Balinese",
        value: "ban",
    },
    BAQ: {
        label: "Basco",
        value: "baq",
    },
    BAS: {
        label: "Basa",
        value: "bas",
    },
    BAT: {
        label: "Baltiche (Altre)",
        value: "bat",
    },
    BEJ: {
        label: "Beja",
        value: "bej",
    },
    BEL: {
        label: "Bielorusso",
        value: "bel",
    },
    BEM: {
        label: "Bemba",
        value: "bem",
    },
    BEN: {
        label: "Bengali",
        value: "ben",
    },
    BER: {
        label: "Berbere (Altre)",
        value: "ber",
    },
    BHO: {
        label: "Bhojpuri",
        value: "bho",
    },
    BIH: {
        label: "Bihari",
        value: "bih",
    },
    BIK: {
        label: "Bikol",
        value: "bik",
    },
    BIN: {
        label: "Bini",
        value: "bin",
    },
    BIS: {
        label: "Bislama",
        value: "bis",
    },
    BLA: {
        label: "Siksika",
        value: "bla",
    },
    BNT: {
        label: "Bantu (Altre)",
        value: "bnt",
    },
    BOS: {
        label: "Bosniaco",
        value: "bos",
    },
    BRA: {
        label: "Braj",
        value: "bra",
    },
    BRE: {
        label: "Bretone",
        value: "bre",
    },
    BTK: {
        label: "Batak (Indonesia)",
        value: "btk",
    },
    BUA: {
        label: "Buriat",
        value: "bua",
    },
    BUG: {
        label: "Buginese",
        value: "bug",
    },
    BUL: {
        label: "Bulgaro",
        value: "bul",
    },
    BUR: {
        label: "Burmese",
        value: "bur",
    },
    BYN: {
        label: "Blin",
        value: "byn",
    },
    CAD: {
        label: "Caddo",
        value: "cad",
    },
    CAI: {
        label: "Lingue Native Centro Americane (Altre)",
        value: "cai",
    },
    CAR: {
        label: "Carib",
        value: "car",
    },
    CAT: {
        label: "Catalano",
        value: "cat",
    },
    CAU: {
        label: "Caucaso (Altre)",
        value: "cau",
    },
    CEB: {
        label: "Cebuano",
        value: "ceb",
    },
    CEL: {
        label: "Celtiche (Altre)",
        value: "cel",
    },
    CHA: {
        label: "Chamorro",
        value: "cha",
    },
    CHB: {
        label: "Chibcha",
        value: "chb",
    },
    CHE: {
        label: "Ceceno",
        value: "che",
    },
    CHG: {
        label: "Chagatai",
        value: "chg",
    },
    CHI: {
        label: "Cinese",
        value: "chi",
    },
    CHK: {
        label: "Chuukese",
        value: "chk",
    },
    CHM: {
        label: "Mari",
        value: "chm",
    },
    CHN: {
        label: "Chinook jargon",
        value: "chn",
    },
    CHO: {
        label: "Choctaw",
        value: "cho",
    },
    CHP: {
        label: "Chipewyan",
        value: "chp",
    },
    CHR: {
        label: "Cherokee",
        value: "chr",
    },
    CHU: {
        label: "Slavo liturgico",
        value: "chu",
    },
    CHV: {
        label: "Chuvash",
        value: "chv",
    },
    CHY: {
        label: "Cheyenne",
        value: "chy",
    },
    CMC: {
        label: "Lingue Chamiche",
        value: "cmc",
    },
    COP: {
        label: "Copto",
        value: "cop",
    },
    COR: {
        label: "Cornico",
        value: "cor",
    },
    COS: {
        label: "Corso",
        value: "cos",
    },
    CPE: {
        label: "Creole e pidgin, basati sull'inglese (Altre)",
        value: "cpe",
    },
    CPF: {
        label: "Creole e pidgin, basati sul francese (Altre)",
        value: "cpf",
    },
    CPP: {
        label: "Creole e pidgin, basati sul portoghese (Altre)",
        value: "cpp",
    },
    CRE: {
        label: "Cree",
        value: "cre",
    },
    CRH: {
        label: "Tataro della Crimea",
        value: "crh",
    },
    CRP: {
        label: "Creole e pidgin (Altre)",
        value: "crp",
    },
    CSB: {
        label: "Casciubo",
        value: "csb",
    },
    CZE: {
        label: "Ceco",
        value: "cze",
    },
    DAK: {
        label: "Dakota",
        value: "dak",
    },
    DAN: {
        label: "Danese",
        value: "dan",
    },
    DAR: {
        label: "Dargwa",
        value: "dar",
    },
    DAY: {
        label: "Dayak",
        value: "day",
    },
    DEL: {
        label: "Delaware",
        value: "del",
    },
    DEN: {
        label: "Slave (Athapascan)",
        value: "den",
    },
    DGR: {
        label: "Dogrib",
        value: "dgr",
    },
    DIN: {
        label: "Dinka",
        value: "din",
    },
    DIV: {
        label: "Divehi",
        value: "div",
    },
    DOI: {
        label: "Dogri",
        value: "doi",
    },
    DRA: {
        label: "Dravidiche (Altre)",
        value: "dra",
    },
    DUA: {
        label: "Duala",
        value: "dua",
    },
    DUM: {
        label: "Olandese, Medio (ca.1050-1350)",
        value: "dum",
    },
    DUT: {
        label: "Olandese",
        value: "dut",
    },
    DYU: {
        label: "Dyula",
        value: "dyu",
    },
    DZO: {
        label: "Dzongkha",
        value: "dzo",
    },
    EFI: {
        label: "Efik",
        value: "efi",
    },
    EGY: {
        label: "Egiziano (Antico)",
        value: "egy",
    },
    EKA: {
        label: "Ekajuk",
        value: "eka",
    },
    ELX: {
        label: "Elamite",
        value: "elx",
    },
    ENG: {
        label: "Inglese",
        value: "eng",
    },
    ENM: {
        label: "Inglese, Medio (1100-1500)",
        value: "enm",
    },
    EPO: {
        label: "Esperanto",
        value: "epo",
    },
    EST: {
        label: "Estone",
        value: "est",
    },
    EWE: {
        label: "Ewe",
        value: "ewe",
    },
    EWO: {
        label: "Ewondo",
        value: "ewo",
    },
    FAN: {
        label: "Fang",
        value: "fan",
    },
    FAO: {
        label: "Faroese",
        value: "fao",
    },
    FAT: {
        label: "Fanti",
        value: "fat",
    },
    FIJ: {
        label: "Fijian",
        value: "fij",
    },
    FIL: {
        label: "Filippino",
        value: "fil",
    },
    FIN: {
        label: "Finlandese",
        value: "fin",
    },
    FIU: {
        label: "Ugro-finnico (Altre)",
        value: "fiu",
    },
    FON: {
        label: "Fon",
        value: "fon",
    },
    FRE: {
        label: "Francese",
        value: "fre",
    },
    FRM: {
        label: "Francese Medio (ca.1400-1600)",
        value: "frm",
    },
    FRO: {
        label: "Francese Antico (842-ca.1400)",
        value: "fro",
    },
    FRY: {
        label: "Frisone",
        value: "fry",
    },
    FUL: {
        label: "Fulah",
        value: "ful",
    },
    FUR: {
        label: "Friulano",
        value: "fur",
    },
    GAA: {
        label: "Ga",
        value: "gaa",
    },
    GAY: {
        label: "Gayo",
        value: "gay",
    },
    GBA: {
        label: "Gbaya",
        value: "gba",
    },
    GEM: {
        label: "Germaniche (Altre)",
        value: "gem",
    },
    GEO: {
        label: "Georgiano",
        value: "geo",
    },
    GER: {
        label: "Tedesco",
        value: "ger",
    },
    GEZ: {
        label: "Geez",
        value: "gez",
    },
    GIL: {
        label: "Gilbertese",
        value: "gil",
    },
    GLA: {
        label: "Gaelico",
        value: "gla",
    },
    GLE: {
        label: "Gaelico irlandese",
        value: "gle",
    },
    GLG: {
        label: "Galiziano",
        value: "glg",
    },
    GLV: {
        label: "Manx",
        value: "glv",
    },
    GMH: {
        label: "Tedesco Medio Alto (ca.1050-1500)",
        value: "gmh",
    },
    GOH: {
        label: "Tedesco Antico Alto (ca.750-1050)",
        value: "goh",
    },
    GON: {
        label: "Gondi",
        value: "gon",
    },
    GOR: {
        label: "Gorontalo",
        value: "gor",
    },
    GOT: {
        label: "Gotico",
        value: "got",
    },
    GRB: {
        label: "Grebo",
        value: "grb",
    },
    GRC: {
        label: "Greco antico",
        value: "grc",
    },
    GRE: {
        label: "Greco moderno",
        value: "gre",
    },
    GRN: {
        label: "Guarani",
        value: "grn",
    },
    GUJ: {
        label: "Gujarati",
        value: "guj",
    },
    GWI: {
        label: "Gwich´in",
        value: "gwi",
    },
    HAI: {
        label: "Haida",
        value: "hai",
    },
    HAT: {
        label: "Haitiano",
        value: "hat",
    },
    HAU: {
        label: "Hausa",
        value: "hau",
    },
    HAW: {
        label: "Hawaiiano",
        value: "haw",
    },
    HEB: {
        label: "Ebraico",
        value: "heb",
    },
    HER: {
        label: "Herero",
        value: "her",
    },
    HIL: {
        label: "Hiligaynon",
        value: "hil",
    },
    HIM: {
        label: "Himachali",
        value: "him",
    },
    HIN: {
        label: "Hindi",
        value: "hin",
    },
    HIT: {
        label: "Hittite",
        value: "hit",
    },
    HMN: {
        label: "Hmong",
        value: "hmn",
    },
    HMO: {
        label: "Hiri Motu",
        value: "hmo",
    },
    HRV: {
        label: "Croato",
        value: "hrv",
    },
    HUN: {
        label: "Ungherese",
        value: "hun",
    },
    HUP: {
        label: "Hupa",
        value: "hup",
    },
    IBA: {
        label: "Iban",
        value: "iba",
    },
    IBO: {
        label: "Igbo",
        value: "ibo",
    },
    ICE: {
        label: "Islandese",
        value: "ice",
    },
    IDO: {
        label: "Ido",
        value: "ido",
    },
    III: {
        label: "Sichuan Yi",
        value: "iii",
    },
    IJO: {
        label: "Ijo",
        value: "ijo",
    },
    IKU: {
        label: "Inuktitut",
        value: "iku",
    },
    ILE: {
        label: "Interlingue",
        value: "ile",
    },
    ILO: {
        label: "Iloko",
        value: "ilo",
    },
    INA: {
        label: "Interlingua (International Auxiliary Language Association)",
        value: "ina",
    },
    INC: {
        label: "Indoariane (Altre)",
        value: "inc",
    },
    IND: {
        label: "Indonesiano",
        value: "ind",
    },
    INE: {
        label: "Indo-Europee (Altre)",
        value: "ine",
    },
    INH: {
        label: "Ingush",
        value: "inh",
    },
    IPK: {
        label: "Inupiaq",
        value: "ipk",
    },
    IRA: {
        label: "Iraniane (Altre)",
        value: "ira",
    },
    IRO: {
        label: "Lingue Iroquoiane ",
        value: "iro",
    },
    ISL: {
        label: "Islandese",
        value: "isl",
    },
    ITA: {
        label: "Italiano",
        value: "ita",
    },
    JAV: {
        label: "Giavanese",
        value: "jav",
    },
    JPN: {
        label: "Giapponese",
        value: "jpn",
    },
    JPR: {
        label: "Giudeo-Persiano",
        value: "jpr",
    },
    JRB: {
        label: "Giudeo-Arabo",
        value: "jrb",
    },
    KAA: {
        label: "Kara-Kalpak",
        value: "kaa",
    },
    KAB: {
        label: "Cabilo",
        value: "kab",
    },
    KAC: {
        label: "Kachin",
        value: "kac",
    },
    KAL: {
        label: "Kalaallisut",
        value: "kal",
    },
    KAM: {
        label: "Kamba",
        value: "kam",
    },
    KAN: {
        label: "Kannada",
        value: "kan",
    },
    KAR: {
        label: "Karen",
        value: "kar",
    },
    KAS: {
        label: "Kashmiri",
        value: "kas",
    },
    KAU: {
        label: "Kanuri",
        value: "kau",
    },
    KAW: {
        label: "Kawi",
        value: "kaw",
    },
    KAZ: {
        label: "Kazakh",
        value: "kaz",
    },
    KBD: {
        label: "Kabardiano",
        value: "kbd",
    },
    KHA: {
        label: "Khasi",
        value: "kha",
    },
    KHI: {
        label: "Khoisan (Altre)",
        value: "khi",
    },
    KHM: {
        label: "Khmer",
        value: "khm",
    },
    KHO: {
        label: "Khotanese",
        value: "kho",
    },
    KIK: {
        label: "Kikuyu",
        value: "kik",
    },
    KIN: {
        label: "Kinyarwanda",
        value: "kin",
    },
    KIR: {
        label: "Kirghiso",
        value: "kir",
    },
    KMB: {
        label: "Kimbundu",
        value: "kmb",
    },
    KOK: {
        label: "Konkani",
        value: "kok",
    },
    KOM: {
        label: "Komi",
        value: "kom",
    },
    KON: {
        label: "Kongo",
        value: "kon",
    },
    KOR: {
        label: "Coreano",
        value: "kor",
    },
    KOS: {
        label: "Kosraean",
        value: "kos",
    },
    KPE: {
        label: "Kpelle",
        value: "kpe",
    },
    KRC: {
        label: "Karachay-Balkar",
        value: "krc",
    },
    KRO: {
        label: "Kru",
        value: "kro",
    },
    KRU: {
        label: "Kurukh",
        value: "kru",
    },
    KUA: {
        label: "Kuanyama",
        value: "kua",
    },
    KUM: {
        label: "Kumyk",
        value: "kum",
    },
    KUR: {
        label: "Curdo",
        value: "kur",
    },
    KUT: {
        label: "Kutenai",
        value: "kut",
    },
    LAD: {
        label: "Ladino",
        value: "lad",
    },
    LAH: {
        label: "Lahnda",
        value: "lah",
    },
    LAM: {
        label: "Lamba",
        value: "lam",
    },
    LAO: {
        label: "Lao",
        value: "lao",
    },
    LAT: {
        label: "Latino",
        value: "lat",
    },
    LAV: {
        label: "Lettone",
        value: "lav",
    },
    LEZ: {
        label: "Lezghian",
        value: "lez",
    },
    LIM: {
        label: "Limburghese",
        value: "lim",
    },
    LIN: {
        label: "Lingala",
        value: "lin",
    },
    LIT: {
        label: "Lituano",
        value: "lit",
    },
    LOL: {
        label: "Mongo",
        value: "lol",
    },
    LOZ: {
        label: "Lozi",
        value: "loz",
    },
    LTZ: {
        label: "Lussemburghese",
        value: "ltz",
    },
    LUA: {
        label: "Luba-Lulua",
        value: "lua",
    },
    LUB: {
        label: "Luba-Katanga",
        value: "lub",
    },
    LUG: {
        label: "Ganda",
        value: "lug",
    },
    LUI: {
        label: "Luiseno",
        value: "lui",
    },
    LUN: {
        label: "Lunda",
        value: "lun",
    },
    LUO: {
        label: "Luo (Kenya e Tanzania)",
        value: "luo",
    },
    LUS: {
        label: "Lushai",
        value: "lus",
    },
    MAC: {
        label: "Macedone",
        value: "mac",
    },
    MAD: {
        label: "Madurese",
        value: "mad",
    },
    MAG: {
        label: "Magahi",
        value: "mag",
    },
    MAH: {
        label: "Marshallese",
        value: "mah",
    },
    MAI: {
        label: "Maithili",
        value: "mai",
    },
    MAK: {
        label: "Maksar",
        value: "mak",
    },
    MAL: {
        label: "Malayalam",
        value: "mal",
    },
    MAN: {
        label: "Mandingo",
        value: "man",
    },
    MAO: {
        label: "Maori",
        value: "mao",
    },
    MAP: {
        label: "Austronesiane (Altre)",
        value: "map",
    },
    MAR: {
        label: "Marathi",
        value: "mar",
    },
    MAS: {
        label: "Masai",
        value: "mas",
    },
    MAY: {
        label: "Malese",
        value: "may",
    },
    MDF: {
        label: "Moksha",
        value: "mdf",
    },
    MDR: {
        label: "Mandar",
        value: "mdr",
    },
    MEN: {
        label: "Mende",
        value: "men",
    },
    MGA: {
        label: "Irlandese Medio (900-1200)",
        value: "mga",
    },
    MIC: {
        label: "Mi'kmaq",
        value: "mic",
    },
    MIN: {
        label: "Minangkabau",
        value: "min",
    },
    MIS: {
        label: "Lingue varie",
        value: "mis",
    },
    MKH: {
        label: "Mon-Khmer (Altre)",
        value: "mkh",
    },
    MLG: {
        label: "Malagasy",
        value: "mlg",
    },
    MLT: {
        label: "Maltese",
        value: "mlt",
    },
    MNC: {
        label: "Manchu",
        value: "mnc",
    },
    MNI: {
        label: "Manipuri",
        value: "mni",
    },
    MNO: {
        label: "Lingue Manobo",
        value: "mno",
    },
    MOH: {
        label: "Mohawk",
        value: "moh",
    },
    MOL: {
        label: "Moldavo",
        value: "mol",
    },
    MON: {
        label: "Mongolo",
        value: "mon",
    },
    MOS: {
        label: "Mossi",
        value: "mos",
    },
    MWL: {
        label: "Mirandese",
        value: "mwl",
    },
    MUL: {
        label: "Lingue multiple",
        value: "mul",
    },
    MUN: {
        label: "Lingue Munda",
        value: "mun",
    },
    MUS: {
        label: "Creek",
        value: "mus",
    },
    MWR: {
        label: "Marwari",
        value: "mwr",
    },
    MYN: {
        label: "Lingue Maya",
        value: "myn",
    },
    MYV: {
        label: "Erzya",
        value: "myv",
    },
    NAH: {
        label: "Nahuatl",
        value: "nah",
    },
    NAI: {
        label: "Lingue Native Nord Americane",
        value: "nai",
    },
    NAP: {
        label: "Napoletano",
        value: "nap",
    },
    NAU: {
        label: "Nauru",
        value: "nau",
    },
    NAV: {
        label: "Navajo",
        value: "nav",
    },
    NBL: {
        label: "Ndebele, Sud",
        value: "nbl",
    },
    NDE: {
        label: "Ndebele, Nord",
        value: "nde",
    },
    NDO: {
        label: "Ndonga",
        value: "ndo",
    },
    NDS: {
        label: "Basso Tedesco",
        value: "nds",
    },
    NEP: {
        label: "Nepalese",
        value: "nep",
    },
    NEW: {
        label: "Nepal Bhasa",
        value: "new",
    },
    NIA: {
        label: "Nias",
        value: "nia",
    },
    NIC: {
        label: "Niger-Kordofaniano (Altre)",
        value: "nic",
    },
    NIU: {
        label: "Niuean",
        value: "niu",
    },
    NNO: {
        label: "Norvegese Nynorsk",
        value: "nno",
    },
    NOB: {
        label: "Norvegese Bokmål",
        value: "nob",
    },
    NOG: {
        label: "Nogai",
        value: "nog",
    },
    NON: {
        label: "Norse, Antico",
        value: "non",
    },
    NOR: {
        label: "Norvegese",
        value: "nor",
    },
    NSO: {
        label: "Northern Sotho",
        value: "nso",
    },
    NUB: {
        label: "Nubian languages",
        value: "nub",
    },
    NWC: {
        label: "Classical Newari",
        value: "nwc",
    },
    NYA: {
        label: "Chichewa",
        value: "nya",
    },
    NYM: {
        label: "Nyamwezi",
        value: "nym",
    },
    NYN: {
        label: "Nyankole",
        value: "nyn",
    },
    NYO: {
        label: "Nyoro",
        value: "nyo",
    },
    NZI: {
        label: "Nzima",
        value: "nzi",
    },
    OCI: {
        label: "Occitano",
        value: "oci",
    },
    OJI: {
        label: "Ojibwa",
        value: "oji",
    },
    ORI: {
        label: "Oriya",
        value: "ori",
    },
    ORM: {
        label: "Oromo",
        value: "orm",
    },
    OSA: {
        label: "Osage",
        value: "osa",
    },
    OSS: {
        label: "Osseto",
        value: "oss",
    },
    OTA: {
        label: "Turco, Ottomano (1500-1928)",
        value: "ota",
    },
    OTO: {
        label: "Lingue Otomiane",
        value: "oto",
    },
    PAA: {
        label: "Papuasiche (Altre)",
        value: "paa",
    },
    PAG: {
        label: "Pangasinano",
        value: "pag",
    },
    PAL: {
        label: "Pahlavi",
        value: "pal",
    },
    PAM: {
        label: "Pampanga",
        value: "pam",
    },
    PAN: {
        label: "Panjabi",
        value: "pan",
    },
    PAP: {
        label: "Papiamento",
        value: "pap",
    },
    PAU: {
        label: "Palauan",
        value: "pau",
    },
    PEO: {
        label: "Persiano Antico (ca.600-400 A.C.)",
        value: "peo",
    },
    PER: {
        label: "Persiano",
        value: "per",
    },
    PHI: {
        label: "Filippine (Altre)",
        value: "phi",
    },
    PHN: {
        label: "Fenicio",
        value: "phn",
    },
    PLI: {
        label: "Pali",
        value: "pli",
    },
    POL: {
        label: "Polacco",
        value: "pol",
    },
    PON: {
        label: "Pohnpeian",
        value: "pon",
    },
    POR: {
        label: "Portoghese",
        value: "por",
    },
    PRA: {
        label: "Lingue Prakrit",
        value: "pra",
    },
    PRO: {
        label: "Provenzale Antico (fino al 1500)",
        value: "pro",
    },
    PUS: {
        label: "Pushto",
        value: "pus",
    },
    QUE: {
        label: "Quechua",
        value: "que",
    },
    RAJ: {
        label: "Rajasthani",
        value: "raj",
    },
    RAP: {
        label: "Rapanui",
        value: "rap",
    },
    RAR: {
        label: "Rarotongan",
        value: "rar",
    },
    ROA: {
        label: "Romanza (Altra)",
        value: "roa",
    },
    ROH: {
        label: "Reto-Romanzo (Rumantsch grischun)",
        value: "roh",
    },
    ROM: {
        label: "Romanes",
        value: "rom",
    },
    RUM: {
        label: "Rumeno",
        value: "rum",
    },
    RUN: {
        label: "Rundi",
        value: "run",
    },
    RUS: {
        label: "Russo",
        value: "rus",
    },
    SAD: {
        label: "Sandawe",
        value: "sad",
    },
    SAG: {
        label: "Sango",
        value: "sag",
    },
    SAH: {
        label: "Yakut",
        value: "sah",
    },
    SAI: {
        label: "Lingue Native Sud Americane (Altre)",
        value: "sai",
    },
    SAL: {
        label: "Lingue Salishan",
        value: "sal",
    },
    SAM: {
        label: "Aramaico Samaritano",
        value: "sam",
    },
    SAN: {
        label: "Sanscrito",
        value: "san",
    },
    SAS: {
        label: "Sasak",
        value: "sas",
    },
    SAT: {
        label: "Santali",
        value: "sat",
    },
    SCC: {
        label: "Serbo",
        value: "scc",
    },
    SCN: {
        label: "Siciliano",
        value: "scn",
    },
    SCO: {
        label: "Scozzese",
        value: "sco",
    },
    SCR: {
        label: "Croato",
        value: "scr",
    },
    SEL: {
        label: "Selkup",
        value: "sel",
    },
    SEM: {
        label: "Semitiche (Altre)",
        value: "sem",
    },
    SGA: {
        label: "Irlandese Antico (fino al 900)",
        value: "sga",
    },
    SGN: {
        label: "Linguaggio dei segni",
        value: "sgn",
    },
    SHN: {
        label: "Shan",
        value: "shn",
    },
    SID: {
        label: "Sidamo",
        value: "sid",
    },
    SIN: {
        label: "Sinhalese",
        value: "sin",
    },
    SIO: {
        label: "Lingue Siouan",
        value: "sio",
    },
    SIT: {
        label: "Sino-Tibetane (Altre)",
        value: "sit",
    },
    SLA: {
        label: "Slave (Altre)",
        value: "sla",
    },
    SLO: {
        label: "Slovacco",
        value: "slo",
    },
    SLV: {
        label: "Sloveno",
        value: "slv",
    },
    SMA: {
        label: "Sami meridionale",
        value: "sma",
    },
    SME: {
        label: "Sami settentrionale",
        value: "sme",
    },
    SMI: {
        label: "Sami (Altre)",
        value: "smi",
    },
    SMJ: {
        label: "Lule Sami",
        value: "smj",
    },
    SMN: {
        label: "Inari Sami",
        value: "smn",
    },
    SMO: {
        label: "Samoano",
        value: "smo",
    },
    SMS: {
        label: "Skolt Sami",
        value: "sms",
    },
    SNA: {
        label: "Shona",
        value: "sna",
    },
    SND: {
        label: "Sindhi",
        value: "snd",
    },
    SNK: {
        label: "Soninke",
        value: "snk",
    },
    SOG: {
        label: "Sogdian",
        value: "sog",
    },
    SOM: {
        label: "Somalo",
        value: "som",
    },
    SON: {
        label: "Songhai",
        value: "son",
    },
    SOT: {
        label: "Sotho, Meridionale",
        value: "sot",
    },
    SPA: {
        label: "Spagnolo",
        value: "spa",
    },
    SRD: {
        label: "Sardo",
        value: "srd",
    },
    SRR: {
        label: "Serer",
        value: "srr",
    },
    SSA: {
        label: "Nilo-Sahariano (Other)",
        value: "ssa",
    },
    SSW: {
        label: "Swati",
        value: "ssw",
    },
    SUK: {
        label: "Sukuma",
        value: "suk",
    },
    SUN: {
        label: "Sundanese",
        value: "sun",
    },
    SUS: {
        label: "Susu",
        value: "sus",
    },
    SUX: {
        label: "Sumerian",
        value: "sux",
    },
    SWA: {
        label: "Swahili",
        value: "swa",
    },
    SWE: {
        label: "Svedese",
        value: "swe",
    },
    SYR: {
        label: "Syriac",
        value: "syr",
    },
    TAH: {
        label: "Tahitian",
        value: "tah",
    },
    TAI: {
        label: "Tai (Other)",
        value: "tai",
    },
    TAM: {
        label: "Tamil",
        value: "tam",
    },
    TAT: {
        label: "Tatar",
        value: "tat",
    },
    TEL: {
        label: "Telugu",
        value: "tel",
    },
    TEM: {
        label: "Timne",
        value: "tem",
    },
    TER: {
        label: "Tereno",
        value: "ter",
    },
    TET: {
        label: "Tetum",
        value: "tet",
    },
    TGK: {
        label: "Tajik",
        value: "tgk",
    },
    TGL: {
        label: "Tagalog",
        value: "tgl",
    },
    THA: {
        label: "Thai",
        value: "tha",
    },
    TIB: {
        label: "Tibetano",
        value: "tib",
    },
    TIG: {
        label: "Tigre",
        value: "tig",
    },
    TIR: {
        label: "Tigrinya",
        value: "tir",
    },
    TIV: {
        label: "Tiv",
        value: "tiv",
    },
    TKL: {
        label: "Tokelau",
        value: "tkl",
    },
    TLH: {
        label: "Klingon",
        value: "tlh",
    },
    TLI: {
        label: "Tlingit",
        value: "tli",
    },
    TMH: {
        label: "Tamashek",
        value: "tmh",
    },
    TOG: {
        label: "Tonga (Nyasa)",
        value: "tog",
    },
    TON: {
        label: "Tonga (Tonga Islands)",
        value: "ton",
    },
    TPI: {
        label: "Tok Pisin",
        value: "tpi",
    },
    TSI: {
        label: "Tsimshian",
        value: "tsi",
    },
    TSN: {
        label: "Tswana",
        value: "tsn",
    },
    TSO: {
        label: "Tsonga",
        value: "tso",
    },
    TUK: {
        label: "Turkmen",
        value: "tuk",
    },
    TUM: {
        label: "Tumbuka",
        value: "tum",
    },
    TUP: {
        label: "Lingue Tupi",
        value: "tup",
    },
    TUR: {
        label: "Turco",
        value: "tur",
    },
    TUT: {
        label: "Altaiche (Altre)",
        value: "tut",
    },
    TVL: {
        label: "Tuvalu",
        value: "tvl",
    },
    TWI: {
        label: "Twi",
        value: "twi",
    },
    TYV: {
        label: "Tuvano",
        value: "tyv",
    },
    UDM: {
        label: "Udmurt",
        value: "udm",
    },
    UGA: {
        label: "Ugaritic",
        value: "uga",
    },
    UIG: {
        label: "Uighur",
        value: "uig",
    },
    UKR: {
        label: "Ucraino",
        value: "ukr",
    },
    UMB: {
        label: "Umbundu",
        value: "umb",
    },
    UND: {
        label: "Indeterminata",
        value: "und",
    },
    URD: {
        label: "Urdu",
        value: "urd",
    },
    UZB: {
        label: "Uzbeco",
        value: "uzb",
    },
    VAI: {
        label: "Vai",
        value: "vai",
    },
    VEN: {
        label: "Venda",
        value: "ven",
    },
    VIE: {
        label: "Vietnamita",
        value: "vie",
    },
    VOL: {
        label: "Volapük",
        value: "vol",
    },
    VOT: {
        label: "Votic",
        value: "vot",
    },
    WAK: {
        label: "Lingue Wakashan",
        value: "wak",
    },
    WAL: {
        label: "Walamo",
        value: "wal",
    },
    WAR: {
        label: "Waray",
        value: "war",
    },
    WAS: {
        label: "Washo",
        value: "was",
    },
    WEL: {
        label: "Gallese",
        value: "wel",
    },
    WEN: {
        label: "Lingue Sorbe",
        value: "wen",
    },
    WLN: {
        label: "Walloon",
        value: "wln",
    },
    WOL: {
        label: "Wolof",
        value: "wol",
    },
    XAL: {
        label: "Kalmyk",
        value: "xal",
    },
    XHO: {
        label: "Xhosa",
        value: "xho",
    },
    YAO: {
        label: "Yao",
        value: "yao",
    },
    YAP: {
        label: "Yapese",
        value: "yap",
    },
    YID: {
        label: "Yiddish",
        value: "yid",
    },
    YOR: {
        label: "Yoruba",
        value: "yor",
    },
    YPK: {
        label: "Lingue Yupik",
        value: "ypk",
    },
    ZAP: {
        label: "Zapotec",
        value: "zap",
    },
    ZEN: {
        label: "Zenaga",
        value: "zen",
    },
    ZHA: {
        label: "Zhuang",
        value: "zha",
    },
    ZND: {
        label: "Zande",
        value: "znd",
    },
    ZUL: {
        label: "Zulu",
        value: "zul",
    },
    ZUN: {
        label: "Zuni",
        value: "zun",
    },
};
Object.freeze(LanguageCode);
export default LanguageCode;
