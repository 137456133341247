<template>
    <main>
        <Dialog header="Conferma" v-model:visible="isConfirmationDialogVisible" :closable="false"
                :modal="true">
            <p>Il cambiamento della password è stato effettuato con successo</p>
            <template #footer>
                <Button label="Ok" @click="isConfirmationDialogVisible = false"
                        class="p-button-text"/>
            </template>
        </Dialog>

        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
                <Card class="p-p-3">
                    <template #title>
                        <h1 class="p-col-12 p-text-center">Password dimenticata?</h1>
                    </template>
                    <template #content>
                        <div v-if="!isTokenValid" class="p-text-center">
                            <p>
                                Il link utilizzato non è più valido,
                                ma non ti preoccupare,
                                puoi generarne uno nuovo nella pagina di login</p>
                        </div>
                        <div v-else >
                            <div class=" p-mb-3">
                                <p class="p-text-center">Scegli la tua nuova password:</p>
                            </div>

                            <form id="new-password-form">
                                <div class="p-fluid">
                                    <div class="p-field p-grid no-margin p-jc-center">
                                        <label for="password"
                                               class="p-col-12 p-md-6 p-lg-4 p-xl-2">
                                            Password
                                        </label>
                                        <div class="p-col-12 p-md-6 p-xl-4">
                                            <Password id="password" autocomplete="new-password"
                                                      v-model="v$.password.$model"
                                                      :class="{ 'p-invalid':(v$.password.$invalid ||
                                               !isPasswordSecure) && isSubmitted }" maxlength="72">
                                                <template #footer="sp">
                                                    {{ sp.level }}
                                                    <Divider />
                                                    <p class="p-mt-3 p-mb-0">
                                                        Regole per una password sicura:
                                                    </p>
                                                    <ul class="p-pl-2 p-ml-3 p-mt-0"
                                                        style="line-height: 1.5">
                                                        <li>Almeno un carattere maiuscolo</li>
                                                        <li>Almeno un carattere minuscono</li>
                                                        <li>Almeno un numero</li>
                                                        <li>Lunghezza minima 8 caratteri</li>
                                                    </ul>
                                                </template>
                                            </Password>
                                            <small v-if="v$.password.$invalid && isSubmitted"
                                                   class="p-error p-d-block">
                                                La password inserita non è valida
                                            </small>
                                            <small v-if="!isPasswordSecure && v$.password.$model !==
                                                    ''"
                                                   class="p-error p-d-block">
                                                La password inserita non è sicura
                                            </small>
                                        </div>
                                    </div>
                                    <div class="p-field p-grid no-margin p-jc-center p-mt-2">
                                        <label for="repeatPassword"
                                               class="p-col-12 p-md-6 p-lg-4 p-xl-2">
                                            Ripeti Password
                                        </label>
                                        <div class="p-col-12 p-md-6 p-xl-4">
                                            <Password id="repeatPassword"
                                                      v-model="v$.repeatPassword.$model"
                                                      :class="{ 'p-invalid':v$.repeatPassword.$model
                                                      !== v$.password.$model }" maxlength="72"
                                                      :feedback="false" />
                                            <small v-if="v$.repeatPassword.$model !==
                                                    v$.password.$model"
                                                   class="p-error p-d-block">
                                                Le password non coincidono
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-field p-grid no-margin p-jc-center p-mt-2">
                                    <Button label="Invia" :loading="isLoading"
                                            class="p-button p-mt-3 p-jc-center"
                                            form="new-password-form" type="submit"
                                            v-on:click.prevent="submitPassword(!v$.$invalid &&
                                v$.repeatPassword.$model === v$.password.$model)"/>
                                </div>
                            </form>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
    </main>
</template>

<script>
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
    computed,
    onMounted,
    reactive,
    ref,
} from "vue";
import { useStore } from "vuex";
import Password from "primevue/password";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import { useRoute, useRouter } from "vue-router";
import Card from "primevue/card";

export default {
    name: "NewPassword",
    components: {
        Password, Divider, Button, Dialog, Card,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const userToken = route.params.token;
        const isTokenValid = ref(false);
        const isConfirmationDialogVisible = ref(false);

        onMounted(async () => {
            try {
                await store.dispatch("checkPasswordToken", userToken);
                isTokenValid.value = true;
            } catch (err) {
                isTokenValid.value = false;
            }
        });

        const state = reactive({
            password: "",
            repeatPassword: "",
        });
        const rules = {
            password: { required, minLength: minLength(8) },
            repeatPassword: { required },
        };
        const isSubmitted = ref(false);
        const isLoading = ref(false);
        const isPasswordSecure = computed(() => store.getters.isPasswordSecure(state.password));

        const v$ = useVuelidate(rules, state);
        const submitPassword = async (isInputValid) => {
            isSubmitted.value = true;
            if (isInputValid && isPasswordSecure) {
                isLoading.value = true;
                let res;
                try {
                    res = await store.dispatch("resetPassword", { password: state.password, token: userToken });
                    await router.push("/");
                    isConfirmationDialogVisible.value = true;
                } catch (err) {
                    console.log(res);
                } finally {
                    isSubmitted.value = false;
                    isLoading.value = false;
                }
            }
        };

        return {
            state,
            v$,
            isSubmitted,
            isConfirmationDialogVisible,
            isTokenValid,
            isPasswordSecure,
            isLoading,
            submitPassword,
        };
    },
};
</script>

<style scoped lang="scss">
h1 {
    font-size: 2rem;
}
</style>
