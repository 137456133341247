<template>
  <div id='cookie-banner' v-if='!accepted'>
    <div class='banner-text'>
      Questo sito web utilizza i cookie. 
      In questo sito web sono presenti solamente cookie necessari per permettere il corretto funzionamento 
      del sito, in particolare, di memorizzare le attività svolte dall'utente sullo stesso. <br>
      Per maggiori informazioni, si consulti l'informativa sul trattamento dei 
      <router-link :to="{ name: 'privacy' }">dati personali</router-link>. 
</div>
    <div class='banner-button' ><button class='link-secondary' @click='acceptbutton'>Chiudo e proseguo</button></div>
    </div>
</template>

<script>
import store from "../store";
export default {
  data() {
    return {
      accepted:0
    }
  },
  mounted() {
      this.cookiePanel();
  },
  methods: {
    acceptbutton:function() {
      store.commit("cookieAccept");
      this.cookiePanel();
    },
    cookiePanel:function() {
      this.accepted = typeof store.getters.cookiePolicy != "undefined" ? store.getters.cookiePolicy : 0;
    }
  }

};
</script>

<style>
#cookie-banner {
  position:fixed;
  bottom:0;
  opacity:.9;
  background:white;
  padding:16px;
  border-top:1px solid #aaaaaa;
  width:100%;
  text-align:center;
}
.banner-text {
  text-align:center;
  margin-bottom:16px;
  line-height:28px;
}
.banner-button {
  text-align:center;
}
.banner-button button {
  text-transform: uppercase;
  border-radius:16px;
  background:#ac0033;
  color:white;
  padding:8px;
  border:1px solid #ac0033;
  cursor:pointer;
}
</style>