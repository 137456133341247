const entryModule = {
    actions: {
        async queryEntries(context, query) {
            return context.rootGetters.getServerConnection.get("/admin/entry/query", {
                params: { query },
            });
        },
        async getEntry(context, entryId) {
            return context.rootGetters.getServerConnection.get(`/admin/entry/${entryId}`);
        },
        async updateEntry(context, { entryId, entry }) {
            return context.rootGetters.getServerConnection.put(`/admin/entry/${entryId}`, entry);
        },
        async deleteEntry(context, entryId) {
            return context.rootGetters.getServerConnection.delete(`/admin/entry/${entryId}`);
        },
        async undeleteEntry(context, entryId) {
            return context.rootGetters.getServerConnection.delete(`/admin/entry/${entryId}`, {
                data: { undelete: true },
            });
        },
        async createEntry(context, entry) {
            return context.rootGetters.getServerConnection.post("/admin/entry", entry);
        },
        async approveEntryReview(context, entryId) {
            return context.rootGetters.getServerConnection.post(
                `/admin/entry/${entryId}/review`,
                {
                    status: "approved",
                },
            );
        },
        async denyEntryReview(context, { entryId, reason }) {
            return context.rootGetters.getServerConnection.post(
                `/admin/entry/${entryId}/review`,
                {
                    status: "denied",
                    reason,
                },
            );
        },
    },
};

export default entryModule;
