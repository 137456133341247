<template>
    <template v-if="!loading && error === null">
        <Message severity="error" :closable="false" v-if="v$.$errors.length > 0">
            Hai {{ errorsCount }} da risolvere.
        </Message>
        <Message severity="error" :closable="false" v-if="isSubmitted && !deletionSuccess">
            Cancellazione del tag fallita. Ricarica la pagina e riprova.
        </Message>
        <Message severity="success" v-if="isSubmitted && submissionSuccess">
            Tag aggiornato.
        </Message>
        <h1 class="p-d-flex p-ai-center">{{ tagTitle }}</h1>
        <div class="p-fluid p-formgrid p-grid entry-detail">
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton forId="id" label="ID interno">
                    ID interno del tag. Questo valore non è modificabile.
                </JLabelHelpButton>
                <InputText id="id" type="text" :value="tag._id" disabled
                           aria-describedby="id-help"/>
            </div>
            <div class="p-field p-col-12">
                <JLabelHelpButton forId="name" label="Nome abbreviato">
                    Nome abbreviato del tag. Questo nome sarà usato internamente durante
                    l'esportazione dei termini.
                </JLabelHelpButton>
                <InputText id="name" type="text" v-model="v$.name.$model"
                           :aria-describedby="boolObjectToString({
                               'name-help': true,
                               'name-err': v$.name.$error,
                           })"
                           :class="{ 'p-invalid': v$.name.$error }"/>
                <small class="p-error" id="name-err" v-if="v$.name.$error">
                    <template
                        v-if="v$.name.$errors.some((err) => err.$validator === 'required')">
                        Il campo è obbligatorio.
                    </template>
                    <template v-if="v$.name.$errors.length > 1">
                        <br>
                    </template>
                    <template
                        v-if="v$.name.$errors.some((err) => err.$validator === '$externalResults')">
                        Esiste già un tag con questo nome.
                    </template>
                </small>
            </div>
            <div class="p-field p-col-12">
                <JLabelHelpButton forId="prettyName" label="Nome completo">
                    Nome completo del tag. Questo sarà il nome visibile pubblicamente.
                </JLabelHelpButton>
                <InputText id="prettyName" type="text" v-model="tag.prettyName"
                           aria-describedby="prettyName-help"/>
            </div>
        </div>
        <div class="p-d-flex p-dir-rev p-ai-center">
            <Button label="Salva" icon="pi pi-save" class="p-ml-2 p-button-success"
                    :loading="submissionLoading" @click="onSave"/>
            <Button label="Elimina" icon="pi pi-trash" class="p-button-danger"
                    @click="onDelete" v-if="tagId"/>
            <p class="errors-count" v-if="v$.$errors.length > 0">{{ errorsCount }}</p>
        </div>
        <Dialog header="Conferma cancellazione" v-model:visible="deleteConfirmVisible"
                :modal="true" contentClass="p-pb-0">
            <p>
                <strong>Stai per cancellare un tag!</strong>
                Questa operazione avrà le seguenti conseguenze:
            </p>
            <ul>
                <li>La cancellazione è irreversibile</li>
                <li>
                    Il tag verrà rimosso da
                    <strong>
                        {{ tag.entriesCount }}
                        {{ tag.entriesCount === 1 ? "termine" : "termini" }}
                    </strong>
                </li>
            </ul>
            <p>
                Per confermare l'operazione, scrivi il numero di termini che perderanno il tag e
                premi il pulsante qui sotto.
            </p>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <InputText type="number" class="no-spin" v-model="entriesCountConfirm"/>
                </div>
            </div>
            <template #footer>
                <Button label="Conferma cancellazione" class="p-button-danger"
                        :disabled="tag.entriesCount.toString() !== entriesCountConfirm"
                        @click="onConfirmDelete"/>
            </template>
        </Dialog>
    </template>
    <template v-else-if="error !== null">
        <h1 class="error-title">{{ error.title }}</h1>
        <p class="error-text">{{ error.text }}</p>
    </template>
</template>

<script>
import {
    computed,
    onMounted,
    reactive,
    ref,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Message from "primevue/message";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "TagDetail",
    components: {
        Button,
        Dialog,
        InputText,
        Message,
        JLabelHelpButton,
    },
    props: {
        tagId: {
            type: String,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const tag = reactive({
            _id: "",
            name: "",
            prettyName: "",
            entriesCount: 0,
        });
        const tagRules = {
            name: { required },
        };
        const $externalResults = ref({});

        const v$ = useVuelidate(tagRules, tag, { $externalResults });
        const error = ref(null);
        const loading = ref(!!props.tagId);
        const isSubmitted = ref(false);
        const submissionSuccess = ref(true);
        const deletionSuccess = ref(true);
        const submissionLoading = ref(false);
        const tagTitle = computed(() => {
            if (tag.prettyName) {
                return tag.prettyName;
            }
            if (tag.name) {
                return tag.name;
            }
            return "Tag";
        });
        const errorsCount = computed(() => (v$.value.$errors.length === 1
            ? "1 errore"
            : `${v$.value.$errors.length} errori`));
        const deleteConfirmVisible = ref(false);
        const entriesCountConfirm = ref("");

        const refreshTag = async () => {
            loading.value = true;
            try {
                error.value = null;
                isSubmitted.value = false;
                submissionSuccess.value = true;
                try {
                    const result = await store.dispatch("admin/getTag", props.tagId);
                    tag._id = result.data._id;
                    tag.name = result.data.name;
                    tag.prettyName = result.data.prettyName;
                    tag.entriesCount = result.data.entriesCount;
                } catch (err) {
                    error.value = {
                        title: "Oh no!",
                        text: "Si è verificato un errore durante il recupero del tag!",
                    };
                }
            } finally {
                loading.value = false;
            }
        };

        const onSave = async () => {
            v$.value.$clearExternalResults();
            let newId = null;
            if (v$.value.$errors.length === 0) {
                isSubmitted.value = true;
                try {
                    submissionLoading.value = true;
                    const tagBody = {
                        name: tag.name,
                        prettyName: tag.prettyName,
                    };
                    if (props.tagId) {
                        await store.dispatch("admin/updateTag", {
                            tagId: props.tagId,
                            tag: tagBody,
                        });
                    } else {
                        const response = await store.dispatch("admin/createTag", tagBody);
                        newId = response.data._id;
                    }
                    submissionSuccess.value = true;
                    if (newId) {
                        // Redirect to the newly created ID page
                        await router.replace({
                            name: "admin.tags.detail", params: { tagId: newId },
                        });
                    }
                } catch (err) {
                    submissionSuccess.value = false;
                    if (err.response.data.error === "Duplicate name") {
                        $externalResults.value = { name: ["Questo tag esiste già"] };
                    }
                } finally {
                    submissionLoading.value = false;
                    window.scrollTo(0, 0);
                }
            }
        };
        const onConfirmDelete = async () => {
            isSubmitted.value = true;
            deletionSuccess.value = true;
            try {
                await store.dispatch("admin/deleteTag", props.tagId);
                await router.replace({ name: "admin.tags" });
            } catch (err) {
                deletionSuccess.value = false;
            }
        };
        const onDelete = async () => {
            if (tag.entriesCount === 0) {
                await onConfirmDelete();
            } else {
                deleteConfirmVisible.value = true;
            }
        };

        watch(() => props.tagId, async () => refreshTag());
        onMounted(async () => {
            if (props.tagId) {
                await refreshTag();
            }
        });

        return {
            v$,
            tag,
            error,
            loading,
            isSubmitted,
            deletionSuccess,
            submissionSuccess,
            submissionLoading,
            tagTitle,
            errorsCount,
            deleteConfirmVisible,
            entriesCountConfirm,
            boolObjectToString,
            onSave,
            onDelete,
            onConfirmDelete,
        };
    },
};
</script>

<style lang="scss" scoped>
    .error-title {
        text-align: center;
        font-size: 2rem;
    }
    .error-text {
        text-align: center;
    }
    .errors-count {
        margin: 0 1rem 0 0;
    }
    p {
        color: inherit;
    }
</style>
