import entryModule from "@/store/modules/admin/entry";
import tagModule from "@/store/modules/admin/tag";
import userModule from "@/store/modules/admin/user";
import statsModule from "@/store/modules/admin/stats";

const adminModule = {
    namespaced: true,
    modules: {
        entryModule,
        tagModule,
        userModule,
        statsModule,
    },
};

export default adminModule;
