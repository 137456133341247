<template>
    <Card>
        <template #title v-if="!loading">
            Modifica del termine
            {{ historyItem?.kanji?.[0]?.term ?? historyItem?.readings?.[0]?.term ?? "" }}
        </template>
        <template #title v-else>
            <Skeleton height="1.5rem"/>
        </template>

        <template #subtitle v-if="!loading">
            <p>Modifica effettuata in data {{ renderDate(historyItem.commit.createdAt) }}</p>
            <div v-if="historyItem.commit.reviewedAt && historyItem.commit.reviewedBy
                    !== historyItem.commit.createdBy" class="p-text-italic">
                <template v-if="historyItem?.commit.reviewStatus === 'approved'">
                        Approvata
                </template>
                <template v-else-if="historyItem?.commit.reviewStatus === 'denied'">
                        Rifiutata
                </template>
                <template v-if="historyItem.commit.reviewedBy && reviewerFullName">
                    da {{ reviewerFullName }}
                </template>
                in data {{ renderDate(historyItem.commit.reviewedAt) }}
            </div>
            <p v-else-if="historyItem.commit.reviewStatus === undefined" class="p-text-italic">
                Questa modifica è in attesa di revisione.
            </p>
        </template>
        <template #subtitle v-else>
            <Skeleton height="4rem"/>
        </template>
        <template #content>
            <template v-if="historyItem.commit.reviewComment">
                <p class="p-m-0">Il revisore ha lasciato il seguente commento:</p>
                <p class="p-ml-3 p-mt-3 p-mb-3 p-text-italic">
                    {{ historyItem.commit.reviewComment }}
                </p>
            </template>
            <template v-else>
                <p class="p-m-0">Cambiamenti dalla versione precedente:</p>
                <ol>
                    <li v-for="(commit, i) of historyItem.commit.commit" :key="i">
                        {{ prettyCommit(commit) }}
                    </li>
                </ol>
            </template>
        </template>
        <template #footer>
            <Button label="Visualizza termine" icon="pi pi-eye"
                    @click="showTerm"/>
        </template>
    </Card>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import Button from "primevue/button";
import prettyCommit from "@/utils/prettyCommit";
import renderDate from "@/utils/prettyDate";

export default {
    name: "JUserHistoryCard",
    components: {
        Card,
        Button,
        Skeleton,
    },
    props: {
        historyItem: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();

        const loading = ref(true);
        const compilerFullName = ref(null);
        const reviewerFullName = ref(null);

        onMounted(async () => {
            loading.value = true;
            try {
                const createdByPromise = props.historyItem.commit.createdBy
                    ? store.dispatch("admin/getUser", props.historyItem.commit.createdBy)
                    : null;
                const reviewedByPromise = props.historyItem.commit.reviewedBy
                    ? store.dispatch("admin/getUser", props.historyItem.commit.reviewedBy)
                    : null;
                // Resolve the two promises in one shot
                const [createdByResponse, reviewedByResponse] = await Promise.all([
                    createdByPromise,
                    reviewedByPromise,
                ]);
                if (createdByResponse) {
                    const { firstName, lastName } = createdByResponse.data;
                    compilerFullName.value = `${firstName} ${lastName}`;
                }
                if (reviewedByResponse) {
                    const { firstName, lastName } = reviewedByResponse.data;
                    reviewerFullName.value = `${firstName} ${lastName}`;
                }
            } catch (err) {
                compilerFullName.value = null;
                reviewerFullName.value = null;
            } finally {
                loading.value = false;
            }
        });

        const showTerm = () => {
            const entryId = props.historyItem._id;
            router.push({
                name: "admin.entries.detail",
                params: {
                    entryId,
                },
            });
        };

        return {
            loading,
            compilerFullName,
            reviewerFullName,
            renderDate,
            showTerm,
            prettyCommit,
        };
    },
};
</script>

<style lang="scss" scoped>
    h3 {
        font-size: inherit;
        font-weight: inherit;
        margin: inherit;
    }
    p {
        color: inherit;
    }
    .p-card-subtitle > p {
        font-weight: inherit;
        margin: inherit;
    }
</style>
