<template>
<div>
    <j-simple-popup-form
        v-model:visible="isRenameDialogVisible" id="renameForm" header="Scegli un nuovo nome"
        placeholder="Nome della collezione" submitLabel="Modifica"
        :previousName="collection.name"
        submitIcon="pi pi-pencil" errorMessagge="Devi specificare un nome"
        @submit="renameCollection(collection._id, $event)" />
    <j-collection-download-dialog v-model:visible="isDownloadDialogVisible"
                                  :collection="collection"/>
    <Card>
        <template #title>
            <h3 class="p-my-0">{{ collection.name }}</h3>
        </template>
        <template #content>
            <p class="p-my-0 p-text-italic">
                {{ collection.count }} {{ collection.count !== 1 ? "termini" : "termine" }}
            </p>
            <p v-if="collection._id === 'watchlist'">
                Inserisci in questa collezione i termini per cui vuoi ricevere notifiche nel caso in
                cui vengano modificati.
            </p>
        </template>
        <template #footer>
            <Button aria-label="Rinomina collezione" icon="pi pi-pencil"
                    class="p-mr-1 p-button-secondary p-button-rounded"
                    @click.stop="showRenameDialog"
                    v-if="collection._id !== 'watchlist'"
                    :loading="isRenamingCollection"/>
            <Button aria-label="Scarica in formato Anki" icon="pi pi-download"
                    class="p-mr-1 p-button-rounded p-button-secondary"
                    @click.stop="showDownloadDialog"
                    :disabled="collection.count <= 0"/>
            <Button aria-label="Cancella" icon="pi pi-trash"
                    class="p-button-rounded p-button-danger"
                    @click.stop="askForDeletion(collection._id)"
                    v-if="collection._id !== 'watchlist'"
                    :loading="isDeletingCollection"/>
        </template>
    </Card>
    <Message severity="error" v-if="deletionError" :closable="false">
        {{ deletionError }}
    </Message>
    <Message severity="error" v-if="renamingError" :closable="false">
        {{ renamingError }}
    </Message>
</div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import Button from "primevue/button";
import Card from "primevue/card";
import Message from "primevue/message";
import JCollectionDownloadDialog from "@/components/collections/JCollectionDownloadDialog.vue";
import JSimplePopupForm from "@/components/JSimplePopupForm.vue";

export default {
    name: "JCollectionCard",
    components: {
        Button,
        Card,
        Message,
        JCollectionDownloadDialog,
        JSimplePopupForm,
    },
    props: {
        collection: {
            type: Object,
            required: true,
        },
    },
    emits: ["renamed", "removed"],
    setup(props, { emit }) {
        const store = useStore();
        const isRenamingCollection = ref(false);
        const isDeletingCollection = ref(false);
        const renamingError = ref();
        const deletionError = ref();

        const isRenameDialogVisible = ref(false);
        const showRenameDialog = () => {
            isRenameDialogVisible.value = true;
        };
        const renameCollection = async (collectionId, newName) => {
            isRenamingCollection.value = true;
            try {
                await store.dispatch("renameCollection", { collectionId, newName });
                emit("renamed", newName);
            } catch (error) {
                renamingError.value = "Si è verificato un errore nel rinominare la collezione. Prova a ricaricare la pagina.";
            }
            isRenamingCollection.value = false;
        };

        const confirm = useConfirm();
        const askForDeletion = (collectionId) => confirm.require({
            message: "Sicuro di voler cancellare la collezione?",
            header: "Conferma",
            icon: "pi pi-exclamation-triangle",
            async accept() {
                isDeletingCollection.value = true;
                try {
                    await store.dispatch("deleteCollection", collectionId);
                    emit("removed");
                } catch (error) {
                    deletionError.value = "Si è verificato un errore nel cancellare la collezione. Prova a ricaricare la pagina.";
                }
                isDeletingCollection.value = false;
            },
        });

        const isDownloadDialogVisible = ref(false);
        const showDownloadDialog = () => { isDownloadDialogVisible.value = true; };

        return {
            renameCollection,
            isRenamingCollection,
            isRenameDialogVisible,
            renamingError,
            showRenameDialog,
            askForDeletion,
            isDeletingCollection,
            deletionError,
            showDownloadDialog,
            isDownloadDialogVisible,
        };
    },
};
</script>

<style scoped lang="scss">
    @import "../../assets/scss/theme/variables";

    h2 {
        margin: 0;
        display: inline;
    }

    .p-card {
        background-color: #FAFAFA;
        overflow-wrap: break-word;

        ::v-deep(.p-card-content) {
            padding: 0;
        }

        ::v-deep(.p-card-footer) {
            padding: 0;
            text-align: right;
        }
    }
</style>
