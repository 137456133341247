<template>
    <DataTable ref="usersTable" :loading="loading" :value="users" :lazy="true" :paginator="true"
               :totalRecords="totalUsers" dataKey="_id"
               responsiveLayout="scroll" :row-hover="true"
               v-model:filters="filters" filter-display="menu"
               :rows="10" :rows-per-page-options="[10, 25, 50, 100]"
               @page="onPageChanged" @sort="onSort" @filter="onFilter">
        <Column :exportable="false">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-raised"
                        aria-label="Modifica" @click="onOpenUser(slotProps.data._id)"/>
            </template>
        </Column>
        <Column field="email" header="Email" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per email"/>
            </template>
        </Column>
        <Column field="firstName" header="Nome" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per nome"/>
            </template>
        </Column>
        <Column field="lastName" header="Cognome" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per cognome"/>
            </template>
        </Column>
        <Column field="badgeNumber" header="Matricola" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per matricola"/>
            </template>
        </Column>
        <Column field="role" header="Ruolo"
                :showFilterOperator="false"
                :showFilterMatchModes="false">
            <template #body="slotProps">
                <div class="p-column-filter">{{ slotProps.data.role }}</div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import UserRole from "@/enums/userRole";

export default {
    name: "AdminUsers",
    components: {
        DataTable,
        Column,
        Button,
        InputText,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const isUserAdmin = computed(() => store.getters.hasRoleOrUpper("admin"));
        const usersTable = ref();
        const loading = ref(false);
        const filters = ref({
            email: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            firstName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            lastName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            badgeNumber: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
        });
        const queryParams = ref({});
        const totalUsers = ref(0);
        const users = ref([]);
        const renderEntry = (entry) => {
            const param = entry;
            param.role = Object.values(UserRole)
                .find((role) => entry.role === role.value).label;
            return param;
        };
        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch("admin/queryUsers", queryParams.value);
                users.value = response.data.users.map((entry) => renderEntry(entry));
                totalUsers.value = response.data.totalUsers;
            } catch (err) {
                users.value = [];
            }
            loading.value = false;
        };
        const onPageChanged = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onFilter = async () => {
            queryParams.value.filters = filters.value;
            await reloadData();
        };
        const onOpenUser = (userId) => {
            router.push({
                name: "admin.users.detail",
                params: {
                    userId,
                },
            });
        };
        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: usersTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();
        });

        return {
            isUserAdmin,
            usersTable,
            loading,
            totalUsers,
            users,
            filters,
            queryParams,
            onPageChanged,
            onSort,
            onFilter,
            onOpenUser,
        };
    },
};
</script>
