<template>
    <Dialog header="Termini sorgente" :visible="visible"
            @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid" :dismissableMask="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p>
                    Inserisci qui eventuali parole o frasi che indicano la provenienza di questo
                    termine.
                </p>
            </div>
        </div>
        <JOrderList v-model="sourceTerms" :emptyItem="emptySourceTerm" :disabled="disabled"
                    addButtonLabel="Aggiungi termine sorgente" :enableInsert="!disabled">
            <template #header="slotProps">
                <h4>{{ getTitle(slotProps.item) }}</h4>
            </template>
            <template #item="slotProps">
                <JSourceTermEditor v-model="slotProps.item" :itemKey="slotProps.key"
                                   :disabled="disabled" :senseKey="senseKey" :glosses="glosses"/>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import JOrderList from "@/components/JOrderList.vue";
import JSourceTermEditor from "@/components/admin/entries/JSourceTermEditor.vue";

export default {
    name: "JSourceTermsEditor",
    components: {
        Button,
        Dialog,
        JOrderList,
        JSourceTermEditor,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        glosses: {
            type: Array,
            required: true,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "onDialogClose"],
    setup(props) {
        const sourceTerms = ref(props.modelValue);

        const v$ = useVuelidate();

        const emptySourceTerm = ref({
            lang: null,
            type: "full",
            waseieigo: false,
            term: null,
        });

        const joinedGlosses = computed(() => props.glosses
            .map((gloss) => gloss.term)
            .join("; "));
        const getTitle = (item) => (item.term !== undefined
            && item.term !== null
            && item.term !== ""
            ? item.term
            : joinedGlosses.value);

        return {
            v$,
            sourceTerms,
            emptySourceTerm,
            getTitle,
        };
    },
};
</script>
