const Priority = {
    NEWS_1: {
        label: "wordfreq, prima metà",
        value: "news1",
    },
    NEWS_2: {
        label: "wordfreq, seconda metà",
        value: "news2",
    },
    ICHI_1: {
        label: "Ichimango goi bunruishuu",
        value: "ichi1",
    },
    ICHI_2: {
        label: "Ichimango goi bunruishuu, bassa frequenza",
        value: "ichi2",
    },
    SPEC_1: {
        label: "Parole comuni non classificate, primo gruppo",
        value: "spec1",
    },
    SPEC_2: {
        label: "Parole comuni non classificate, secondo gruppo",
        value: "spec2",
    },
    GAI_1: {
        label: "wordfreq, primo gruppo gairaigo",
        value: "gai1",
    },
    GAI_2: {
        label: "wordfreq, secondo gruppo gairaigo",
        value: "gai2",
    },
    NF_01: {
        label: "wordfreq, gruppo 1",
        value: "nf01",
    },
    NF_02: {
        label: "wordfreq, gruppo 2",
        value: "nf02",
    },
    NF_03: {
        label: "wordfreq, gruppo 3",
        value: "nf03",
    },
    NF_04: {
        label: "wordfreq, gruppo 4",
        value: "nf04",
    },
    NF_05: {
        label: "wordfreq, gruppo 5",
        value: "nf05",
    },
    NF_06: {
        label: "wordfreq, gruppo 6",
        value: "nf06",
    },
    NF_07: {
        label: "wordfreq, gruppo 7",
        value: "nf07",
    },
    NF_08: {
        label: "wordfreq, gruppo 8",
        value: "nf08",
    },
    NF_09: {
        label: "wordfreq, gruppo 9",
        value: "nf09",
    },
    NF_10: {
        label: "wordfreq, gruppo 10",
        value: "nf10",
    },
    NF_11: {
        label: "wordfreq, gruppo 11",
        value: "nf11",
    },
    NF_12: {
        label: "wordfreq, gruppo 12",
        value: "nf12",
    },
    NF_13: {
        label: "wordfreq, gruppo 13",
        value: "nf13",
    },
    NF_14: {
        label: "wordfreq, gruppo 14",
        value: "nf14",
    },
    NF_15: {
        label: "wordfreq, gruppo 15",
        value: "nf15",
    },
    NF_16: {
        label: "wordfreq, gruppo 16",
        value: "nf16",
    },
    NF_17: {
        label: "wordfreq, gruppo 17",
        value: "nf17",
    },
    NF_18: {
        label: "wordfreq, gruppo 18",
        value: "nf18",
    },
    NF_19: {
        label: "wordfreq, gruppo 19",
        value: "nf19",
    },
    NF_20: {
        label: "wordfreq, gruppo 20",
        value: "nf20",
    },
    NF_21: {
        label: "wordfreq, gruppo 21",
        value: "nf21",
    },
    NF_22: {
        label: "wordfreq, gruppo 22",
        value: "nf22",
    },
    NF_23: {
        label: "wordfreq, gruppo 23",
        value: "nf23",
    },
    NF_24: {
        label: "wordfreq, gruppo 24",
        value: "nf24",
    },
    NF_25: {
        label: "wordfreq, gruppo 25",
        value: "nf25",
    },
    NF_26: {
        label: "wordfreq, gruppo 26",
        value: "nf26",
    },
    NF_27: {
        label: "wordfreq, gruppo 27",
        value: "nf27",
    },
    NF_28: {
        label: "wordfreq, gruppo 28",
        value: "nf28",
    },
    NF_29: {
        label: "wordfreq, gruppo 29",
        value: "nf29",
    },
    NF_30: {
        label: "wordfreq, gruppo 30",
        value: "nf30",
    },
    NF_31: {
        label: "wordfreq, gruppo 31",
        value: "nf31",
    },
    NF_32: {
        label: "wordfreq, gruppo 32",
        value: "nf32",
    },
    NF_33: {
        label: "wordfreq, gruppo 33",
        value: "nf33",
    },
    NF_34: {
        label: "wordfreq, gruppo 34",
        value: "nf34",
    },
    NF_35: {
        label: "wordfreq, gruppo 35",
        value: "nf35",
    },
    NF_36: {
        label: "wordfreq, gruppo 36",
        value: "nf36",
    },
    NF_37: {
        label: "wordfreq, gruppo 37",
        value: "nf37",
    },
    NF_38: {
        label: "wordfreq, gruppo 38",
        value: "nf38",
    },
    NF_39: {
        label: "wordfreq, gruppo 39",
        value: "nf39",
    },
    NF_40: {
        label: "wordfreq, gruppo 40",
        value: "nf40",
    },
    NF_41: {
        label: "wordfreq, gruppo 41",
        value: "nf41",
    },
    NF_42: {
        label: "wordfreq, gruppo 42",
        value: "nf42",
    },
    NF_43: {
        label: "wordfreq, gruppo 43",
        value: "nf43",
    },
    NF_44: {
        label: "wordfreq, gruppo 44",
        value: "nf44",
    },
    NF_45: {
        label: "wordfreq, gruppo 45",
        value: "nf45",
    },
    NF_46: {
        label: "wordfreq, gruppo 46",
        value: "nf46",
    },
    NF_47: {
        label: "wordfreq, gruppo 47",
        value: "nf47",
    },
    NF_48: {
        label: "wordfreq, gruppo 48",
        value: "nf48",
    },
};
Object.freeze(Priority);
export default Priority;
