import router from "@/router";
import { io } from "socket.io-client";
import ToastEventBus from "primevue/toasteventbus";
import { fromSimpleText } from "@/utils/toastFactory";

const socketModule = {
    state() {
        return {
            socket: undefined,
        };
    },
    getters: {
        socket: (state) => state.socket,
    },
    mutations: {
        SET_SOCKET: (state, socket) => {
            state.socket = socket;
        },
    },
    actions: {
        createSocket: (context, { socketToken, userId }) => {
            if (context.getters.socket === undefined) {
                const socket = io(process.env.VUE_APP_SERVER_URL, {
                    auth: {
                        socketToken,
                        userId,
                    },
                });

                socket.on("notification", (notification) => {
                    context.commit("HANDLE_INCOMING_SOCKET_NOTIFICATION", notification);
                });
                socket.on("deleteGroupedNotification", async ({ groupId, excludedUserId }) => {
                    await context.dispatch("deleteGroupedNotification", { groupId, excludedUserId });
                });
                socket.on("forceReleaseLock", async () => {
                    if (router.currentRoute.value.name === "admin.entries.detail") {
                        await router.push({ name: "admin.entries" });
                        ToastEventBus.emit("add", fromSimpleText(
                            "warn",
                            "Sessione scaduta",
                            "La tua sessione di modifica del termine è scaduta!",
                        ));
                    }
                });
                socket.on("disconnect", () => context.commit("SET_SOCKET", undefined));

                context.commit("SET_SOCKET", socket);
            }
        },
    },
};

export default socketModule;
