<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-py-5">
                <Message severity="error" :closable="false" v-if="!submissionSuccess">
                    Si è verificato un errore durante l'aggiornamento della preferenza.
                </Message>
                <h1>Impostazioni</h1>
                <div class="p-grid">
                    <div class="p-field p-col-12 p-d-flex p-ai-center">
                        <div>
                            <label for="wantsEnglish"
                                   class="p-mr-4 p-mr-xl-0 p-text-bold p-d-block">
                                Visualizzazione traduzione inglese
                            </label>
                            <small id="wantsEnglish-help" class="p-d-block">
                                Abilitando la visualizzazione dell'inglese, tutti i compilatori
                                potranno vedere le traduzioni originali di JMdict.
                            </small>
                        </div>
                        <InputSwitch id="wantsEnglish" aria-describedby="wantsEnglish-help"
                                     v-model="wantsEnglish"
                                     class="p-ml-auto switch-flex-shrink"
                                     @change="updateEnglishPreference"/>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import InputSwitch from "primevue/inputswitch";
import Message from "primevue/message";

export default {
    name: "Preferences",
    components: {
        InputSwitch, Message,
    },
    setup() {
        const store = useStore();
        const submissionSuccess = ref(true);
        const wantsEnglish = ref(store.getters.englishPreference);

        const updateEnglishPreference = async () => {
            try {
                await store.dispatch("setEnglishPreference", wantsEnglish.value);
            } catch (err) {
                submissionSuccess.value = false;
            }
        };

        return {
            wantsEnglish,
            updateEnglishPreference,
            submissionSuccess,
        };
    },
};
</script>

<style scoped>
    .switch-flex-shrink {
        flex-shrink: 0;
    }
</style>
