const ReadingInfo = {
    GIKUN: {
        label: "Gikun (lettura per significato) o jukujikun (lettura speciale)",
        value: "gikun",
    },
    IRREGULAR_KANA: {
        label: "Parola con kana usati in maniera irregolare",
        value: "ik",
    },
    OUTDATED_KANA: {
        label: "Uso dei kana antiquato o in disuso",
        value: "ok",
    },
    USUALLY_KANJI_ALONE: {
        label: "Parola spesso scritta usando solo kanji",
        value: "uK",
    },
};
Object.freeze(ReadingInfo);
export default ReadingInfo;
