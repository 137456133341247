<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12">
            <JLabelHelpButton :id="`sourceTerm-${senseKey}-lang-${itemKey}`"
                              label="Lingua del termine sorgente*">
                Inserisci qui la lingua dalla quale il termine sorgente proviene.
            </JLabelHelpButton>
            <Dropdown
                :aria-labelledby="`sourceTerm-${senseKey}-lang-${itemKey}`"
                :aria-describedby="boolObjectToString({
                            [`sourceTerm-${senseKey}-lang-${itemKey}-help`]: true,
                            [`sourceTerm-${senseKey}-lang-${itemKey}-err`]: v$.lang.$error,
                        })"
                :class="{ 'p-invalid': v$.lang.$error }"
                :filter="true" :options="languages" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled" dataKey="value" v-model="v$.lang.$model"/>
            <small class="p-error" :id="`sourceTerm-${senseKey}-lang-${itemKey}-err`"
                   v-if="v$.lang.$error">
                Il campo è obbligatorio.
            </small>
        </div>
        <div class="p-field p-col-12 p-lg-6">
            <JLabelHelpButton :id="`sourceTerm-${senseKey}-type-${itemKey}`"
                              label="Livello di descrizione del termine*">
                Imposta questo campo a "Parziale" se il termine sorgente descrive solo
                parzialmente il campo semantico del termine.
            </JLabelHelpButton>
            <Dropdown
                :aria-labelledby="`sourceTerm-${senseKey}-type-${itemKey}`"
                :aria-describedby="`sourceTerm-${senseKey}-type-${itemKey}-help`"
                :options="sourceTermTypes" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled" dataKey="value" v-model="item.type"/>
        </div>
        <div class="p-field p-col-12 p-lg-6">
            <div class="p-field-checkbox">
                <Checkbox
                    :id="`sourceTerm-${senseKey}-wasei-${itemKey}`"
                    :binary="true"
                    :aria-describedby="`sourceTerm-${senseKey}-wasei-${itemKey}-help`"
                    :disabled="disabled" v-model="item.waseieigo"/>
                <JLabelHelpButton :forId="`sourceTerm-${senseKey}-wasei-${itemKey}`"
                                  :isRight="true" label="È wasei gairaigo?">
                    Spunta questa casella se la parola giapponese è stata costruita da
                    parole della lingua sorgente, e non da una frase di quel linguaggio.
                </JLabelHelpButton>
            </div>
        </div>
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`sourceTerm-${senseKey}-term-${itemKey}`"
                              label="Termine sorgente">
                Il contenuto del termine sorgente. Lascia vuoto se le definizioni sono uguali
                al termine sorgente.
            </JLabelHelpButton>
            <InputText
                :id="`sourceTerm-${senseKey}-term-${itemKey}`"
                :aria-describedby="`sourceTerm-${senseKey}-term-${itemKey}-help`"
                :placeholder="joinedGlosses" :disabled="disabled" type="text" v-model="item.term"/>
        </div>
    </div>
</template>

<script>
import {
    computed,
    reactive,
    ref,
    watch,
} from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import LanguageCode from "@/enums/iso639";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JSourceTermEditor",
    components: {
        Checkbox,
        Dropdown,
        InputText,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        glosses: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const rules = {
            lang: { required },
        };

        const v$ = useVuelidate(rules, item);

        const languages = ref(Object.values(LanguageCode));
        const sourceTermTypes = ref([
            {
                value: "full",
                label: "Totale",
            },
            {
                value: "part",
                label: "Parziale",
            },
        ]);

        const joinedGlosses = computed(() => props.glosses
            .map((gloss) => gloss.term)
            .join("; "));

        return {
            v$,
            item,
            languages,
            sourceTermTypes,
            joinedGlosses,
            boolObjectToString,
        };
    },
};
</script>

<style scoped>

</style>
