<template>
  <template v-for="(tag, tagIndex) of displayedTags" :key="tagIndex">
    <Tag v-if="tag && tag.label" class="p-mr-1 tag">
      {{ tag.label }}
    </Tag>
    <Tag v-else-if="tag" class="p-mr-1 tag">
      {{ tag.value }}
    </Tag>
  </template>
  <template v-for="(pos, posIndex) of sense.partsOfSpeech" :key="posIndex">
    <Tag v-if="pos !== 'unc'" class="p-mr-1 pos" tabindex="0" role="button" v-tooltip.bottom="getPartOfSpeechDescription(pos)">
      {{ pos }}
    </Tag>
  </template>
  <ul class="glosses-list list-inline-semicolon" v-if="(sense.glosses?.length ?? 0) > 0">
    <li v-for="(gloss, glossIndex) of sense.glosses" :key="glossIndex">
      <template v-if="gloss.type"> {{ gloss.term }} ({{ getGlossTypeAbbreviation(gloss.type) }}) </template>
      <template v-else>
        {{ gloss.term }}
      </template>
    </li>
  </ul>
  <ul class="glosses-list list-inline-semicolon" v-else>
    <li class="p-text-italic">
      Nessuna traduzione in questo campo semantico
    </li>
  </ul>
  <div v-if="(appliesToList?.length ?? 0) > 0" class="extra-list">
    Vale per:
    <ul class="list-inline">
      <li v-for="(appliesTo, appliesToIndex) of appliesToList" :key="appliesToIndex">
        <span :class="{ serif: isSerif }">
          {{ appliesTo }}
        </span>
      </li>
    </ul>
  </div>
  <div v-if="(sense.misc?.length ?? 0) > 0 || (sense.dialects?.length ?? 0) > 0" class="extra-list">
    <ul class="list-inline">
      <li v-for="(dialect, dialectIndex) of sense.dialects" :key="dialectIndex">
        {{ getDialectDescription(dialect) }}
      </li>
      <li v-for="(misc, miscIndex) of sense.misc" :key="miscIndex">
        {{ getMiscellaneousEntryInfoDescription(misc) }}
      </li>
    </ul>
  </div>
  <div v-if="(sense.notes?.length ?? 0) > 0" class="extra-list">
    <ul class="list-inline">
      <li v-for="(note, noteIndex) of sense.notes" :key="noteIndex" :class="{ serif: isSerif }">
        {{ note.text }}
      </li>
    </ul>
  </div>
  <div v-if="(sense.sourceTerms?.length ?? 0) > 0" class="extra-list">
    <ul class="list-inline">
      <li v-for="(sourceTerm, sourceTermIndex) of sense.sourceTerms" :key="sourceTermIndex">
        <template v-if="sourceTerm.term"> Deriva <template v-if="sourceTerm.type === 'part'">parzialmente </template> da "{{ sourceTerm.term }}" ({{ getLanguageDescription(sourceTerm.lang) }}) </template>
        <template v-else> Lingua di origine: {{ getLanguageDescription(sourceTerm.lang) }} </template>
      </li>
    </ul>
  </div>
  <div v-if="(crossReferences?.length ?? 0) > 0" class="extra-list">
    Vedi anche:
    <ul v-if="!isBackoffice" class="list-inline">
      <li v-for="(xref, xrefIndex) of crossReferences" :key="xrefIndex">
        <router-link :to="{ name: 'entry', params: { slug: xref.label, entryId: xref.value } }">
          <span :class="{ serif: isSerif }">{{ xref.label }}</span>
        </router-link>
        <template v-if="xref.antonym">(contr.)</template>
      </li>
    </ul>
    <Skeleton v-else-if="crossReferencesLoading" />
    <ul v-else class="list-inline">
      <li v-for="(xref, xrefIndex) of crossReferences" :key="xrefIndex">
        <router-link :to="{ name: 'admin.entries.detail', params: { entryId: xref.value } }">
          <span :class="{ serif: isSerif }">{{ xref.label }}</span>
        </router-link>
        <template v-if="xref.antonym">(contr.)</template>
      </li>
    </ul>
  </div>
  <div class="extra-list examples" v-if="(sense.examples?.length ?? 0) > 0">
    <ul>
      <li v-for="(example, exampleIndex) of sense.examples" :key="exampleIndex">
        <p class="original p-mt-2" :class="{ serif: isSerif }">{{ example.original }}</p>
        <p :class="{ translation: true, 'p-mb-2': !example.source, serif: isSerif }">
          {{ example.translation }}
        </p>
        <p class="source p-mb-2" v-if="example.source">{{ example.source }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { isObject } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Tag from 'primevue/tag'
import Skeleton from 'primevue/skeleton'
import PartOfSpeech from '@/enums/partOfSpeech'
import MiscellaneousEntryInfo from '@/enums/misc'
import LanguageCode from '@/enums/iso639'
import Dialect from '@/enums/dialect'
import GlossType from '@/enums/glossType'

export default {
  name: 'JEntrySense',
  components: {
    Tag,
    Skeleton,
  },
  props: {
    sense: {
      type: Object,
      required: true,
    },
    kanji: {
      type: Object,
      required: true,
    },
    readings: {
      type: Object,
      required: true,
    },
    isBackoffice: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
    },
  },
  setup(props) {
    const store = useStore()

    const isSerif = ref(!!store.getters.loggedUser?.wantsSerif)

    const displayedTags = computed(() =>
      props.sense.tags.map((tag) => {
        if (isObject(tag)) {
          // Tag is an object with _id, name and prettyName
          return { label: tag.prettyName, value: tag.name }
        }
        // Tag is an ObjectId
        const foundTag = props?.tags?.find((t) => t._id === tag)
        if (foundTag) {
          return { label: foundTag.prettyName, value: foundTag.name }
        }
        return null
      })
    )
    const appliesToList = computed(() => {
      const kanjiList = props.sense.appliesToKanji
        .map((kanjiId) => {
          const foundKanji = props.kanji.find((kanji) => kanji._id === kanjiId)
          return foundKanji ? foundKanji.term : null
        })
        .filter((kanji) => !!kanji)
      const readingsList = props.sense.appliesToReadings
        .map((readingId) => {
          const foundReading = props.readings.find((reading) => reading._id === readingId)
          return foundReading ? foundReading.term : null
        })
        .filter((reading) => !!reading)
      return [...kanjiList, ...readingsList]
    })

    const crossReferencesLoading = ref(true)
    const referencedEntries = ref([])
    const mapCrossReferences = (xrefs, antonym) => {
      if (!props.isBackoffice) {
        // Cross references are objects
        return xrefs?.map((xref) => ({
          label: xref.entry.term,
          value: xref.entry._id,
          antonym,
        }))
      }
      // Cross references are IDs
      return xrefs?.map((xref) => {
        const referencedEntry = referencedEntries.value.find((entry) => entry._id === xref.entry)
        if (referencedEntry) {
          const primaryKanji = referencedEntry.kanji?.[0] ?? null
          const primaryReading = primaryKanji ? referencedEntry.readings.find((reading) => reading.restrict === primaryKanji._id) ?? referencedEntry.readings[0] ?? null : referencedEntry.readings[0] ?? null
          const referencedKanji = xref.kanji ? referencedEntry.kanji.find((kanji) => kanji._id === xref.kanji) : primaryKanji ?? null
          const referencedReading = xref.reading ? referencedEntry.readings.find((reading) => reading._id === xref.reading) : primaryReading ?? null
          if (referencedKanji && referencedReading) {
            return {
              label: `${referencedKanji.term}【${referencedReading.term}】`,
              value: referencedEntry._id,
              antonym,
            }
          }
          if (referencedReading) {
            return {
              label: referencedReading.term,
              value: referencedEntry._id,
              antonym,
            }
          }
          return null
        }
        return null
      })
    }
    const crossReferences = ref()
    const getLanguageDescription = (lang) =>
      Object.values(LanguageCode)
        .find((l) => l.value === lang)
        ?.label.toLowerCase() ?? 'lingua indeterminata'
    const getPartOfSpeechDescription = (pos) => Object.values(PartOfSpeech).find((p) => p.value === pos)?.label ?? 'Parte del discorso sconosciuta'
    const getMiscellaneousEntryInfoDescription = (misc) => Object.values(MiscellaneousEntryInfo).find((m) => m.value === misc)?.label ?? 'Informazione aggiuntiva sconosciuta'
    const getDialectDescription = (dialect) => Object.values(Dialect).find((d) => d.value === dialect)?.label ?? 'Dialetto sconosciuto'
    const getGlossTypeAbbreviation = (glossType) => Object.values(GlossType).find((g) => g.value === glossType)?.shortLabel ?? null

    const refreshCrossReferences = async () => {
      if (!props.isBackoffice) {
        // We're not in the backoffice, so the entries' cross references are objects with
        // the _id and term fields that tell us how to display them, we don't need to
        // fetch every single referenced entry to build that piece of information ourselves
        crossReferencesLoading.value = false
        return
      }

      // We're in backoffice, so it's safe to call the endpoints
      const entriesToFetch = [...props.sense.crossReferences, ...props.sense.antonyms].map((xref) => xref.entry).map((entryId) => store.dispatch('admin/getEntry', entryId))
      try {
        const responses = await Promise.all(entriesToFetch)
        referencedEntries.value = responses.map((response) => response.data)
        crossReferences.value = [...mapCrossReferences(props.sense.crossReferences, false), ...mapCrossReferences(props.sense.antonyms, true)]
      } catch (err) {
        // Couldn't load all cross references, return none
        referencedEntries.value = []
      } finally {
        crossReferencesLoading.value = false
      }
    }
    watch(props.sense.crossReferences, async () => refreshCrossReferences())
    watch(props.sense.antonyms, async () => refreshCrossReferences())

    onMounted(async () => {
      await refreshCrossReferences()
    })

    return {
      isSerif,
      crossReferencesLoading,
      referencedEntries,
      displayedTags,
      appliesToList,
      crossReferences,
      getPartOfSpeechDescription,
      getMiscellaneousEntryInfoDescription,
      getLanguageDescription,
      getDialectDescription,
      getGlossTypeAbbreviation,
      refreshCrossReferences,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/theme/_variables';

.p-tag {
  &.tag {
    background-color: $highlightBg;
    color: $highlightTextColor;
  }
  &.pos {
    background-color: #e3e3e3;
    color: $secondaryButtonBg;
  }
}

ul.glosses-list {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline;
  }
}

.extra-list {
  font-style: italic;
  margin-top: 0;
  font-size: 0.8rem;

  ul {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline;
    }
  }
}

ul.list-inline li:not(:last-child)::after {
  content: ', ';
}

ul.list-inline-semicolon li:not(:last-child)::after {
  content: '; ';
}

.examples {
  margin-left: 1.5rem;

  .original,
  .translation,
  .source {
    color: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
