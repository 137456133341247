const dateTimeFormat = Intl.DateTimeFormat("it", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
});

export default function renderDate(date) {
    return dateTimeFormat.format(new Date(date));
}
