<template>
<h1 class="desktop-only">Clicca su una collezione per visualizzarne i contenuti!</h1>
</template>

<script>
export default {
    name: "JStartingCollection",
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/theme/variables";
@import "~primeflex/src/_variables.scss";

.desktop-only {
    @media (max-width: $md) {
        display: none;
    }
}
</style>
