import { ref } from "vue";
import Paginator from "@/utils/pagination/paginator";

/**
 * Given an async getter and an error message creates the reactive elements that describe the status
 * of the pagination and the obtained elements.
 * @param getter a getter that takes as parameters a page number and optionally a number of elements
 *               to skip
 * @param error an error message value to use in case of loading errors
 */
function usePagination(getter, error) {
    const paginator = Paginator.from(getter);

    const elements = ref([]);
    const isLoading = ref(false);
    const loadingError = ref();
    const isOver = ref(false);
    const loadNext = async (skip) => {
        try {
            if (!(paginator.isOver || !!loadingError.value || isLoading.value)) {
                isLoading.value = true;
                const newElements = await paginator.loadNext(skip);
                elements.value = elements.value.concat(newElements);
                isLoading.value = false;
                if (paginator.isOver) {
                    isOver.value = true;
                }
            }
        } catch (err) {
            loadingError.value = error;
            isLoading.value = false;
        }
    };
    const reset = () => {
        elements.value = [];
        isLoading.value = false;
        loadingError.value = undefined;
        isOver.value = false;
        paginator.reset();
    };

    return {
        elements,
        isLoading,
        loadingError,
        isOver,
        loadNext,
        reset,
    };
}

export default usePagination;
