const Dialect = {
    BRA: {
        label: "Brasiliano",
        value: "bra",
    },
    HOB: {
        label: "Hokkaido-ben",
        value: "hob",
    },
    KSB: {
        label: "Kansai-ben",
        value: "ksb",
    },
    KTB: {
        label: "Kantou-ben",
        value: "ktb",
    },
    KYB: {
        label: "Kyoto-ben",
        value: "kyb",
    },
    KYU: {
        label: "Kyuushuu-ben",
        value: "kyu",
    },
    NAB: {
        label: "Nagano-ben",
        value: "nab",
    },
    OSB: {
        label: "Osaka-ben",
        value: "osb",
    },
    RKB: {
        label: "Ryuukyuu-ben",
        value: "rkb",
    },
    THB: {
        label: "Touhoku-ben",
        value: "thb",
    },
    TSB: {
        label: "Tosa-ben",
        value: "tsb",
    },
    TSUG: {
        label: "Tsugaru-ben",
        value: "tsug",
    },
};
Object.freeze(Dialect);
export default Dialect;
