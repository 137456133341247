<template>
    <Dialog header="Scritture/letture di riferimento" :visible="visible"
            @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-appliesToKanji-${senseKey}`"
                                  label="Vale per le scritture">
                    Indica per quali scritture vale questo campo semantico.
                    Se vuoto, il campo semantico varrà per tutte le scritture.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.appliesToKanji" :filter="true"
                    :options="renderIdTermItem(kanji)" :showToggleAll="!disabled"
                    optionLabel="term" optionValue="_id"
                    placeholder="Tutte le scritture"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-appliesToKanji-${senseKey}`"
                    :aria-describedby="`sense-appliesToKanji-${senseKey}-help`"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-appliesToReadings-${senseKey}`"
                                  label="Vale per le letture">
                    Indica per quali letture vale questo campo semantico.
                    Se vuoto, il campo semantico varrà per tutte le letture.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.appliesToReadings" :filter="true"
                    :options="renderIdTermItem(readings)" :showToggleAll="!disabled"
                    optionLabel="term" optionValue="_id"
                    placeholder="Tutte le letture"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-appliesToReadings-${senseKey}`"
                    :aria-describedby="`sense-appliesToReadings-${senseKey}-help`"/>
            </div>
        </div>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";

export default {
    name: "JAppliesToEditor",
    components: {
        Button,
        Dialog,
        MultiSelect,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        kanji: {
            type: Array,
            required: true,
        },
        readings: {
            type: Array,
            required: true,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "onDialogClose"],
    setup(props) {
        const sense = ref(props.modelValue);

        const v$ = useVuelidate();

        const renderIdTermItem = (kanji) => kanji.map((k) => ({
            _id: k._id,
            term: k.term,
        }));

        return {
            v$,
            sense,
            renderIdTermItem,
        };
    },
};
</script>
