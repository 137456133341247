<template>
    <div :class="{ scrollable: isDialog }"
         ref="scrollNotificationMenu" :onscroll="onScroll">
        <h1 :class="{ 'p-my-0': isDialog, 'p-pt-3': isDialog, 'p-mx-3': isDialog }">
            Notifiche
        </h1>
        <div :class="{ 'p-mx-3': isDialog }">
            <Button class="p-mr-2 p-mt-3" label="Cancella tutte" icon="pi pi-trash"
                    @click="onDeleteClick"/>
            <Button class="p-mt-3" label="Segna tutte come lette" icon="pi pi-eye"
                    @click="onReadClick"/>
        </div>
        <p v-if="notifications.length === 0 && !isLoadingNotifications
                 && !notificationsLoadingError" :class="{ 'p-mx-3': isDialog }">
            Non ci sono notifiche!
        </p>
        <j-notification v-for="notification of notifications"
                        :notification="notification"
                        :key="notification._id"
                        :class="{ 'p-mx-3': isDialog }"/>
        <div :class="{ 'p-mx-3': isDialog }">
            <j-error v-if="notificationsLoadingError" :error="notificationsLoadingError"/>
            <j-error v-else-if="areNotificationsOver && notifications.length > 0"
                     :error="{ title: 'Notifiche terminate!' }"/>
        </div>
    </div>
</template>

<script>
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
} from "vue";
import { useStore } from "vuex";
import Button from "primevue/button";
import JError from "@/components/JError.vue";
import JNotification from "@/components/notification/JNotification.vue";

export default {
    name: "JNotifications",
    components: { Button, JError, JNotification },
    props: {
        isDialog: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup() {
        const store = useStore();
        const notificationsModule = store.state.notifications;
        const notifications = computed(() => notificationsModule.notifications);
        const areNotificationsOver = computed(() => notificationsModule.areNotificationsOver);
        const notificationsLoadingError = computed(
            () => notificationsModule.notificationsLoadingError,
        );
        const isLoadingNotifications = computed(() => notificationsModule.isLoadingNotifications);
        onMounted(() => store.dispatch("loadNotifications"));
        onUnmounted(async () => store.commit("RESET_NOTIFICATIONS"));

        const onDeleteClick = async () => store.dispatch("deleteAllNotifications");
        const onReadClick = async () => store.dispatch("markAllAsRead");

        const scrollNotificationMenu = ref();
        const onScroll = async () => {
            const element = scrollNotificationMenu.value;
            if (element?.scrollTop >= element?.scrollHeight - element?.offsetHeight - 200
                && !notificationsModule.isLoadingNotifications
                && !notificationsModule.areNotificationsOver) {
                await store.dispatch("loadNotifications");
            }
        };
        return {
            notifications,
            isLoadingNotifications,
            areNotificationsOver,
            notificationsLoadingError,
            onDeleteClick,
            onReadClick,
            scrollNotificationMenu,
            onScroll,
        };
    },
};
</script>

<style scoped lang="scss">
    .scrollable {
        max-height: 60vh;
        overflow-y: auto;
    }
</style>
