<template>
    <template v-if="!loading && error === null">
        <Message severity="error" :closable="false" v-if="v$.$errors.length > 0">
            Hai {{ errorsCount }} da risolvere.
        </Message>
        <Message v-if="message" :closable="message?.closable ?? false" :severity="message.severity">
            {{ message.message }}
        </Message>
        <h1 class="p-d-flex p-ai-center">{{ collaboratorFullName }}</h1>
        <div class="p-fluid p-formgrid p-grid entry-detail">
            <div class="p-field p-col-6 p-md-6">
                <JLabelHelpButton forId="id" label="ID interno">
                    ID interno del collaboratore. Questo valore non è modificabile.
                </JLabelHelpButton>
                <InputText id="id" type="text" :value="collaborator._id" disabled
                           aria-describedby="id-help"/>
            </div>
            <div class="p-field p-col-6 p-md-6">
                <JLabelHelpButton forId="priority" label="Priorità">
                    Priorità. Numero minore corrisponde a priorità maggiore.
                </JLabelHelpButton>
                <InputNumber id="priority"  showButtons :format="false" :min="0" :max="100" :step="1" v-model="v$.priority.$model" 
                           aria-describedby="id-priority"/>
            </div>

            
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton forId="firstName" label="Nome*">
                    Nome del collaboratore.
                </JLabelHelpButton>
                <InputText id="firstName" type="text" v-model="v$.firstName.$model"
                           :aria-describedby="boolObjectToString({
                               'firstName-help': true,
                               'firstName-err': v$.firstName.$error,
                           })"
                           :class="{ 'p-invalid': v$.firstName.$error }"/>
                <small class="p-error" id="firstName-err" v-if="v$.firstName.$error">
                    Il campo è obbligatorio.
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton forId="lastName" label="Cognome*">
                    Cognome del collaboratore.
                </JLabelHelpButton>
                <InputText id="lastName" type="text" v-model="v$.lastName.$model"
                           :aria-describedby="boolObjectToString({
                               'lastName-help': true,
                               'lastName-err': v$.lastName.$error,
                           })"
                           :class="{ 'p-invalid': v$.lastName.$error }"/>
                <small class="p-error" id="lastName-err" v-if="v$.lastName.$error">
                    Il campo è obbligatorio.
                </small>
            </div>
            <div class="p-field p-col-12">
                <div class="p-mb-2 p-d-flex p-ai-center p-flex-wrap profile-pic">
                    <Avatar :image="collaboratorProfilePicture"
                            :icon="!collaboratorProfilePicture ? 'pi pi-user' : null"
                            shape="circle" size="xlarge"/>
                    <JLabelHelpButton forId="profilePicture" label="Foto di profilo*"
                                      :isRight="true">
                        Carica una foto di profilo per questo collaboratore. I formati supportati
                        sono JPEG e PNG. Il formato ideale sarebbe quadrato, ma in caso di
                        immagine rettangolare questa verrà ritagliata.
                    </JLabelHelpButton>
                </div>
                <FileUpload name="profilePicture" accept="image/jpeg,image/png"
                            :multiple="false" :maxFileSize="1000000" :fileLimit="1"
                            :showUploadButton="false" :showCancelButton="false"
                            invalidFileLimitMessage="È possibile caricare solo un file."
                            invalidFileSizeMessage="La dimensione massima consentita è {1}."
                            :aria-describedby="boolObjectToString({
                               'profilePicture-help': true,
                               'profilePicture-err': v$.profilePicture.$error,
                            })"
                            @select="onFileChanged" @remove="onFileChanged">
                    <template #empty>
                        <p>Trascina qui l'immagine di profilo da caricare.</p>
                    </template>
                </FileUpload>
                <small class="p-error" id="profilePicture-err" v-if="v$.profilePicture.$error">
                    Il campo è obbligatorio.
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton forId="startYear" label="Anno di inizio">
                    Anno di inizio della collaborazione. Lascia vuoto se è permanente.
                </JLabelHelpButton>
                <InputText id="startYear" type="number" v-model.number="v$.startYear.$model"
                           class="no-spin"
                           :aria-describedby="boolObjectToString({
                               'startYear-help': true,
                               'startYear-err': v$.startYear.$error,
                            })"/>
                <small class="p-error" id="startYear-err" v-if="v$.startYear.$error">
                    <template
                        v-if="v$.startYear.$errors.some((err) => err.$validator === 'required')">
                        Il campo è obbligatorio, essendo specificato un anno di fine collaborazione.
                    </template>
                    <template v-if="v$.startYear.$errors.length > 1">
                        <br>
                    </template>
                    <template
                        v-if="v$.startYear.$errors.some((err) => err.$validator === 'numeric')">
                        Il campo deve essere un numero.
                    </template>
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton forId="endYear" label="Anno di fine">
                    Anno di fine della collaborazione. Lascia vuoto se è ancora in corso.
                </JLabelHelpButton>
                <InputText id="endYear" type="number" v-model.number="v$.endYear.$model"
                           class="no-spin"
                           :aria-describedby="boolObjectToString({
                               'endYear-help': true,
                               'endYear-err': v$.endYear.$error,
                            })"/>
                <small class="p-error" id="endYear-err" v-if="v$.endYear.$error">
                    <template
                        v-if="v$.endYear.$errors.some((err) => err.$validator === 'minValue')">
                        L'anno di fine non può essere minore dell'anno di inizio collaborazione.
                    </template>
                    <template v-if="v$.endYear.$errors.length > 1">
                        <br>
                    </template>
                    <template
                        v-if="v$.endYear.$errors.some((err) => err.$validator === 'numeric')">
                        Il campo deve essere un numero.
                    </template>
                </small>
            </div>
            <div class="p-field p-col-12">
                <JLabelHelpButton forId="role" label="Ruolo">
                    Ruolo assunto dal collaboratore (opzionale).
                </JLabelHelpButton>
                <InputText id="role" type="text" v-model="collaborator.role"
                           aria-describedby="role-help"/>
            </div>
            <div class="p-field p-col-12">
                <JLabelHelpButton forId="description" label="Descrizione">
                    Una breve descrizione del collaboratore (opzionale).
                </JLabelHelpButton>
                <Textarea id="description" type="text" v-model="collaborator.description"
                          rows="5" :autoResize="true" aria-describedby="description-help"/>
            </div>
        </div>
        <div class="p-d-flex p-dir-rev p-ai-center">
            <Button label="Salva" icon="pi pi-save" class="p-ml-2 p-button-success"
                    :loading="submissionLoading" @click="onSave"/>
            <Button label="Elimina" icon="pi pi-trash" class="p-button-danger"
                    @click="onDelete" v-if="collaboratorId"/>
            <p class="errors-count" v-if="v$.$errors.length > 0">{{ errorsCount }}</p>
        </div>
    </template>
    <template v-else-if="error !== null">
        <JError :error="error"/>
    </template>
</template>

<script>
import {
    computed,
    onMounted,
    reactive,
    ref,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
    required,
    requiredIf,
    numeric,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useConfirm } from "primevue/useconfirm";
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import Textarea from "primevue/textarea";
import JError from "@/components/JError.vue";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "CollaboratorDetail",
    components: {
        Avatar,
        Button,
        FileUpload,
        InputText,
        InputNumber,
        Message,
        Textarea,
        JError,
        JLabelHelpButton,
    },
    props: {
        collaboratorId: {
            type: String,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const confirm = useConfirm();

        const collaborator = reactive({
            _id: "",
            firstName: "",
            lastName: "",
            priority:0,
            profilePicture: null,
            startYear: null,
            endYear: null,
            role: "",
            description: "",
        });
        const mustBeSingle = (value) => value && !Array.isArray(value);
        const notBeforeStart = (value) => value === undefined
            || value === null
            || value === ""
            || value >= collaborator.startYear;
        const collaboratorRules = {
            firstName: { required },
            lastName: { required },
            priority: { numeric },
            profilePicture: { mustBeSingle },
            startYear: { required: requiredIf(() => collaborator.endYear), numeric },
            endYear: { numeric, minValue: notBeforeStart },
        };

        const v$ = useVuelidate(collaboratorRules, collaborator);
        const error = ref(null);
        const message = ref(null);
        const loading = ref(!!props.collaboratorId);
        const submissionLoading = ref(false);
        const collaboratorFullName = computed(() => (
            collaborator.firstName || collaborator.lastName
                ? `${collaborator.firstName} ${collaborator.lastName}`.trim()
                : "Collaboratore"));
        const collaboratorProfilePicture = computed(() => (
            collaborator.profilePicture instanceof File
                ? window.URL.createObjectURL(collaborator.profilePicture)
                : collaborator.profilePicture));
        const errorsCount = computed(() => (v$.value.$errors.length === 1
            ? "1 errore"
            : `${v$.value.$errors.length} errori`));

        const refreshCollaborator = async () => {
            loading.value = true;
            try {
                error.value = null;
                message.value = null;
                try {
                    const result = await store.dispatch(
                        "getCollaborator",
                        props.collaboratorId,
                    );
                    collaborator._id = result.data._id;
                    collaborator.priority = result.data.priority || 0;
                    collaborator.firstName = result.data.firstName;
                    collaborator.lastName = result.data.lastName;
                    collaborator.profilePicture = result.data.profilePicture;
                    collaborator.startYear = result.data.startYear;
                    collaborator.endYear = result.data.endYear;
                    collaborator.role = result.data.role;
                    collaborator.description = result.data.description;
                } catch (err) {
                    error.value = {
                        title: "Oh no!",
                        text: "Si è verificato un errore durante il recupero del collaboratore!",
                    };
                }
            } finally {
                loading.value = false;
            }
        };

        const onSave = async () => {
            let newId = null;
            v$.value.$touch();
            if (v$.value.$errors.length === 0) {
                try {
                    submissionLoading.value = true;
                    const formData = new FormData();
                    formData.append("firstName", collaborator.firstName);
                    formData.append("lastName", collaborator.lastName);
                    if (collaborator.startYear) {
                        formData.append("startYear", collaborator.startYear);
                    }
                    if (collaborator.endYear) {
                        formData.append("endYear", collaborator.endYear);
                    }
                    if (collaborator.role) {
                        formData.append("role", collaborator.role);
                    }
                    if (collaborator.priority) {
                        formData.append("priority", collaborator.priority);
                    }
                    if (collaborator.description) {
                        formData.append("description", collaborator.description);
                    }
                    if (collaborator.profilePicture instanceof File) {
                        formData.append("profilePicture", collaborator.profilePicture);
                    }
                    if (props.collaboratorId) {
                        await store.dispatch("editCollaborator", {
                            collaboratorId: props.collaboratorId,
                            collaborator: formData,
                        });
                    } else {
                        const response = await store.dispatch("addCollaborator", formData);
                        newId = response.data._id;
                    }
                    message.value = {
                        message: "Collaboratore salvato.",
                        severity: "success",
                    };
                    if (newId) {
                        // Redirect to the newly created ID page
                        await router.replace({
                            name: "admin.collaborators.detail", params: { collaboratorId: newId },
                        });
                    }
                } catch (err) {
                    message.value = {
                        message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                        severity: "error",
                        closable: true,
                    };
                } finally {
                    submissionLoading.value = false;
                    window.scrollTo(0, 0);
                }
            }
        };
        const onDelete = async () => {
            confirm.require({
                message: "Stai per cancellare un collaboratore. Vuoi continuare?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                async accept() {
                    try {
                        await store.dispatch("deleteCollaborator", props.collaboratorId);
                        await router.replace({ name: "admin.collaborators" });
                        await refreshCollaborator();
                        message.value = {
                            message: "Collaboratore cancellato.",
                            severity: "success",
                            closable: true,
                        };
                    } catch (err) {
                        message.value = {
                            message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                            severity: "error",
                            closable: true,
                        };
                    }
                },
            });
        };
        const onFileChanged = (event) => {
            collaborator.profilePicture = (event.files?.length ?? 0) === 1
                ? event.files[0]
                : null;
        };

        watch(() => props.collaboratorId, async () => refreshCollaborator());
        onMounted(async () => {
            if (props.collaboratorId) {
                await refreshCollaborator();
            }
        });

        return {
            v$,
            collaborator,
            error,
            message,
            loading,
            submissionLoading,
            collaboratorFullName,
            collaboratorProfilePicture,
            errorsCount,
            boolObjectToString,
            onSave,
            onDelete,
            onFileChanged,
        };
    },
};
</script>

<style lang="scss">
@import "src/assets/scss/theme/_variables";

.p-fluid .p-fileupload-row .p-button {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;
}
.p-avatar-image > img {
    object-fit: cover;
}
</style>

<style lang="scss" scoped>
.profile-pic {
    column-gap: 0.5rem;
}
textarea {
    resize: vertical;
}
.errors-count {
    margin: 0 1rem 0 0;
}
p {
    color: inherit;
}
</style>
