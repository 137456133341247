<template>
    <h1 class="mobile-only">{{ collectionName }}</h1>
    <j-error v-if="(!isLoading || isOver) && entries.length === 0"
             :error="{ title: 'Questa collezione è vuota!' }"/>
    <template v-else>
        <j-term-card class="p-mb-3" v-for="entry of entries" :term="entry" :key="entry._id"
                     @removedFromCollection="removeEntryFrom($event, entry._id)"
                     @addedToCollection="addEntryTo($event)"
                     @newCollection="handleNewCollection"/>
        <j-error v-if="isOver" :error="{ title: 'Sono finiti i termini della collezione' }"/>
        <j-error :error="loadingError"/>
    </template>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { remove } from "lodash";
import usePagination from "@/utils/pagination/paginationService";
import JError from "@/components/JError.vue";
import JTermCard from "@/components/JTermCard.vue";

export default {
    name: "JCollection",
    components: { JError, JTermCard },
    props: {
        collectionId: {
            type: String,
            required: true,
        },
        shouldLoadMore: {
            type: Number,
            default: 0,
        },
    },
    emits: ["removedFromCollection", "addedToCollection", "newCollection", "loadingStarted", "loadingEnded"],
    setup(props, { emit }) {
        const store = useStore();
        const entriesGetter = async (page) => store.dispatch("getCollectionEntries", {
            collectionId: props.collectionId,
            page,
        });
        const error = {
            title: "Oh no!",
            text: "Si è verificato un errore nel caricare la collezione!",
        };
        const {
            elements,
            isLoading,
            loadingError,
            isOver,
            loadNext,
            reset,
        } = usePagination(entriesGetter, error);
        const entries = elements;

        const removeEntryFrom = (collectionId, entryId) => {
            if (collectionId === props.collectionId) {
                remove(entries.value, (entry) => entry._id === entryId);
            }
            emit("removedFromCollection", collectionId);
        };
        const addEntryTo = (collectionId) => emit("addedToCollection", collectionId);
        const handleNewCollection = (collection) => emit("newCollection", collection);

        const collectionName = ref();
        onMounted(async () => {
            emit("loadingStarted");
            collectionName.value = await store.dispatch("getCollectionName", props.collectionId);
            await loadNext();
            emit("loadingEnded");
        });
        watch(() => props.collectionId, async () => {
            reset();
            emit("loadingStarted");
            collectionName.value = await store.dispatch("getCollectionName", props.collectionId);
            await loadNext();
            emit("loadingEnded");
        });
        watch(() => props.shouldLoadMore, async () => {
            if (!isLoading.value && !isOver.value) {
                emit("loadingStarted");
                await loadNext();
                emit("loadingEnded");
            }
        });

        return {
            entries,
            collectionName,
            isLoading,
            loadingError,
            isOver,
            removeEntryFrom,
            addEntryTo,
            handleNewCollection,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables.scss";

    .mobile-only {
        @media (min-width: $md) {
            display: none;
        }
    }
</style>
