<template>
    <div class="p-grid">
        <div v-if="otherKanjiList.length > 0" class="p-col-12 p-md-6">
            <component :is="titleElement" class="p-my-0">Scritture alternative</component>
            <ul>
                <li v-for="(writing, i) of otherKanjiList" :key="i">
                    <div class="p-d-flex p-dir-col" :class="{ serif: isSerif }">
                        <template v-if="writing.term">{{ writing.term }}</template>
                        <ul class="extra-list list-inline">
                            <li v-for="(orthoInfo, orthoInfoIndex) of writing.orthographicInfo"
                                :key="orthoInfoIndex">
                                {{ getOrthographicInfoDescription(orthoInfo) }}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="otherReadingsList.length > 0" class="p-col-12 p-md-6">
            <component :is="titleElement" class="p-my-0">Letture alternative</component>
            <ul>
                <li v-for="(reading, i) of otherReadingsList" :key="i">
                    <div class="p-d-flex p-dir-col">
                        <div :class="{ serif: isSerif }">
                            <template v-if="reading?.term">{{ reading.term }}</template>
                            <small class="romaji" v-if="reading?.romaji">
                                ({{ reading.romaji }})
                            </small>
                        </div>
                        <small v-if="reading?.noKanji">Slegato a una scrittura</small>
                        <div v-if="(reading?.restrict?.length ?? 0) > 0" class="extra-list">
                            Vale per:
                            <ul class="extra-list list-inline">
                                <li v-for="(appliesTo, appliesToIndex) of reading.restrict"
                                    :key="appliesToIndex">
                                    <span :class="{ serif: isSerif }">
                                        {{ getKanjiFromId(appliesTo)?.term }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <ul v-if="(reading?.readingInfo?.length ?? 0) > 0"
                            class="extra-list list-inline">
                            <li v-for="(readingInfo, readingInfoIndex) of reading.readingInfo"
                                :key="readingInfoIndex">
                                {{ getReadingInfoDescription(readingInfo) }}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import OrthographicInfo from "@/enums/orthographicInfo";
import ReadingInfo from "@/enums/readingInfo";

export default {
    name: "JEntryOtherKanjiReadings",
    props: {
        kanji: {
            type: Array,
            default() {
                return [];
            },
        },
        readings: {
            type: Array,
            default() {
                return [];
            },
        },
        titleElement: {
            type: String,
            default: "h3",
        },
    },
    setup(props) {
        const store = useStore();

        const isSerif = ref(!!store.getters.loggedUser?.wantsSerif);

        const primaryKanji = computed(() => props.kanji?.[0] ?? null);
        const primaryReading = computed(() => (primaryKanji.value
            ? props.readings
                .find((reading) => reading.restrict === primaryKanji.value._id)
                ?? props.readings[0] ?? null
            : props.readings[0] ?? null));

        const otherKanjiList = computed(() => props.kanji
            ?.filter((kanji) => kanji._id !== primaryKanji.value._id) ?? []);
        const otherReadingsList = computed(() => props.readings
            ?.filter((reading) => reading._id !== primaryReading.value._id) ?? []);

        const getKanjiFromId = (kanjiId) => props.kanji.find((kanji) => kanji._id === kanjiId);

        const getOrthographicInfoDescription = (orthoInfo) => Object.values(OrthographicInfo)
            .find((o) => o.value === orthoInfo)?.label
            ?? "Informazione ortografica sconosciuta";
        const getReadingInfoDescription = (readingInfo) => Object.values(ReadingInfo)
            .find((r) => r.value === readingInfo)?.label
            ?? "Informazione sulla lettura sconosciuta";

        return {
            isSerif,
            otherKanjiList,
            otherReadingsList,
            getOrthographicInfoDescription,
            getReadingInfoDescription,
            getKanjiFromId,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    ul {
        margin: 0;
    }

    .extra-list {
        font-style: italic;
        font-size: 0.8rem;
        display: inline;
        margin-top: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline;
        }
    }

    ul.list-inline li:not(:last-child)::after {
        content: ", ";
    }

    ul.list-inline-semicolon li:not(:last-child)::after {
        content: "; ";
    }

    .romaji {
        font-size: 1rem;
        &::before {
            content: " ";
        }
    }
</style>
