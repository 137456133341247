<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-py-5">
                <Breadcrumb :home="home" :model="currentPath" class="p-mb-4" />
                <router-view></router-view>
            </div>
        </div>
    </main>
</template>

<script>
import { isString } from "lodash";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import Breadcrumb from "primevue/breadcrumb";

export default {
    name: "Base",
    components: { Breadcrumb },
    setup() {
        const route = useRoute();

        const home = ref({
            icon: "pi pi-home",
            to: { name: "admin.home" },
        });
        const currentPath = computed(() => route.meta?.breadcrumbs
            ?.map((bc) => (isString(bc) ? { label: bc } : bc)) ?? []);

        return {
            home,
            currentPath,
        };
    },
};
</script>
