<template>
    <Card class="p-p-3">
        <template #title>
            <h1 class="p-my-0">Registrati</h1>
        </template>
        <template #content>
            <form id="signup-form" autocomplete="on">
                <div class="p-fluid">
                    <h2 class="p-mt-0">Account</h2>
                    <div class="p-field p-grid">
                        <label for="username" class="p-col-12 p-md-3 p-xl-2">Email</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <InputText
                                id="username" type="email" autocomplete="email"
                                v-model="v$.email.$model"
                                :class="{ 'p-invalid': v$.email.$invalid && isSubmitted }"/>
                            <small class="p-error p-d-block"
                                   v-if="((v$.email.$error || v$.email.$invalid) && isSubmitted)">
                                Email inserita non valida
                            </small>
                        </div>

                    </div>
                    <div class="p-field p-grid">
                        <label for="password" class="p-col-12 p-md-3 p-xl-2">Password</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <Password id="password" autocomplete="new-password"
                                      v-model="v$.password.$model"
                                      :class="{ 'p-invalid':(v$.password.$invalid ||
                                      !isPasswordSecure) && isSubmitted}" maxlength="72">
                                <template #footer="sp">
                                    {{ sp.level }}
                                    <Divider />
                                    <p class="p-mt-3 p-mb-0">Regole per una password sicura:</p>
                                    <ul class="p-pl-2 p-ml-3 p-mt-0" style="line-height: 1.5">
                                        <li>Almeno un carattere maiuscolo</li>
                                        <li>Almeno un carattere minuscono</li>
                                        <li>Almeno un numero</li>
                                        <li>Lunghezza minima 8 caratteri</li>
                                    </ul>
                                </template>
                            </Password>
                            <small v-if="!v$.password.minLength && isSubmitted"
                                   class="p-error p-d-block">
                                La password inserita deve essere lunga almeno 8 caratteri
                            </small>
                            <small v-else-if="v$.password.$invalid && isSubmitted"
                                   class="p-error p-d-block">
                                La password inserita non è valida
                            </small>
                            <small v-else-if="!isPasswordSecure && isSubmitted"
                                   class="p-error p-d-block">
                                La password inserita non è sicura
                            </small>
                        </div>

                    </div>

                    <div class="p-field p-grid">
                        <label for="repeatPassword" class="p-col-12 p-md-3 p-xl-2">
                            Conferma password
                        </label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <Password
                                id="repeatPassword" autocomplete="new-password"
                                v-model="v$.repeatPassword.$model"
                                :class="{ 'p-invalid': v$.repeatPassword.$model !==
                                  v$.password.$model }"
                                :feedback="false" maxlength="72"/>
                            <small v-if="v$.repeatPassword.$model !== v$.password.$model"
                                   class="p-error p-d-block">
                                Le password non coincidono
                            </small>
                        </div>
                    </div>

                    <h2>Informazioni personali</h2>

                    <div class="p-field p-grid">
                        <label for="name" class="p-col-12 p-md-3 p-xl-2">Nome</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <InputText id="name" type="text"
                                       autocomplete="given-name"
                                       :class="{ 'p-invalid': v$.firstName.$invalid &&
                                       isSubmitted }" v-model="v$.firstName.$model" />
                            <small v-if="v$.firstName.$invalid && isSubmitted"
                                   class="p-error p-d-block">
                                Il campo è obbligatorio
                            </small>
                        </div>

                    </div>

                    <div class="p-field p-grid">
                        <label for="surname" class="p-col-12 p-md-3 p-xl-2">Cognome</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <InputText
                                id="surname" type="text"
                                autocomplete="family-name" v-model="v$.lastName.$model"
                                :class="{ 'p-invalid': v$.lastName.$invalid && isSubmitted }" />
                            <small v-if="v$.lastName.$invalid && isSubmitted"
                                   class="p-error p-d-block">
                                Il campo è obbligatorio
                            </small>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <label for="region" class="p-col-12 p-md-3 p-xl-2">Regione</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <Dropdown
                                id="region" v-model="v$.region.$model" :filter="true"
                                :options="regions"  optionLabel="name" optionValue="value"
                                :class="{ 'p-invalid': v$.region.$invalid && isSubmitted }">
                                <template #option="slotProps">
                                    {{
                                        slotProps.option.name.length > 45
                                            ? slotProps.option.name.substring(0, 45) + "..."
                                            : slotProps.option.name
                                    }}
                                </template>
                            </Dropdown>
                            <small v-if="v$.region.$invalid && isSubmitted"
                                   class="p-error p-d-block">
                                Il campo è obbligatorio
                            </small>
                        </div>
                    </div>

                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <Checkbox id="student" v-model="v$.isStudent.$model" :binary="true" />
                            <label for="student">Sei uno studente?</label>
                        </div>
                    </div>
                    <div v-if="v$.isStudent.$model === true">
                        <div class="p-field p-grid">
                            <label for="university" class="p-col-12 p-md-3 p-xl-2">
                                Università
                            </label>
                            <div class="p-col-12 p-md-9 p-xl-10">
                                <Dropdown
                                    id="university" v-model="v$.university.$model"
                                    :options="universities" optionLabel="name"
                                    :filter="true" optionValue="name"
                                    :class="{ 'p-invalid': v$.university.$invalid && isSubmitted }">
                                    <template #option="slotProps">
                                        {{
                                            slotProps.option.name.length > 45
                                                ? slotProps.option.name.substring(0, 45) + "..."
                                                : slotProps.option.name
                                        }}
                                    </template>
                                </Dropdown>
                            </div>
                            <small
                                v-show="v$.university.$invalid && isSubmitted"
                                class="p-error p-col-12 p-md-9 p-xl-10 p-md-offset-3 p-xl-offset-2">
                                Il campo è obbligatorio
                            </small>
                        </div>

                        <div class="p-field p-grid">
                            <label for="badgeNumber" class="p-col-12 p-md-3 p-xl-2">
                                Matricola
                            </label>
                            <div class="p-col-12 p-md-9 p-xl-10">
                                <InputText
                                    id="badgeNumber" type="text"
                                    :class="{ 'p-invalid': v$.badgeNumber.$invalid && isSubmitted }"
                                    v-model="v$.badgeNumber.$model"/>
                            </div>
                            <small
                                v-show="v$.badgeNumber.$invalid && isSubmitted"
                                class="p-error p-col-12 p-md-9 p-xl-10 p-md-offset-3 p-xl-offset-2">
                                Il campo è obbligatorio
                            </small>
                        </div>
                    </div>
                    <Message v-if="successMessage !== ''" severity="success">
                        {{ successMessage }}
                    </Message>
                    <Message v-if="errorMessage !== ''" severity="error">
                        {{ errorMessage }}
                    </Message>
                </div>
            </form>
        </template>
        <template #footer>
            <Button label="Registrati" :loading="isLoading" class="p-button p-mt-3"
                    form="signup-form" type="submit" @click.prevent="submitSignup(!v$.$invalid &&
                    v$.repeatPassword.$model === v$.password.$model)"/>
        </template>
    </Card>
</template>

<script>
import {
    required,
    email,
    minLength,
    requiredIf,
} from "@vuelidate/validators";
import {
    ref,
    onMounted,
    onBeforeMount,
    computed,
    reactive,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Password from "primevue/password";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Message from "primevue/message";
import { useVuelidate } from "@vuelidate/core";
import Universities from "@/enums/universities";
import Regions from "@/enums/regions";

export default {
    name: "JSignUp",
    components: {
        Password,
        InputText,
        Divider,
        Dropdown,
        Button,
        Card,
        Checkbox,
        Message,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        onBeforeMount(() => {
            if (store.getters.isLoggedIn) {
                router.go(-1);
            }
        });

        const successMessage = ref("");
        const errorMessage = ref("");
        const isLoading = ref(false);
        const isSubmitted = ref(false);

        const state = reactive({
            email: "",
            password: "",
            repeatPassword: "",
            firstName: "",
            lastName: "",
            isStudent: false,
            badgeNumber: "",
            university: "",
            region: "",
        });

        const rules = {
            email: { required, email },
            password: { required, minLength: minLength(8) },
            repeatPassword: { required },
            firstName: { required },
            lastName: { required },
            region: { required },
            isStudent: {},
            university: { required: requiredIf(() => state.isStudent === true) },
            badgeNumber: { required: requiredIf(() => state.isStudent === true) },
        };
        const v$ = useVuelidate(rules, state);
        const universities = ref(Object.values(Universities));
        const regions = ref(Object.values(Regions));

        const clearErrors = () => {
            successMessage.value = "";
            errorMessage.value = "";
        };

        onMounted(async () => {
            clearErrors();
        });

        const isPasswordSecure = computed(() => store.getters.isPasswordSecure(state.password));

        const submitSignup = async (isInputValid) => {
            clearErrors();
            isSubmitted.value = true;
            if (isInputValid && isPasswordSecure.value) {
                isLoading.value = true;
                try {
                    const response = await store.dispatch("registerNewUser", state);
                    if (response.status === 201) {
                        successMessage.value = "Registrazione avvenuta con successo";
                        await router.push({ path: "/profile" });
                    }
                } catch (err) {
                    if (err.response.data.error.includes("Path `email` is invalid")) {
                        errorMessage.value = "La mail inserita non è valida";
                    } else if (err.response.data.error.includes("The email inserted is invalid")) {
                        errorMessage.value = "La mail inserita non è valida";
                    } else if (err.response.data.error.includes("The password entered is invalid")) {
                        errorMessage.value = "La password inserita non è sicura";
                    } else if (err.response.data.error.includes("University")) {
                        errorMessage.value = "L'università inserita non è valida";
                    } else {
                        errorMessage.value = "Si è verificato un errore durante la "
                            + "registrazione, riprova più tardi";
                    }
                } finally {
                    isLoading.value = false;
                    isSubmitted.value = false;
                }
            }
        };

        return {
            v$,
            universities,
            regions,
            isLoading,
            isSubmitted,
            isPasswordSecure,
            submitSignup,
            successMessage,
            errorMessage,
        };
    },
};
</script>

<style scoped>
    h1 {
        font-size: 2rem;
    }
</style>
