<template>
    <Card>
        <template #content>
            <div class="p-d-flex p-jc-center p-jc-md-start p-ai-center p-flex-wrap entry-title">
                <JEntryTitle :entryId="modelValue._id" :kanji="modelValue.kanji"
                             :readings="modelValue.readings"
                             :withLink="false" :withEdukanjiLink="false"/>
                <div class="p-d-flex p-jc-center p-ai-center p-flex-wrap kanji-readings-buttons">
                    <Button label="Scritture"
                            :icon="disabled || reviewing ? 'pi pi-eye' : 'pi pi-pencil'"
                            @click="kanjiEditorVisible = true"/>
                    <Button label="Letture"
                            :icon="disabled || reviewing ? 'pi pi-eye' : 'pi pi-pencil'"
                            @click="readingsEditorVisible = true"/>
                    <JKanjiListEditor :kanji="modelValue.kanji"
                                      @update:kanji="onPropertyChange($event, 'kanji')"
                                      v-model:visible="kanjiEditorVisible"
                                      @onDialogClose="onDialogClose" :tempId="tempId"
                                      @updateTempId="$emit('updateTempId')"
                                      :disabled="disabled" :reviewing="reviewing"/>
                    <JReadingsListEditor :kanji="modelValue.kanji" :readings="modelValue.readings"
                                         @update:readings="onPropertyChange($event, 'readings')"
                                         v-model:visible="readingsEditorVisible"
                                         @onDialogClose="onDialogClose" :tempId="tempId"
                                         @updateTempId="$emit('updateTempId')"
                                         :disabled="disabled" :reviewing="reviewing"/>
                </div>
            </div>
            <div class="p-grid no-margin p-mt-2"
                 v-if="(modelValue.kanji?.length ?? 0) > 1
                    || (modelValue.readings?.length ?? 0) > 1">
                <div class="p-col-12">
                    <JEntryOtherKanjiReadings :kanji="modelValue.kanji"
                                              :readings="modelValue.readings"/>
                </div>
            </div>
            <div class="p-mt-2">
                <div class="p-grid">
                    <div class="p-col-12">
                        <h3>Campi semantici</h3>
                        <JOrderableSenses v-model="translatedSenses" :emptyItem="emptySense"
                                          :kanji="modelValue.kanji" :readings="modelValue.readings"
                                          :tags="tags" keyField="_id" :tempId="tempId"
                                          :disabled="reviewing" :enableInsert="!reviewing"
                                          :partsOfSpeech="partsOfSpeech"
                                          @updateTempId="$emit('updateTempId')"/>
                    </div>
                    <div class="p-col-12" v-if="canSeeOriginalSenses && originalSenses.length > 0">
                        <h3>Campi semantici originali</h3>
                        <Message :closable="false" severity="warn" v-if="modelValue.jmdictId">
                            Questi campi semantici provengono da JMdict; possono essere solo
                            consultati ma non modificati.
                        </Message>
                        <ol class="original-senses">
                            <li v-for="originalSense of originalSenses"
                                :key="originalSense._id" class="item-card p-mb-2">
                                <div class="p-grid no-margin">
                                    <div class="p-col-12 counter">
                                        <JEntrySense :isBackoffice="true" :sense="originalSense"
                                                     :tags="tags" :kanji="modelValue.kanji"
                                                     :readings="modelValue.readings"/>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import { cloneDeep } from "lodash";
import {
    reactive,
    ref,
    computed,
} from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Card from "primevue/card";
import Message from "primevue/message";
import JEntryTitle from "@/components/JEntryTitle.vue";
import JEntryOtherKanjiReadings from "@/components/JEntryOtherKanjiReadings.vue";
import JOrderableSenses from "@/components/admin/entries/JOrderableSenses.vue";
import JEntrySense from "@/components/JEntrySense.vue";
import JKanjiListEditor from "@/components/admin/entries/JKanjiListEditor.vue";
import JReadingsListEditor from "@/components/admin/entries/JReadingsListEditor.vue";
import LanguageCode from "@/enums/iso639";
import PartOfSpeech from "@/enums/partOfSpeech";

export default {
    name: "JEntryEditor",
    components: {
        Button,
        Card,
        Message,
        JEntryTitle,
        JEntryOtherKanjiReadings,
        JKanjiListEditor,
        JReadingsListEditor,
        JEntrySense,
        JOrderableSenses,
    },
    props: {
        modelValue: {
            type: Object,
        },
        tempId: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        reviewing: {
            type: Boolean,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:modelValue", "updateTempId"],
    setup(props, { emit }) {
        const store = useStore();

        const isUserReviewer = computed(() => store.getters.hasRoleOrUpper("reviewer"));
        const canSeeOriginalSenses = computed(() => isUserReviewer.value
            || store.getters.englishPreference);

        const entry = reactive(props.modelValue);

        const v$ = useVuelidate();

        const kanjiEditorVisible = ref(false);
        const readingsEditorVisible = ref(false);

        const emptySense = computed(() => ({
            _id: props.tempId,
            lang: LanguageCode.ITA.value,
            appliesToKanji: [],
            appliesToReadings: [],
            crossReferences: [],
            antonyms: [],
            partsOfSpeech: [],
            tags: [],
            misc: [],
            sourceTerms: [],
            dialects: [],
            glosses: [],
            notes: [],
            examples: [],
        }));
        const originalSenses = computed(() => props.modelValue.senses
            .filter((sense) => sense.lang === LanguageCode.ENG.value));
        const translatedSenses = computed({
            get() {
                return props.modelValue.senses
                    .filter((sense) => sense.lang === LanguageCode.ITA.value);
            },
            set(newValue) {
                const newEntry = cloneDeep(props.modelValue);
                newEntry.senses = originalSenses.value.concat(newValue);
                emit("update:modelValue", newEntry);
            },
        });
        const partsOfSpeech = computed(() => {
            if ((originalSenses.value?.length ?? 0) > 0) {
                const originalPartsOfSpeech = originalSenses.value
                    .flatMap((sense) => sense.partsOfSpeech);
                return Object.values(PartOfSpeech)
                    .filter((pos) => originalPartsOfSpeech.includes(pos.value));
            }
            return Object.values(PartOfSpeech);
        });

        const onDialogClose = () => {
            const newEntry = cloneDeep(props.modelValue);
            newEntry.kanji = props.modelValue.kanji
                .filter((kanji) => !!kanji && !!kanji.term);
            newEntry.readings = props.modelValue.readings
                .filter((reading) => !!reading && !!reading.term);
            emit("update:modelValue", newEntry);
        };
        const onPropertyChange = (newValue, key) => {
            const clonedEntry = cloneDeep(props.modelValue);
            clonedEntry[key] = newValue;
            emit("update:modelValue", clonedEntry);
        };

        return {
            v$,
            entry,
            isUserReviewer,
            canSeeOriginalSenses,
            kanjiEditorVisible,
            readingsEditorVisible,
            emptySense,
            originalSenses,
            translatedSenses,
            partsOfSpeech,
            onDialogClose,
            onPropertyChange,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    .original-senses {
        counter-reset: originalSenseNumber;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .counter::before {
        counter-increment: originalSenseNumber;
        content: counter(originalSenseNumber) ". ";
    }

    .item-card {
        background: $panelContentBg;
        color: $panelContentTextColor;
        box-shadow: $cardShadow;
        border-radius: $borderRadius;
        padding: $cardBodyPadding;
    }

    .entry-title {
        row-gap: 0.5rem;
        column-gap: 1rem;

        .kanji-readings-buttons {
            column-gap: 0.25rem;
            row-gap: 0.25rem;
        }
    }

    .p-card {
        text-align: left;
        background-color: #FAFAFA;

        .p-tag {
            background-color: $highlightBg;
            color: $highlightTextColor;
        }
    }

    .extra-list {
        font-style: italic;
        margin-top: 0;
        font-size: 0.8rem;

        ul {
            display: inline;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline;
            }
        }
    }
</style>
