const lockModule = {
    actions: {
        async takeLock(context, entryId) {
            await context.rootGetters.getServerConnection.post(`/lock/${entryId}`);
        },
        async releaseLock(context, entryId) {
            await context.rootGetters.getServerConnection.delete(`/lock/${entryId}`);
        },
        async forceRelease(context, entryId) {
            await context.rootGetters.getServerConnection.delete(`/lock/${entryId}/force`);
        },
    },
};

export default lockModule;
