const GlossType = {
    LITERAL: {
        value: "lit",
        label: "Letterale",
        shortLabel: "lett.",
    },
    FIGURATIVE: {
        value: "fig",
        label: "Figurativo",
        shortLabel: "fig.",
    },
    EXPLANATION: {
        value: "expl",
        label: "Spiegazione",
        shortLabel: "spieg.",
    },
    TRADEMARK: {
        value: "tm",
        label: "Marchio registrato",
        shortLabel: "mar. reg.",
    },
};
Object.freeze(GlossType);
export default GlossType;
