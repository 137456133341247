import ToastEventBus from "primevue/toasteventbus";
import { fromNotification, fromSimpleText } from "@/utils/toastFactory";

const notificationsModule = {
    state: {
        notifications: [],
        hasLoaded: false,
        page: 0,
        toSkip: 0,
        unreadNotificationsCount: 0,
        isLoadingNotifications: false,
        areNotificationsOver: false,
        notificationsLoadingError: undefined,
    },
    mutations: {
        SET_IS_LOADING_NOTIFICATIONS(state, value) { state.isLoadingNotifications = value; },
        SET_HAS_LOADED(state, value) { state.hasLoaded = value; },
        NOTIFICATIONS_OVER(state) { state.areNotificationsOver = true; },
        NOTIFICATION_LOADING_ERROR(state) {
            state.notificationsLoadingError = {
                title: "Oh no!",
                text: "Si è verificato un errore nel caricare le notifiche.",
            };
        },
        ADD_NOTIFICATIONS(state, newNotifications) {
            state.notifications = state.notifications.concat(newNotifications);
        },
        HANDLE_INCOMING_SOCKET_NOTIFICATION(state, notification) {
            // Create a toast from the notification and add it to the bus used by PrimeVue to show
            // toasts
            ToastEventBus.emit("add", fromNotification(notification));
            state.unreadNotificationsCount += 1;
            // Meaning one is in the notification page
            if (state.hasLoaded) {
                state.notifications.unshift(notification);
                state.toSkip += 1;
                state.areNotificationsOver = false;
            }
        },
        INCREASE_PAGE_COUNT(state) {
            state.page += 1;
        },
        RESET_NOTIFICATIONS(state) {
            state.page = 0;
            state.hasLoaded = false;
            state.toSkip = 0;
            state.notifications = [];
            state.isLoadingNotifications = false;
            state.areNotificationsOver = false;
            state.notificationsLoadingError = undefined;
        },
        SET_UNREAD_NOTIFICATIONS_COUNT(state, value) {
            state.unreadNotificationsCount = value;
        },
        SET_NOTIFICATION_AS_READ(state, notificationId) {
            if (state.unreadNotificationsCount > 0) {
                state.unreadNotificationsCount -= 1;
            }
            const notification = state.notifications.find((n) => n._id === notificationId);
            if (notification !== undefined && notification !== null) {
                notification.isRead = true;
            }
        },
        MARK_ALL_AS_READ(state) {
            for (const notification of state.notifications) {
                notification.isRead = true;
            }
            state.unreadNotificationsCount = 0;
        },
        DELETE_GROUPED_NOTIFICATION(state, groupId) {
            if (!(state.notifications
                .find((notification) => notification.groupId === groupId)?.isRead)) {
                if (state.unreadNotificationsCount > 0) {
                    state.unreadNotificationsCount -= 1;
                }
            }
            state.notifications = state.notifications
                .filter((notification) => notification.groupId !== groupId);
        },
        DELETE_ALL_NOTIFICATIONS(state) {
            state.page = 0;
            state.toSkip = 0;
            state.unreadNotificationsCount = 0;
            state.notifications = [];
            state.isLoadingNotifications = false;
            state.areNotificationsOver = false;
            state.notificationsLoadingError = undefined;
        },
    },
    actions: {
        async loadNotifications(context) {
            context.commit("SET_IS_LOADING_NOTIFICATIONS", true);
            try {
                const response = await context.rootGetters.getServerConnection
                    .get("/notifications", { params: { page: context.state.page + 1, skip: context.state.toSkip } });
                context.commit("SET_HAS_LOADED", true);
                if (response.data.length === 0) {
                    context.commit("NOTIFICATIONS_OVER");
                } else {
                    context.commit("INCREASE_PAGE_COUNT");
                    context.commit("ADD_NOTIFICATIONS", response.data);
                }
            } catch (error) {
                context.commit("NOTIFICATION_LOADING_ERROR");
            }
            context.commit("SET_IS_LOADING_NOTIFICATIONS", false);
        },
        async markAsRead(context, notification) {
            if (!notification.isRead) {
                try {
                    await context.rootGetters.getServerConnection.put(`/notifications/${notification._id}`);
                    context.commit("SET_NOTIFICATION_AS_READ", notification._id);
                } catch (error) {
                    // Do nothing
                }
            }
        },
        async markAllAsRead(context) {
            try {
                await context.rootGetters.getServerConnection.put("/notifications");
                context.commit("MARK_ALL_AS_READ");
            } catch (error) {
                // Do nothing
            }
        },
        async takeGroupedNotification(context, notification) {
            await context.rootGetters.getServerConnection.delete(`/notifications/grouped/${notification._id}`);
        },
        deleteGroupedNotification(context, { groupId, excludedUserId }) {
            const user = context.rootGetters.loggedUser;
            if (user._id !== excludedUserId) {
                context.commit("DELETE_GROUPED_NOTIFICATION", groupId);
            }
        },
        async deleteAllNotifications(context) {
            try {
                await context.rootGetters.getServerConnection.delete("/notifications");
                context.commit("DELETE_ALL_NOTIFICATIONS");
            } catch (error) {
                ToastEventBus.emit(
                    "add",
                    fromSimpleText("error", "Oh no!",
                        "Si è verificato un errore nel cancellare le notifiche"),
                );
            }
        },
        async loadUnreadNotificationsCount(context) {
            try {
                const response = await context.rootGetters.getServerConnection.get("/notifications/unread/count");
                context.commit("SET_UNREAD_NOTIFICATIONS_COUNT",
                    parseInt(response.data.unreadNotificationsCount, 10));
            } catch (error) {
                context.commit("SET_UNREAD_NOTIFICATIONS_COUNT", 0);
            }
        },
    },
};

export default notificationsModule;
