<template>
    <Button v-if="button !== undefined"
            :label="button.label"
            @click="onButtonClick"/>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import { fromSimpleText } from "@/utils/toastFactory";
import getNotificationDisplayData from "@/utils/notificationsDisplayData";

export default {
    name: "JNotificationButton",
    components: { Button },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const toast = useToast();

        const { button } = getNotificationDisplayData(props.notification);
        const onButtonClick = async () => {
            await store.dispatch("markAsRead", props.notification);
            if (props.notification.groupId !== undefined) {
                try {
                    await store.dispatch("takeGroupedNotification", props.notification);
                } catch (error) {
                    toast.add(fromSimpleText(
                        "error",
                        "Oh no!",
                        "Si è verificato un errore, prova a ricaricare la pagina.",
                    ));
                    return;
                }
            }
            await router.push(button.route);
        };
        return { button, onButtonClick };
    },
};
</script>
