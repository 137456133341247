export default function boolObjectToString(obj) {
    const keys = Object.keys(obj);
    let result = "";
    keys.forEach((key) => {
        if (obj[key]) {
            result += `${key} `;
        }
    });
    return result.trim();
}
