<template>
    <div class="p-d-flex p-dir-col p-ai-center">
        <component :is="element" :class="{ serif: isSerif, 'p-my-0': true }">
            <template v-if="(title?.length ?? 0) === 0">{{ emptyTitlePlaceholder }}</template>
            <router-link
                v-else-if="withLink"
                :to="{ name: 'entry', params: { slug: completeTitle, entryId: entryId } }">
                <template v-for="(elem, titleIndex) of title" :key="titleIndex">
                    <template v-if="elem.ruby === elem.rt">
                        <span :class="{ serif: isSerif }">{{ elem.rt }}</span>
                    </template>
                    <template v-else>
                        <ruby>
                            {{ elem.ruby }}
                            <rp>(</rp><rt>{{ elem.rt }}</rt><rp>)</rp>
                        </ruby>
                    </template>
                </template>
            </router-link>
            <template v-else v-for="(elem, titleIndex) of title" :key="titleIndex">
                <template v-if="elem.ruby === elem.rt">{{ elem.rt }}</template>
                <template v-else>
                    <ruby v-if="withEdukanjiLink">
                        <a v-for="(elemChar, elemCharIndex) of elem.ruby"
                           :key="elemCharIndex"
                           :class="{ serif: isSerif }"
                           class="edukanji-link"
                           :href="`https://edukanji.unive.it/kanji/${elemChar}`">
                            {{ elemChar }}
                        </a>
                        <rp>(</rp><rt>{{ elem.rt }}</rt><rp>)</rp>
                    </ruby>
                    <ruby v-else>
                        {{ elem.ruby }}
                        <rp>(</rp><rt>{{ elem.rt }}</rt><rp>)</rp>
                    </ruby>
                </template>
            </template>
        </component>
        <small class="romaji">{{ primaryRomaji }}</small>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import pairFurigana from "@/utils/furigana";

export default {
    name: "JEntryTitle",
    props: {
        entryId: {
            type: String,
            required: true,
        },
        kanji: {
            type: Array,
            required: true,
        },
        readings: {
            type: Array,
            required: true,
        },
        withLink: {
            type: Boolean,
            default: true,
        },
        withEdukanjiLink: {
            type: Boolean,
            default: true,
        },
        emptyTitlePlaceholder: {
            type: String,
            default: "Nuovo termine",
        },
        element: {
            type: String,
            default: "h2",
        },
    },
    setup(props) {
        const store = useStore();

        const isSerif = ref(!!store.getters.loggedUser?.wantsSerif);

        const primaryKanji = computed(() => props.kanji?.[0] ?? null);
        const primaryReading = computed(() => (primaryKanji.value
            ? props.readings
                .find((reading) => reading.restrict === primaryKanji.value._id)
            ?? props.readings[0] ?? null
            : props.readings[0] ?? null));
        const primaryRomaji = computed(() => primaryReading.value?.romaji ?? null);
        const titleWithComplete = computed(() => {
            if (primaryKanji.value === null && primaryReading.value === null) {
                // We have nothing
                return null;
            }
            if (primaryKanji.value !== null && primaryReading.value === null) {
                // We only have a kanji
                return {
                    title: [{ ruby: primaryKanji.value.term, rt: primaryKanji.value.term }],
                    completeTitle: primaryKanji.value.term,
                };
            }
            if (primaryKanji.value === null && primaryReading.value !== null) {
                // We only have a reading
                return {
                    title: [{ ruby: primaryReading.value.term, rt: primaryReading.value.term }],
                    completeTitle: primaryReading.value.term,
                };
            }
            if (primaryKanji.value !== null && primaryReading.value !== null) {
                // We have a kanji and a reading, pair the furigana;
                // if we can't, return the whole kanji and reading
                let generatedTitle;
                try {
                    generatedTitle = pairFurigana(
                        primaryKanji.value.term,
                        primaryReading.value.term,
                    );
                } catch (err) {
                    generatedTitle = [
                        { ruby: primaryKanji.value.term, rt: primaryReading.value.term },
                    ];
                }
                return {
                    title: generatedTitle,
                    completeTitle: `${primaryKanji.value.term}【${primaryReading.value.term}】`,
                };
            }
            // Congrats if we get here, that means boolean logic no longer applies
            return null;
        });
        const title = computed(() => titleWithComplete.value?.title ?? null);
        const completeTitle = computed(() => titleWithComplete.value?.completeTitle ?? null);

        return {
            isSerif,
            primaryKanji,
            primaryReading,
            primaryRomaji,
            title,
            completeTitle,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    h2 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
        margin: 0;
    }

    a {
        color: inherit;
        transition: color 0.125s;
        &:hover {
            color: $primaryColor;
        }
    }

    .romaji {
        font-size: 0.8rem;
        font-style: italic;
    }
</style>
