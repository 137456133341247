<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-py-5">
                <Card class="p-p-3">
                    <template #title>
                    </template>
                    <template #content>
                        <div class='privacy-body'>
<div class='title'>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI<br>
ai sensi dell’articolo 13 del Regolamento (UE) 2016/679
</div>
<p>
L’Università Ca’ Foscari Venezia, nell’ambito delle proprie finalità istituzionali e in adempimento agli obblighi previsti
dall’art. 13 del Regolamento UE 2016/679 (“GDPR”), fornisce informazioni in merito al trattamento dei dati personali
conferiti dagli utenti attraverso la creazione di un account nella Cafoscari Jisho APP.
<b>La creazione dell’account è possibile solo nella versione WEB dell’APP, pertanto nella versione mobile della stessa
non vengono raccolti e trattati dati personali.</b>
</p>
<ol>
<li>TITOLARE DEL TRATTAMENTO</li>
Il Titolare del trattamento è l’Università Ca’ Foscari Venezia, con sede in Dorsoduro n. 3246, 30123 Venezia (VE),
nella persona della Magnifica Rettrice pro tempore.
<li>RESPONSABILE DELLA PROTEZIONE DEI DATI</li>
L’Università ha nominato il “Responsabile della Protezione dei Dati” (“RPD” o “DPO”), che può essere contattato
scrivendo all’indirizzo di posta elettronica dpo@unive.it o al seguente indirizzo: Università Ca’ Foscari Venezia,
Responsabile della Protezione dei Dati, Dorsoduro n. 3246, 30123 Venezia (VE).
<li>CATEGORIE DI DATI PERSONALI, FINALITA’ E BASE GIURIDICA DEL TRATTAMENTO</li>
Per la creazione dell’account, è richiesto il conferimento di nome, cognome, indirizzo email, regione di appartenenza
e, se studenti, indicazione dell’Università a cui si è iscritti e numero di matricola. Tali dati verranno trattati
dall’Università per la gestione degli account e di ricerca statistica.
La base giuridica di tale trattamento è rappresentata dall’art. 6.1.b) del GDPR (“servizio richiesto dall’interessato”) e
dall’art. 6.1.e) del GDPR (“esecuzione di un compito di interesse pubblico”).
Lei potrà opporsi al predetto trattamento in qualsiasi momento, scrivendo al Responsabile della Protezione dei Dati
Personali ai recapiti sopra indicati. L’Università si asterrà dal trattare ulteriormente i Suoi dati personali salvo
sussistano motivi cogenti che legittimino la prosecuzione del trattamento.
<li>MODALITA’ DI TRATTAMENTO</li>
Il trattamento dei dati personali verrà effettuato da dipendenti e collaboratori dell’Università che agiscono sulla
base di specifiche istruzioni fornite in ordine alle finalità e modalità del trattamento medesimo (nel rispetto di
quanto previsto dall’art. 29 del GDPR e dall’art. 2-quaterdecies del D.lgs. n. 196/2003), con l’utilizzo di procedure
anche informatizzate, adottando misure tecniche e organizzative adeguate a proteggerli da accessi non autorizzati o
illeciti, dalla distruzione, dalla perdita di integrità e riservatezza, anche accidentali.
<li>TEMPI DI CONSERVAZIONE</li>
I dati verranno conservati per l’intera durata di attivazione dell’account e per i successivi 10 anni, tempo necessario
per condurre ricerche statistiche sulle attività della APP.
<li>DESTINATARI E CATEGORIE DI DESTINATARI DEI DATI PERSONALI</li>
Per le finalità sopra riportate, oltre ai dipendenti e ai collaboratori dell’Ateneo specificamente autorizzati, potranno
trattare i dati personali anche soggetti che svolgono attività in outsourcing per conto dell’Ateneo nella loro qualità di
Responsabili del trattamento. La lista aggiornata dei Responsabili del trattamento è disponibile qui:
<a href='https://www.unive.it/pag/34666/'>https://www.unive.it/pag/34666/</a>.
<li>CONFERIMENTO DEI DATI</li>
Il conferimento dei dati personali è necessario per la creazione e gestione degli account della APP. La mancata
acquisizione dei dati da parte dell’Università comporta per l’utente l’impossibilità di creare il proprio account sulla
APP.

<li>DIRITTI DEGLI INTERESSATI E MODALITA’ DI ESERCIZIO</li>

In qualità di interessato, ha diritto di ottenere dall’Ateneo, nei casi previsti dal Regolamento, l’accesso ai dati
personali, la rettifica, l’integrazione, la cancellazione degli stessi o la limitazione del trattamento ovvero di opporsi al
trattamento medesimo (artt. 15 e ss. del Regolamento). La richiesta potrà essere presentata, senza alcuna formalità,
contattando direttamente il Responsabile della Protezione dei Dati all’indirizzo dpo@unive.it ovvero inviando una
comunicazione al seguente recapito: Università Ca’ Foscari Venezia, Responsabile della Protezione dei Dati,
Dorsoduro n. 3246, 30123 Venezia. In alternativa, è possibile contattare il Titolare del trattamento, inviando una PEC
a protocollo@pec.unive.it.
Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti avvenga in violazione di quanto
previsto dal Regolamento hanno, inoltre, il diritto di proporre reclamo all’Autorità Garante per la protezione dei dati
personali, come previsto dall’art. 77 del Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del
Regolamento).
<li>COOKIE</li>
Nella versione WEB della APP sono presenti cookie tecnici di sessione necessari alla memorizzazione delle attività
svolte dall’utente sulla APP stessa. I dati raccolti associati a questi cookie sono: indirizzo IP dell’utente, data e ora
della visita, attività svolta dall’utente [altro?]. La base giuridica per il trattamento dei predetti dati personali è
rappresentata dagli artt. 6.1.b) del Regolamento (“servizio prestato su richiesta dell’interessato”). È possibile
disattivare tali cookie in ogni momento attraverso il link “abilita/disabilita cookie” oppure attraverso le impostazioni
del proprio browser ma così facendo si potrebbe perdere la memorizzazione delle attività svolte.
</ol>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
    </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import Divider from "primevue/divider";
// import Skeleton from "primevue/skeleton";

export default {
    name: "Privacy",
    components: { Card, Divider },
    setup() {
        const store = useStore();


        onMounted(async () => {

        });
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.7rem;
    color:#656565;
    margin-bottom:1rem;
}
.support-element {
    margin-bottom:2rem;
}
.info-block {
    margin-top:0.5rem;
    border-top:1px solid #efefef;
    padding-top:0.5rem;
}
.info-name,.info-element {
    margin-bottom:0.3rem;
}
.title {
    text-align:center;
    font-weight:bold;
    font-size:larger;
    margin-bottom:32px;
}
ol li {
    font-weight:bold;
    margin-top:12px;
}
</style>
