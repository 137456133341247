<template>
    <j-error :error="{
        title: 'Oh no!',
        text: 'La pagina che hai cercato non esiste.',
    }"/>
</template>

<script>
import JError from "@/components/JError.vue";

export default {
    name: "Error404",
    components: { JError },
};
</script>
