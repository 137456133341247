<template>
<ul v-if="reports.length > 0">
    <li v-for="report in reports" :key="report._id" class="p-mb-3">
        <j-entry-report-card :report="report" @archivedReport="$emit('archivedReport', $event)"/>
    </li>
</ul>
<p v-else>Non è presente nessuna segnalazione per questo termine.</p>
</template>

<script>
import JEntryReportCard from "@/components/admin/entries/JEntryReportCard.vue";

export default {
    name: "JReportsList",
    components: { JEntryReportCard },
    emits: ["archivedReport"],
    props: {
        reports: {
            type: [Object],
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
</style>
