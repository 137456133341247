import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";
import userModule from "@/store/modules/user";
import searchModule from "@/store/modules/search";
import entryModule from "@/store/modules/entry";
import assignmentsModule from "@/store/modules/assignments";
import adminModule from "@/store/modules/admin";
import collectionsModule from "@/store/modules/collections/index";
import socketModule from "@/store/modules/socket";
import notificationsModule from "@/store/modules/notifications";
import lockModule from "@/store/modules/lock";
import preferencesModule from "@/store/modules/preferences";
import reportModule from "@/store/modules/report";
import collaboratorsModule from "@/store/modules/collaborators";
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
const serverUrl = process.env.VUE_APP_SERVER_URL;

export default createStore({
    getters: {
        getServerConnection: (state) => {
            const connection = axios.create({
                baseURL: serverUrl,
                withCredentials: true,
            });
            // This configuration on axios inserts dynamically into the requests header the
            // element X-CSRF-TOKEN with the proper value in order to prevent csrf attacks
            connection.interceptors.request.use((config) => {
                if (!(["get", "head", "option"].includes(config.method.toLowerCase()))) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers["X-CSRF-TOKEN"] = state.user.user.csrfToken;
                }
                return config;
            }, (error) => Promise.reject(error));
            // In case a response has status code 403 redirect to error page
            connection.interceptors.response.use((response) => response, (error) => {
                if (error.response.status === 403) {
                    router.replace({ name: "403" });
                }
                return Promise.reject(error);
            });
            return connection;
        },
        cookiePolicy: (state)=> {
            return Cookies.get("jisho_cookiepolicy")
        }
    },
    modules: {
        user: userModule,
        search: searchModule,
        entry: entryModule,
        admin: adminModule,
        collections: collectionsModule,
        socket: socketModule,
        notifications: notificationsModule,
        assignments: assignmentsModule,
        lock: lockModule,
        preferences: preferencesModule,
        report: reportModule,
        collaborators: collaboratorsModule,
    },

    mutations: {
        cookieAccept: (state) => {
            Cookies.set("jisho_cookiepolicy",1);
        }
    },

});
