const OrthographicInfo = {
    ATEJI: {
        label: "Lettura ateji (fonetica)",
        value: "ateji",
    },
    IRREGULAR_KANA: {
        label: "Parola con kana usati in maniera irregolare",
        value: "ik",
    },
    IRREGULAR_KANJI: {
        label: "Parola con kanji usati in maniera irregolare",
        value: "iK",
    },
    IRREGULAR_OKURIGANA: {
        label: "Uso irregolare dell'okurigana",
        value: "io",
    },
    OUTDATED_KANJI: {
        label: "Parola con kanji desueti",
        value: "oK",
    },
    RARELY_USED_KANJI: {
        label: "Forma in kanji usata raramente",
        value: "rK",
    },
};
Object.freeze(OrthographicInfo);
export default OrthographicInfo;
