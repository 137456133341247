<template>
    <Dialog header="Salva in..." :modal="true" :dismissableMask="true"
            @update:visible="$emit('update:visible', $event)"
            :visible="visible" @show="loadInitialState"
            @hide="updateCollections">
        <template v-if="isLoggedIn">
            <Message v-if="creationError" severity="error" :closable="false">
                {{ creationError }}
            </Message>
            <j-error v-if="loadingError" :error="loadingError"/>
            <div v-else class="p-multiselect-panel p-component">
                <div class="p-multiselect-items-wrapper">
                    <ul class="p-multiselect-items p-component">
                        <li v-for="collection in collections" :key="collection._id"
                            :class="{ 'p-highlight': isSelected(collection) }"
                            class="p-multiselect-item">
                            <Checkbox name="collection" :value="collection._id"
                                      v-model="selectedCollections"
                                      :disabled="isLoading"/>
                            {{ truncate(collection.name) }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="p-text-center">
                <h2>Per salvare un termine in una collezione<br/>effettua il login!</h2>
                <Button label="Accedi" class="p-button-rounded" @click="goToLogin"/>
            </div>
        </template>
        <template #footer>
            <j-collection-creation-button v-if="isLoggedIn" @creationError="setCreationError"
                @newCollection="handleNewCollection"/>
        </template>
    </Dialog>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { difference } from "lodash";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import JCollectionCreationButton from "@/components/collections/JCollectionCreationButton.vue";
import JError from "@/components/JError.vue";
import { fromSimpleText } from "@/utils/toastFactory";

export default {
    name: "JCollectionPicker",
    components: {
        Button,
        Checkbox,
        Dialog,
        Message,
        JCollectionCreationButton,
        JError,
    },
    props: {
        visible: {
            type: Boolean,
        },
        entry: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:visible", "removedFromCollection", "addedToCollection", "newCollection", "creationError"],
    setup(props, { emit }) {
        const updateIsVisible = (value) => emit("update:visible", value);

        const router = useRouter();
        const store = useStore();
        const toast = useToast();

        const isLoggedIn = computed(() => store.getters.isLoggedIn);
        const goToLogin = () => router.push({ name: "login" });

        const collections = ref([]);
        const selectedCollections = ref([]);
        const isSelected = (collection) => selectedCollections.value.indexOf(collection._id) >= 0;
        let initialSelectedCollections = [];
        const isLoading = ref(false);
        const loadingError = ref();
        const loadInitialState = async () => {
            try {
                isLoading.value = true;
                const collectionsWithEntry = await store.dispatch("getCollectionsWithEntry", props.entry._id);
                collections.value = await store.dispatch("getCollections");
                selectedCollections.value = collectionsWithEntry;
                initialSelectedCollections = [...collectionsWithEntry];
            } catch (error) {
                loadingError.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore nel caricare le collezioni! Prova a ricaricare la pagina.",
                };
            } finally {
                isLoading.value = false;
            }
        };

        const updateCollections = async () => {
            if (!isLoggedIn.value) {
                return;
            }
            try {
                updateIsVisible(false);
                const collectionsToRemoveFrom = difference(
                    initialSelectedCollections,
                    selectedCollections.value,
                );
                const collectionsToAddTo = difference(
                    selectedCollections.value,
                    initialSelectedCollections,
                );
                const removePromises = collectionsToRemoveFrom
                    .map((collectionId) => store.dispatch("removeEntryFromCollection", {
                        collectionId, entry: props.entry,
                    }));
                const addPromises = collectionsToAddTo
                    .map((collectionId) => store.dispatch("addEntryToCollection", {
                        collectionId, entry: props.entry,
                    }));
                await Promise.all([...removePromises, ...addPromises]);
                collectionsToRemoveFrom.forEach((collectionId) => emit("removedFromCollection", collectionId));
                collectionsToAddTo.forEach((collectionId) => emit("addedToCollection", collectionId));
            } catch (error) {
                toast.add(fromSimpleText(
                    "error",
                    "Errore",
                    "Si è verificato un errore nel modificare la collezione, riprova.",
                ));
            }
        };

        const creationError = ref();
        const setCreationError = () => {
            creationError.value = "Si è verificato un problema nel creare la collezione";
            emit("creationError");
        };
        const handleNewCollection = (collection) => {
            collections.value.push(collection);
            emit("newCollection", collection);
        };

        const truncate = (s) => `${s.substring(0, 100)}${s.length >= 100 ? "..." : ""}`;

        return {
            isLoggedIn,
            goToLogin,
            collections,
            selectedCollections,
            isSelected,
            loadInitialState,
            isLoading,
            updateCollections,
            setCreationError,
            handleNewCollection,
            creationError,
            loadingError,
            truncate,
        };
    },
};
</script>

<style scoped lang="scss">
    ::v-deep(.p-component) {
        max-width: 90vw;
        overflow-x: hidden;
    }

    .p-multiselect-panel {
        display: block;
        position: static;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 30vh;
        overflow-y: auto;
    }
</style>
