<template>
    <j-loading-bar :loading="isLoadingNotifications"/>
    <div class="p-grid no-margin">
        <div class="p-col-10 p-offset-1 p-py-5" ref="scrollComponent">
            <j-notifications/>
        </div>
    </div>
</template>

<script>
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
} from "vue";
import { useStore } from "vuex";
import JLoadingBar from "@/components/JLoadingBar.vue";
import JNotifications from "@/components/notification/JNotifications.vue";

export default {
    name: "Notifications",
    components: { JLoadingBar, JNotifications },
    setup() {
        const store = useStore();
        const notificationsModule = store.state.notifications;
        const isLoadingNotifications = computed(() => notificationsModule.isLoadingNotifications);

        const scrollComponent = ref();
        const onScrollWindow = async () => {
            const element = scrollComponent.value;
            if (element?.getBoundingClientRect().bottom < window.innerHeight
                && !notificationsModule.isLoadingNotifications
                && !notificationsModule.areNotificationsOver) {
                await store.dispatch("loadNotifications");
            }
        };
        onMounted(() => window.addEventListener("scroll", onScrollWindow));
        onUnmounted(() => window.removeEventListener("scroll", onScrollWindow));

        return { isLoadingNotifications, scrollComponent };
    },
};
</script>
