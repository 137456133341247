<template>
    <Card>
        <template #title>
            <slot name="title">
                <h2 class="p-m-0" v-if="title">{{ title }}</h2>
            </slot>
        </template>
        <template #content>
            <slot name="beforeContent"></slot>
            <slot v-if="!loading && !error" name="content"></slot>
            <Skeleton v-else-if="!error" :height="skeletonHeight"/>
            <JError v-else :error="error"/>
            <slot name="afterContent"></slot>
        </template>
        <template #footer>
            <div class="p-d-flex p-dir-rev">
                <Button label="Visualizza elenco" icon="pi pi-eye"
                        @click="$emit('onViewListClick', $event)"/>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import JError from "@/components/JError.vue";
import Button from "primevue/button";

export default {
    name: "JHomeCard",
    components: {
        Button,
        Card,
        Skeleton,
        JError,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
        },
        skeletonHeight: {
            type: String,
            default: "1rem",
        },
    },
    emits: ["onViewListClick"],
};
</script>
