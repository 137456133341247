import CRUDModule from "@/store/modules/collections/CRUD";
import entriesModule from "@/store/modules/collections/collectionEntries";

const collectionsModule = {
    modules: {
        CRUDModule,
        entriesModule,
    },
};

export default collectionsModule;
