const userModule = {
    actions: {
        async getUsers(context) {
            return context.rootGetters.getServerConnection.get("/admin/user");
        },
        async getUser(context, userId) {
            return context.rootGetters.getServerConnection.get(`/admin/user/${userId}`);
        },
        async queryUsers(context, query) {
            return context.rootGetters.getServerConnection.get("/admin/user/query", {
                params: { query },
            });
        },
        async updateUser(context, { userId, user }) {
            return context.rootGetters.getServerConnection.put(`/admin/user/${userId}`, user);
        },
        async getCompilers(context) {
            return context.rootGetters.getServerConnection.get("/admin/user/compilers");
        },
        async getHistory(context, userId) {
            return context.rootGetters.getServerConnection.get(`/admin/user/${userId}/history`);
        },
    },
};

export default userModule;
