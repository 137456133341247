const AllowedAnkiFields = {
    MAIN_ENTRY: {
        label: "mainEntry",
        value: "Termine",
    },
    KANJI: {
        label: "kanji",
        value: "Scritture alternative",
    },
    READING: {
        label: "reading",
        value: "Letture alternative",
    },
    SENSES: {
        label: "senses",
        value: "Campi semantici",
    },
};

Object.freeze(AllowedAnkiFields);
export default AllowedAnkiFields;
