<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-py-5">
                <h1 class="p-mb-6">Assignment</h1>
                <j-user-assignments-table :user="user"/>
            </div>
        </div>
    </main>
</template>

<script>
import JUserAssignmentsTable from "@/components/admin/user/JUserAssignmentsTable.vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
    name: "Assignments",
    components: { JUserAssignmentsTable },
    setup() {
        const store = useStore();

        const user = computed(() => store.getters.loggedUser);

        return {
            user,
        };
    },
};
</script>
