import { createApp } from "vue";

// PrimeVue imports
import PrimeVue from "primevue/config";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

// Local imports
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Assets imports
import "./assets/scss/theme/theme.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primeflex/src/_variables.scss";

// Router configuration
router.beforeEach((to, from) => {
    const loginRoute = {
        name: "login",
        query: {
            next: to.fullPath,
        },
        replace: true,
    };
    const unauthorizedRoute = { name: "403" };
    // Using the requireRole meta attribute implies `requireLogin: true`
    if (to.meta?.requireRole !== undefined) {
        // Route requires a specific role
        if (!store.getters.hasRoleOrUpper(to.meta?.requireRole)) {
            return store.getters.isLoggedIn ? unauthorizedRoute : loginRoute;
        }
        return true;
    }
    if (to.meta?.requireLogin) {
        // Route requires login
        return store.getters.isLoggedIn ? true : loginRoute;
    }
    if (to.meta?.requireAnonymous) {
        // Route requires not logged in
        return store.getters.isLoggedIn ? from : true;
    }
    return true;
});

router.afterEach((to) => {
    const fixedPart = "Cafoscari Jisho Web";
    document.title = to?.meta?.title ? `${to.meta.title} | ${fixedPart}` : fixedPart;
});

const vuePromise = () => {
    createApp(App)
        .use(PrimeVue, {
            ripple: true,
            locale: {
                startsWith: "Inizia con",
                contains: "Contiene",
                notContains: "Non contiene",
                endsWith: "Finisce con",
                equals: "Uguale a",
                notEquals: "Diverso da",
                noFilter: "Rimuovi filtri",
                lt: "Minore di",
                lte: "Minore o uguale a",
                gt: "Maggiore di",
                gte: "Maggiore o uguale a",
                dateIs: "La data è",
                dateIsNot: "La data non è",
                dateBefore: "La data è prima di",
                dateAfter: "La data è dopo",
                clear: "Cancella",
                apply: "Applica",
                matchAll: "Tutti fanno match",
                matchAny: "Alcuni fanno match",
                addRule: "Aggiungi regola",
                removeRule: "Rimuovi regola",
                accept: "Sì",
                reject: "No",
                choose: "Scegli",
                upload: "Carica",
                cancel: "Cancella",
                dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
                dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
                dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
                monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
                monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
                today: "Oggi",
                weekHeader: "Wk",
                firstDayOfWeek: 0,
                dateFormat: "dd/mm/yy",
                weak: "Debole",
                medium: "Media",
                strong: "Sicura",
                passwordPrompt: "Inserisci una password",
                emptyFilterMessage: "Nessun risultato trovato",
                emptyMessage: "Nessuna opzione disponibile",
            },
        })
        .use(ConfirmationService)
        .use(ToastService)
        .use(store)
        .use(router)
        .directive("badge", BadgeDirective)
        .directive("tooltip", Tooltip)
        .mount("#app");
};

store.dispatch("loadLoggedUser")
    .then(() => store.dispatch("loadEnglishPreference")
        .then(() => vuePromise()))
    .catch((err) => {
        console.error(err);
        vuePromise();
    });
