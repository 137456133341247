const Regions = [
    { name: "Abruzzo", value: "abr" },
    { name: "Basilicata", value: "bas" },
    { name: "Calabria", value: "cal" },
    { name: "Campania", value: "cam" },
    { name: "Emilia-Romagna", value: "emr" },
    { name: "Friuli-Venezia Giulia", value: "fvg" },
    { name: "Lazio", value: "laz" },
    { name: "Liguria", value: "lig" },
    { name: "Lombardia", value: "lom" },
    { name: "Marche", value: "mar" },
    { name: "Molise", value: "mol" },
    { name: "Piemonte", value: "pie" },
    { name: "Puglia", value: "pug" },
    { name: "Sardegna", value: "sar" },
    { name: "Sicilia", value: "sic" },
    { name: "Toscana", value: "tos" },
    { name: "Trentino-Alto Adige", value: "tad" },
    { name: "Umbria", value: "umb" },
    { name: "Valle D'Aosta", value: "vda" },
    { name: "Veneto", value: "ven" },
    { name: "Europa", value: "eur" },
    { name: "Resto del mondo", value: "row" },
];

export default Regions;
