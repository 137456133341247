<template>
    <div>
        <JCollectionPicker
            :entry="term" v-model:visible="isCollectionPickerVisible"
            @removedFromCollection="$emit('removedFromCollection', $event)"
            @addedToCollection="$emit('addedToCollection', $event)"
            @newCollection="$emit('newCollection', $event)"
            @creationError="$emit('creationError')"/>
        <JReportDialog :entry="term" v-model:visible="isReportDialogVisible"/>
        <Card>
            <template #content>
                <div class="p-grid no-margin">
                    <div class="p-col-12 p-px-0 p-pt-0 p-pb-4">
                        <div class="p-d-flex p-jc-center p-jc-md-start p-ai-center term-title">
                            <div class="p-d-flex p-flex-column p-mr-2">
                                <Button icon="pi pi-bookmark"
                                        class="p-button-icon-only p-button-rounded p-button-text"
                                        aria-label="Aggiungi ai preferiti"
                                        @click="showPicker"/>
                                <Button icon="pi pi-exclamation-triangle"
                                        class="p-button-icon-only p-button-rounded p-button-text"
                                        aria-label="Segnala un errore"
                                        @click="showReportDialog"/>
<!--                                <Button icon="pi pi-volume-up"-->
<!--                                   class="p-button-icon-only p-button-rounded p-button-text">-->
<!--                                </Button>-->
                            </div>
                            <JEntryTitle element="h1" :entryId="term._id"
                                         :withLink="!isTermPage" :withEdukanjiLink="isTermPage"
                                         :kanji="term.kanji" :readings="term.readings"/>
                        </div>
                    </div>
                    <div class="p-col-12 p-px-0 p-pt-0 p-pb-4" aria-label="Campi semantici">
                        <Message severity="warn" :closable="false" v-if="isPendingReview">
                            Questa voce ha delle modifiche non ancora approvate, quindi
                            alcune informazioni potrebbero essere incomplete o errate.
                        </Message>
                        <ol>
                            <li v-for="(sense, i) of term.senses" :key="i">
                                <JEntrySense :sense="sense" :kanji="term.kanji"
                                             :readings="term.readings"/>
                            </li>
                        </ol>
                    </div>
                    <div class="p-col-12 p-px-0 p-py-0">
                        <div class="expandable" :style="expandable">
                            <JEntryOtherKanjiReadings :kanji="term.kanji"
                                                      :readings="term.readings"/>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer v-if="canBeExpanded">
                <Button :icon="isCompact ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
                        class="p-button-icon-only p-button-rounded p-button-text"
                        @click="isCompact = !isCompact"></Button>
            </template>
        </Card>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import Button from "primevue/button";
import Card from "primevue/card";
import Message from "primevue/message";
import JEntryTitle from "@/components/JEntryTitle.vue";
import JEntryOtherKanjiReadings from "@/components/JEntryOtherKanjiReadings.vue";
import JEntrySense from "@/components/JEntrySense.vue";
import JCollectionPicker from "@/components/collections/JCollectionPicker.vue";
import JReportDialog from "@/components/JReportDialog.vue";

export default {
    name: "JTermCard",
    components: {
        Card,
        Button,
        Message,
        JEntryTitle,
        JEntryOtherKanjiReadings,
        JEntrySense,
        JCollectionPicker,
        JReportDialog,
    },
    props: {
        term: {
            type: Object,
            required: true,
        },
        isTermPage: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["removedFromCollection", "addedToCollection", "created", "error"],
    setup(props) {
        const term = ref(props.term);
        const isCompact = ref(true);
        const isCollectionPickerVisible = ref(false);
        const showPicker = () => {
            isCollectionPickerVisible.value = true;
        };

        const isReportDialogVisible = ref(false);
        const showReportDialog = () => { isReportDialogVisible.value = true; };

        const primaryKanji = computed(() => term.value.kanji?.[0] ?? null);
        const primaryReading = computed(() => (primaryKanji.value
            ? term.value.readings
                .find((reading) => reading.restrict === primaryKanji.value._id)
            ?? term.value.readings[0] ?? null
            : term.value.readings[0] ?? null));

        const otherKanjiList = computed(() => term.value.kanji
            ?.filter((kanji) => kanji._id !== primaryKanji.value._id) ?? []);
        const otherReadingsList = computed(() => term.value.readings
            ?.filter((reading) => reading._id !== primaryReading.value._id) ?? []);
        const canBeExpanded = computed(() => otherKanjiList.value.length > 0
            || otherReadingsList.value.length > 0);

        const isPendingReview = computed(() => term.value?.meta?.pendingReview ?? false);
        const expandable = computed(() => ({ display: isCompact.value ? "none" : "block" }));

        return {
            isCompact,
            isCollectionPickerVisible,
            showPicker,
            isReportDialogVisible,
            showReportDialog,
            canBeExpanded,
            isPendingReview,
            expandable,
        };
    },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/theme/_variables";
@import "~primeflex/src/_variables.scss";

.p-card {
    text-align: left;
    background-color: #FAFAFA;

    ::v-deep(.p-card-content) {
        padding-bottom: 0 !important;
    }

    ol {
        margin: 0;
        padding-left: 2rem;

        li {
            margin-top: 0.5rem;
        }
    }

    .expandable {
        @media(min-width: $md) {
            display: block !important;
        }
    }

    ::v-deep(.p-card-footer) {
        padding-top: 0;
        text-align: center;

        @media (min-width: $md) {
            display: none;
        }
    }
}

.p-message {
    margin: 0.5rem;
}
</style>
