<template>
    <nav class="desktop-only p-d-flex p-ai-center p-py-2 p-pl-3 p-pr-2"
         :class="{ 'p-jc-end': isHomepage, 'p-jc-between': !isHomepage, 'homepage': isHomepage }">
        <router-link to="/">
            <h1 v-if="!isHomepage">Cafoscari Jisho
                <span class="p-text-italic">Web</span>
            </h1>
        </router-link>
        <ul class="p-d-flex p-ai-center">
            <li>
                <router-link class="about" :to="{ name: 'privacy' }">Privacy</router-link>
            </li>
            <li>
                <router-link class="about" :to="{ name: 'support' }">Support</router-link>
            </li>
            <li>
                <router-link class="about" :to="{ name: 'about' }">About</router-link>
            </li>
            <li v-if="!isLoggedIn">
                <router-link :to="{ name: 'login' }">
                    <Button class="p-button-rounded">Accedi</Button>
                </router-link>
            </li>
            <li v-else>
                <Button class="p-button-rounded"
                        :label="user.firstName"
                        @click="showDesktopMenu"/>
                <Button class="p-button-rounded p-ml-2"
                        :label="unreadNotifications > 0 ? unreadNotifications : undefined"
                        icon="pi pi-bell" @click="showNotificationMenu">
                </Button>
            </li>
        </ul>
    </nav>
    <OverlayPanel ref="desktopMenu" class="p-p-3 overlay-menu">
        <ul>
            <li class="p-my-0"><h1 class="p-my-0">Ciao, {{ user.firstName }}!</h1></li>
            <li>
                <ul class="p-ml-3">
                    <li>
                        <router-link :to="{ name: 'collections.empty' }">
                            Collezioni
                        </router-link>
                    </li>
                    <li v-if="user.role !== 'user'">
                        <router-link :to="{ name: 'admin.home' }">
                            Back office
                        </router-link>
                    </li>
                    <li v-if="user.role === 'admin'">
                        <router-link :to="{ name: 'preferences' }">
                            Impostazioni
                        </router-link>
                    </li>
                    <li><router-link :to="{ name: 'profile' }">Profilo</router-link></li>
                </ul>
            </li>
            <li>
                <Button @click="logout" class="p-button-rounded">Logout</Button>
            </li>
        </ul>
    </OverlayPanel>
    <OverlayPanel ref="notificationMenu" class="overlay-menu" id="notificationMenu"
                  :baseZIndex="1000" :autoZIndex="false">
        <j-notifications :is-dialog="true"/>
    </OverlayPanel>
    <nav class="mobile-only p-d-flex p-ai-center p-py-2 p-pl-3 p-pr-2"
         :class="{ 'p-jc-end': isHomepage, 'p-jc-between': !isHomepage, 'homepage': isHomepage }">
        <router-link to="/">
            <h1 v-if="!isHomepage">
                Cafoscari Jisho
                <span class="p-text-italic">Web</span>
            </h1>
        </router-link>
        <Button class="p-button-rounded" icon="pi pi-bars" @click="isMenuVisible = true"/>
    </nav>
    <Sidebar class="overlay-menu mobile-only p-pl-5" v-model:visible="isMenuVisible"
             position="right">
        <ul>
            <template v-if="isLoggedIn">
                <li><h2>Ciao, {{ user.firstName }}!</h2></li>
                <li class="p-ml-3">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'notifications' }">
                                Notifiche <Badge v-if="unreadNotifications > 0"
                                             :value="unreadNotifications"></Badge>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'collections.empty' }">
                                Collezioni
                            </router-link>
                        </li>
                        <li v-if="user.role !== 'user'">
                            <router-link :to="{ name: 'admin.home' }">Back office</router-link>
                        </li>
                        <li v-if="user.role === 'admin'">
                            <router-link :to="{ name: 'preferences' }">
                                Impostazioni
                            </router-link>
                        </li>
                        <li><router-link :to="{ name: 'profile' }">Profilo</router-link></li>
                    </ul>
                </li>
            </template>
            <template v-else>
                <li><h2>Menu</h2></li>
            </template>
            <li class="p-ml-3">
                <router-link class="about" :to="{ name: 'about' }">About</router-link>
            </li>
            <li v-if="!isLoggedIn">
                <router-link :to="{ name: 'login' }">
                    <Button class="p-button-rounded">Accedi</Button>
                </router-link>
            </li>
            <li v-else>
                <Button @click="logout" class="p-button-rounded">Logout</Button>
            </li>
        </ul>
    </Sidebar>
</template>

<script>
import {
    computed, ref, watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Badge from "primevue/badge";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import Sidebar from "primevue/sidebar";
import JNotifications from "@/components/notification/JNotifications.vue";

export default {
    name: "JNavbar",
    components: {
        Badge,
        Button,
        OverlayPanel,
        Sidebar,
        JNotifications,
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        const isMenuVisible = ref(false);
        const currentRoute = computed(() => route.fullPath);

        const isHomepage = computed(() => currentRoute.value === "/");
        const desktopMenu = ref();
        const showDesktopMenu = (event) => desktopMenu.value.toggle(event);

        watch(currentRoute, () => {
            isMenuVisible.value = false;
            desktopMenu.value.hide();
        });

        const user = computed(() => store.getters.loggedUser);
        const isLoggedIn = computed(() => store.getters.isLoggedIn);

        const unreadNotifications = computed(
            () => store.state.notifications.unreadNotificationsCount.toString(),
        );
        const notificationMenu = ref();
        const showNotificationMenu = (event) => notificationMenu.value.toggle(event);

        const logout = async () => {
            try {
                await store.dispatch("logout");
                await router.push("/");
            } catch (err) {
                console.log(err);
            }
            desktopMenu.value.hide();
        };

        return {
            desktopMenu,
            isMenuVisible,
            currentRoute,
            isHomepage,
            showDesktopMenu,
            notificationMenu,
            showNotificationMenu,
            user,
            unreadNotifications,
            isLoggedIn,
            logout,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/theme/_variables";
@import "~primeflex/src/_variables.scss";

#notificationMenu {
    padding: 0;
}

.overlay-menu {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-top: 1rem;
        }
    }
}

.mobile-only {
    @media (min-width: $md) {
        display: none !important;
    }
}

.desktop-only {
    @media (max-width: $md) {
        display: none !important;
    }
}

nav {
    a {
        text-decoration: none;
    }

    h1, span {
        padding: 0;
        margin: 0;
        color: $fontOnPrimary;
        font-size: 1rem;
    }

    color: $fontOnPrimary;
    background-color: $primaryColor;

    .about {
        color: $fontOnPrimary;
    }

    &.homepage {
        background-color: transparent;
        color: $mainFontColor;

        .about {
            color: $primaryColor;
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            margin: 0 0 0 1rem;
            display: inline;
        }
    }
}
</style>
