const MiscellaneousEntryInfo = {
    ABBR: {
        label: "Abbreviazione",
        value: "abbr",
    },
    ARCH: {
        label: "Arcaismo",
        value: "arch",
    },
    CHAR: {
        label: "Personaggio",
        value: "char",
    },
    CHN: {
        label: "Linguaggio dei bambini",
        value: "chn",
    },
    COL: {
        label: "Forma colloquiale",
        value: "col",
    },
    COMPANY: {
        label: "Nome di azienda",
        value: "company",
    },
    CREAT: {
        label: "Creatura",
        value: "creat",
    },
    DATED: {
        label: "Termine datato",
        value: "dated",
    },
    DEI: {
        label: "Divinità",
        value: "dei",
    },
    DEROG: {
        label: "Derogatorio",
        value: "derog",
    },
    DOC: {
        label: "Documento",
        value: "doc",
    },
    EV: {
        label: "Evento",
        value: "ev",
    },
    FAM: {
        label: "Linguaggio familiare",
        value: "fam",
    },
    FEM: {
        label: "Linguaggio o termine femminile",
        value: "fem",
    },
    FEM_GN: {
        label: "Nome proprio femminile",
        value: "fem-gn",
    },
    FICT: {
        label: "Fiction",
        value: "fict",
    },
    FORM: {
        label: "Termine formale o letterario",
        value: "form",
    },
    GIVEN: {
        label: "Nome proprio neutro",
        value: "given",
    },
    GROUP: {
        label: "Gruppo",
        value: "group",
    },
    HIST: {
        label: "Termine storico",
        value: "hist",
    },
    HON: {
        label: "Linguaggio onorifico (sonkeigo)",
        value: "hon",
    },
    HUM: {
        label: "Linguaggio umile (kenjōgo)",
        value: "hum",
    },
    ID: {
        label: "Espressione idiomatica",
        value: "id",
    },
    JOC: {
        label: "Termine scherzoso, umoristico",
        value: "joc",
    },
    LEG: {
        label: "Leggenda",
        value: "leg",
    },
    M_SL: {
        label: "Slang dei manga",
        value: "m-sl",
    },
    MALE: {
        label: "Linguaggio o termine maschile",
        value: "male",
    },
    MALE_GN: {
        label: "Nome proprio maschile",
        value: "male-gn",
    },
    MYTH: {
        label: "Mitologia",
        value: "myth",
    },
    NET_SL: {
        label: "Slang di internet",
        value: "net-sl",
    },
    OBJ: {
        label: "Oggetto",
        value: "obj",
    },
    OBS: {
        label: "Termine obsoleto",
        value: "obs",
    },
    OBSC: {
        label: "Termine oscuro",
        value: "obsc",
    },
    ON_MIM: {
        label: "Termine onomatopeico",
        value: "on-mim",
    },
    ORGANIZATION: {
        label: "Nome di organizzazione",
        value: "organization",
    },
    OTH: {
        label: "Altro",
        value: "oth",
    },
    PERSON: {
        label: "Nome proprio di un personaggio pubblico",
        value: "person",
    },
    PLACE: {
        label: "Nome di luogo",
        value: "place",
    },
    POET: {
        label: "Termine poetico",
        value: "poet",
    },
    POL: {
        label: "Linguaggio cortese (teineigo)",
        value: "pol",
    },
    PRODUCT: {
        label: "Nome di prodotto",
        value: "product",
    },
    PROVERB: {
        label: "Proverbio",
        value: "proverb",
    },
    QUOTE: {
        label: "Citazione",
        value: "quote",
    },
    RARE: {
        label: "Raro",
        value: "rare",
    },
    RELIG: {
        label: "Religione",
        value: "relig",
    },
    SENS: {
        label: "Argomento sensibile",
        value: "sens",
    },
    SERV: {
        label: "Servizio",
        value: "serv",
    },
    SL: {
        label: "Slang",
        value: "sl",
    },
    STATION: {
        label: "Stazione ferroviaria",
        value: "station",
    },
    SURNAME: {
        label: "Cognome",
        value: "surname",
    },
    UK: {
        label: "Parola spesso scritta usando solo i kana",
        value: "uk",
    },
    UNCLASS: {
        label: "Sostantivo non classificato",
        value: "unclass",
    },
    VULG: {
        label: "Parola o espressione volgare",
        value: "vulg",
    },
    WORK: {
        label: "Nome di opera d'arte, opera letteraria, musica ecc.",
        value: "work",
    },
    X: {
        label: "Termine volgare o non adatto a minori",
        value: "x",
    },
    YOJI: {
        label: "Yojijukugo",
        value: "yoji",
    },
};
Object.freeze(MiscellaneousEntryInfo);
export default MiscellaneousEntryInfo;
