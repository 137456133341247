<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`kanji-term-${itemKey}`"
                              label="Scrittura*">
                Scrittura in kanji del termine. Può contenere anche kana,
                ma almeno un carattere non può esserlo.
            </JLabelHelpButton>
            <InputText :id="`kanji-term-${itemKey}`"
                       type="text" :disabled="disabled"
                       :aria-describedby="boolObjectToString({
                           [`kanji-term-${itemKey}-help`]: true,
                           [`kanji-term-${itemKey}-err`]: v$.term.$error,
                       })"
                       :class="{ 'p-invalid': v$.term.$error }"
                       v-model="v$.term.$model"/>
            <small class="p-error" :id="`kanji-term-${itemKey}-err`" v-if="v$.term.$error">
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'required')">
                    Il campo è obbligatorio.
                </template>
                <template v-if="v$.term.$errors.length > 1">
                    <br>
                </template>
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'nonKana')
                                && v$.term.$model.length > 0">
                    Il campo contiene solo kana. Inseriscilo come lettura.
                </template>
            </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :id="`kanji-orthoInfo-${itemKey}`"
                              label="Informazioni ortografiche">
                Inserisci qua eventuali informazioni ortografiche da segnalare
                sul kanji.
            </JLabelHelpButton>
            <MultiSelect
                v-model="item.orthographicInfo" :filter="true"
                :options="orthographicInfo" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled" :showToggleAll="!disabled"
                placeholder="Nessuna informazione ortografica"
                :aria-labelledby="`kanji-orthoInfo-${itemKey}`"
                :aria-describedby="`kanji-orthoInfo-${itemKey}-help`"/>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :id="`kanji-priorities-${itemKey}`"
                              label="Priorità">
                Inserisci qua la priorità della scrittura.<br>
                La lista <em>wordfreq</em> è composta da 24000 parole prese dal
                Mainichi Shimbun, ordinate per frequenza decrescente. Ciascuno dei
                48 gruppi disponibili è composto da 500 parole.
            </JLabelHelpButton>
            <MultiSelect
                v-model="item.priorities" :filter="true"
                :options="priority" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled" :showToggleAll="!disabled"
                placeholder="Nessuna priorità"
                :aria-labelledby="`kanji-priorities-${itemKey}`"
                :aria-describedby="`kanji-priorities-${itemKey}-help`"/>
        </div>
    </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import OrthographicInfo from "@/enums/orthographicInfo";
import Priority from "@/enums/priority";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JKanjiEditor",
    components: {
        InputText,
        MultiSelect,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const nonKana = (value) => value
            ?.match(/[^\u3040-\u309f\u30a0-\u30ff]+/gmu) !== null ?? false;
        const rules = {
            term: { required, nonKana },
        };

        const v$ = useVuelidate(rules, item);

        const orthographicInfo = ref(Object.values(OrthographicInfo));
        const priority = ref(Object.values(Priority));

        return {
            v$,
            item,
            orthographicInfo,
            priority,
            boolObjectToString,
        };
    },
};
</script>
