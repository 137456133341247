<template>
    <template v-if="lockError">
        <j-error :error="unlockingError ? unlockingError : lockError"/>
        <template v-if="isUserAdmin && !unlockingError">
            <div class="p-text-center">
                <Button label="Entra comunque" icon="pi pi-key" @click="confirmUnlock"/>
            </div>
        </template>
    </template>
    <template v-else-if="!loading && error === null">
        <Dialog header="Scegli un utente" v-model:visible="showAssignToUserDialog" :modal="true">
            <Dropdown :options="compilers" optionLabel="fullName" optionValue="_id"
                      style="width: 50vw" placeholder="Seleziona un utente"
                      :filter="true" v-model="selectedCompiler"/>
            <template #footer>
                <Button label="Cancella" icon="pi pi-times" @click="closeAssignToUserDialog"
                        class="p-button-text"/>
                <Button label="Invia" icon="pi pi-check" @click="sendAssignToUserDialog" autofocus
                        :disabled="selectedCompiler === null" :loading="isAssignmentLoading"/>
            </template>
        </Dialog>
        <Message severity="info" :closable="false" v-if="isUserReviewer && isPendingReview">
            Questo termine ha una proposta di aggiornamento in sospeso.
            Consulta la storia per avere informazioni sui cambiamenti e, una volta rivisto il
            termine, decidi se approvare o rifiutare la proposta.
        </Message>
        <Message severity="warn" :closable="false" v-if="reportsCount > 0">
            Questo termine ha {{ reportsCount }}
             {{ reportsCount > 1 ? "errori segnalati" : "errore segnalato" }}!
        </Message>
        <Message severity="error" :closable="false" v-if="v$.$errors.length > 0">
            Hai {{ errorsCount }} da risolvere.
            <template v-if="v$.$errors.some((e) => e.$property === 'readings')">
                Almeno una lettura deve essere presente.
            </template>
            <template v-if="v$.$errors.some((e) => e.$property === 'senses')">
                Almeno un campo semantico deve essere presente.
            </template>
            <template v-if="v$.$errors.some((e) => e.$property === 'partsOfSpeech')">
                Le parti del discorso sono obbligatorie.
            </template>
        </Message>
        <Message v-if="message" :closable="message?.closable ?? false" :severity="message.severity">
            {{ message.message }}
        </Message>
        <h1 class="p-d-flex p-ai-center">
            {{ entryTitle }}
            <Tag v-if="!entry.jmdictId" value="originale" class="p-ml-2"/>
            <Tag v-if="entry.fromJmnedict" value="da JMnedict" class="p-ml-2"/>
            <Tag v-if="isSoftDeleted" value="cancellato" class="p-ml-2"/>
        </h1>
        <TabView @tabClick="refreshEntry">
            <TabPanel>
                <template #header>
                    <span>Dettagli</span>
                </template>
                <h2>Informazioni base</h2>
                <div class="p-fluid p-formgrid p-grid entry-detail">
                    <div class="p-field p-col-12 p-md-6">
                        <JLabelHelpButton forId="id" label="ID interno">
                            ID interno del termine. Questo valore non è modificabile, essendo
                            generato automaticamente.
                        </JLabelHelpButton>
                        <InputText id="id" type="text" :value="entry._id" disabled
                                   placeholder="Valore generato automaticamente"
                                   aria-describedby="id-help"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="entry.jmdictId">
                        <JLabelHelpButton forId="jmdictId" label="ID JMdict">
                            ID del termine all'interno di JMdict. Questo valore non è modificabile.
                        </JLabelHelpButton>
                        <InputText id="jmdictId" type="number" :value="entry.jmdictId" disabled
                                   class="no-spin" aria-describedby="jmdictId-help"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="entry.jmdictId">
                        <JLabelHelpButton forId="assignmentPriority"
                                          label="Priorità di assegnamento">
                            Questo valore indica la priorità con la quale il termine sarà assegnato
                            ai compilatori. Il valore minimo è 0 e il valore massimo è 100; termini
                            con un valore maggiore verranno assegnati prima di quelli con un valore
                            minore
                        </JLabelHelpButton>
                        <InputNumber id="assignmentPriority" v-model="v$.assignmentPriority.$model"
                                 :disabled="!isUserAdmin" :min="0" :max="100" showButtons
                                 :class="{ 'p-invalid': v$.assignmentPriority.$error }"
                                 :aria-describedby="boolObjectToString({
                                   [`assignmentPriority-help`]: true,
                                   [`assignmentPriority-err`]: v$.assignmentPriority.$error,
                                 })" :useGrouping="false" />
                        <small class="p-error" id="assignmentPriority-err"
                               v-if="entry.assignmentPriority === null">
                            Il campo è obbligatorio.
                        </small>
                    </div>
                </div>
                <Divider/>
                <JEntryEditor :modelValue="entry" @update:modelValue="onEntryUpdate"
                              :tempId="nextTempId" :disabled="isDisabled"
                              :reviewing="isReviewing || isSoftDeleted"
                              :tags="tags" @updateTempId="onUpdateTempId"/>
                <Divider/>
                <div class="p-d-flex p-ai-center p-dir-rev"
                     v-if="isPendingReview && isUserReviewer">
                    <Button label="Approva" class="p-ml-2 p-button-success"
                            icon="pi pi-check" :loading="approvalLoading" @click="onApproveEdit"/>
                    <Button label="Rifiuta" class="p-button-danger"
                            icon="pi pi-times" :loading="denialLoading" @click="onDenyEdit"/>
                    <OverlayPanel ref="denyPanel" :dismissable="true"
                                  :breakpoints="{
                                      '992px': '50vw',
                                      '768px': '60vw',
                                      '576px': '80vw',
                                  }"
                                  :style="{ width: '25rem' }">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                <JLabelHelpButton forId="denyReason" label="Motivo del rifiuto">
                                    Inserisci qua il motivo del rifiuto della proposta di
                                    aggiornamento del termine.
                                    Esprimi dettagliatamente i problemi riscontrati.
                                </JLabelHelpButton>
                                <Textarea id="denyReason" v-model="denyReason" rows="4"/>
                            </div>
                        </div>
                        <div class="p-d-flex p-dir-rev">
                            <Button label="Conferma rifiuto" class="p-button-danger"
                                    :disabled="(denyReason?.trim().length ?? 0) === 0"
                                    :loading="submissionLoading" @click="onDenyConfirmEdit"/>
                        </div>
                    </OverlayPanel>
                </div>
                <div class="p-d-flex p-flex-wrap p-ai-center p-dir-rev" v-else>
                    <Button label="Salva termine" icon="pi pi-save"
                            class="p-button-success p-ml-2 p-mt-1"
                            :loading="submissionLoading" @click="onSave"/>
                    <template v-if="isUserAdmin">
                        <Button label="Elimina termine" icon="pi pi-trash"
                                class="p-button-danger p-ml-2 p-mt-1"
                                @click="onDelete" v-if="entryId && !isSoftDeleted"/>
                        <Button label="Ripristina termine" icon="pi pi-undo" class="p-ml-2 p-mt-1"
                                @click="onUndelete" v-if="entryId && isSoftDeleted"/>
                        <Button label="Assegna a" icon="pi pi-user-plus"
                                class="p-button-secondary p-ml-2 p-mt-1"
                                @click="onAssign" v-if="entryId && !isSoftDeleted && isUserAdmin"
                                :disabled="isTranslated"/>
                    </template>
                    <p class="errors-count" v-if="v$.$errors.length > 0">{{ errorsCount }}</p>
                </div>
            </TabPanel>
            <TabPanel v-if="!!entryId">
                <template #header>
                    <span>Storia</span>
                </template>
                <Timeline :value="history" :align="'alternate'" class="history-timeline">
                    <template #marker="slotProps">
                        <span :class="{
                            'history-status-marker': true,
                            'p-shadow-2': true,
                            [slotProps.item.reviewStatus]: true,
                        }"></span>
                    </template>
                    <template #content="slotProps">
                        <JEntryHistoryCard :historyItem="slotProps.item"
                                           :creation="slotProps.item.reviewStatus === 'created'"
                                           :deletion="slotProps.item.reviewStatus === 'deleted'"/>
                    </template>
                </Timeline>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <span>Segnalazioni</span>
                    <Badge v-if="reportsCount > 0" class="p-ml-2" :value="reportsCount"/>
                </template>
                <j-reports-list :reports="reports" @archivedReport="onArchivedReport"/>
            </TabPanel>
        </TabView>
    </template>
    <template v-else-if="error !== null">
        <h1 class="error-title">{{ error.title }}</h1>
        <p class="error-text">{{ error.text }}</p>
    </template>
</template>

<script>
import { cloneDeep, isEmpty } from "lodash";
import {
    reactive,
    ref,
    computed,
    onMounted,
    watch,
    onUnmounted,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import { useConfirm } from "primevue/useconfirm";
import Badge from "primevue/badge";
import Button from "primevue/button";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import Message from "primevue/message";
import TabPanel from "primevue/tabpanel";
import InputNumber from "primevue/inputnumber";
import TabView from "primevue/tabview";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Timeline from "primevue/timeline";
import JEntryEditor from "@/components/admin/entries/JEntryEditor.vue";
import JEntryHistoryCard from "@/components/admin/entries/JEntryHistoryCard.vue";
import JError from "@/components/JError.vue";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import JReportsList from "@/components/admin/entries/JReportsList.vue";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "EntryDetail",
    components: {
        Badge,
        Button,
        Divider,
        InputText,
        Message,
        InputNumber,
        OverlayPanel,
        TabPanel,
        TabView,
        Tag,
        Dropdown,
        Textarea,
        Dialog,
        Timeline,
        JEntryEditor,
        JEntryHistoryCard,
        JError,
        JLabelHelpButton,
        JReportsList,
    },
    props: {
        entryId: {
            type: String,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const confirm = useConfirm();

        const loading = ref(!!props.entryId);

        const tags = ref([]);
        const entry = reactive({
            _id: "",
            jmdictId: "",
            fromJmnedict: false,
            kanji: [],
            readings: [],
            senses: [],
            meta: {},
            assignmentPriority: 0,
        });
        const onEntryUpdate = (newEntry) => {
            entry._id = newEntry._id;
            entry.jmdictId = newEntry.jmdictId;
            entry.fromJmnedict = newEntry.fromJmnedict;
            entry.kanji = newEntry.kanji;
            entry.readings = newEntry.readings;
            entry.senses = newEntry.senses;
            entry.meta = newEntry.meta;
            entry.assignmentPriority = newEntry.assignmentPriority;
        };
        const entryRules = {
            senses: { required, minLength: minLength(1) },
            assignmentPriority: { required },
        };
        const v$ = useVuelidate(entryRules, entry);
        const message = ref();
        const error = ref(null);
        const submissionLoading = ref(false);
        const approvalLoading = ref(false);
        const denialLoading = ref(false);
        const entryTitle = computed(() => {
            if (entry.kanji.length > 0) {
                return (entry.kanji[0]?.term ?? "") !== "" ? entry.kanji[0]?.term : "Termine";
            }
            return (entry.readings[0]?.term ?? "") !== "" ? entry.readings[0]?.term : "Termine";
        });
        const isUserReviewer = computed(() => store.getters.hasRoleOrUpper("reviewer"));
        const isUserAdmin = computed(() => store.getters.hasRoleOrUpper("admin"));
        const isPendingReview = computed(() => entry.meta.pendingReview?._id !== undefined);
        const isTranslated = entry.senses.some((sense) => sense.lang === "ita");
        const errorsCount = computed(() => (v$.value.$errors.length === 1
            ? "1 errore"
            : `${v$.value.$errors.length} errori`));
        const isDisabled = computed(() => !!entry.jmdictId || !isUserReviewer.value);
        const isReviewing = computed(() => isUserReviewer.value && isPendingReview.value);
        const isSoftDeleted = computed(() => !!entry.meta.deletedAt);
        const history = computed(() => {
            if (!entry.meta || isEmpty(entry.meta)) {
                return [];
            }
            const historyCommits = entry.meta?.history ?? [];
            const deniedCommits = entry.meta?.denied ?? [];
            const allCommits = [
                ...historyCommits,
                ...deniedCommits,
            ];
            if (entry.meta.pendingReview) {
                allCommits.push({
                    ...entry.meta.pendingReview,
                    reviewStatus: "pending",
                });
            }
            allCommits.push({
                createdAt: entry.meta.createdAt,
                createdBy: entry.meta.createdBy,
                reviewStatus: "created",
            });
            if (isSoftDeleted.value) {
                allCommits.push({
                    createdAt: entry.meta.deletedAt,
                    createdBy: entry.meta.deletedBy,
                    reviewStatus: "deleted",
                });
            }
            return allCommits
                .map((commit) => {
                    const clonedCommit = cloneDeep(commit);
                    clonedCommit.createdAt = new Date(clonedCommit.createdAt);
                    clonedCommit.reviewedAt = clonedCommit.reviewedAt
                        ? new Date(clonedCommit.reviewedAt)
                        : undefined;
                    return clonedCommit;
                })
                .sort((commitA, commitB) => commitB.createdAt - commitA.createdAt);
        });

        const nextTempIdCounter = ref(1);
        const nextTempId = computed(() => `temp-${nextTempIdCounter.value}`);

        const denyPanel = ref();
        const denyReason = ref();

        const reports = ref([]);
        const reportsCount = computed(() => reports.value.filter((r) => !r.meta.deletedAt).length);
        const onArchivedReport = (reportId) => {
            const report = reports.value.find((r) => r._id === reportId);
            report.meta.deletedAt = new Date();
            reports.value = reports.value.filter((r) => r._id !== reportId);
            reports.value.push(report);
        };

        const refreshEntry = async () => {
            error.value = null;
            try {
                const tagsPromise = store.dispatch("admin/getTags");
                const resultPromise = store.dispatch("admin/getEntry", props.entryId);
                const reportsPromise = store.dispatch("getEntryReports", props.entryId);
                const [resolvedTags, resoledResult, resolvedReports] = await Promise.all([
                    tagsPromise,
                    resultPromise,
                    reportsPromise,
                ]);
                entry._id = resoledResult.data._id;
                entry.jmdictId = resoledResult.data.jmdictId;
                entry.fromJmnedict = resoledResult.data.fromJmnedict;
                entry.kanji = resoledResult.data.kanji;
                entry.readings = resoledResult.data.readings;
                entry.senses = resoledResult.data.senses;
                entry.meta = resoledResult.data.meta;
                entry.assignmentPriority = resoledResult.data.assignmentPriority;
                tags.value = resolvedTags.data.map((tag) => ({
                    _id: tag._id,
                    name: tag.name,
                    prettyName: tag.prettyName,
                    label: tag.prettyName ?? tag.name,
                }));
                reports.value = resolvedReports;
            } catch (err) {
                error.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore durante il recupero del termine!",
                };
            }
        };

        const onApproveEdit = async () => {
            try {
                approvalLoading.value = true;
                await store.dispatch("admin/approveEntryReview", props.entryId);
                await refreshEntry();
                message.value = {
                    message: "Termine revisionato.",
                    severity: "success",
                    closable: true,
                };
            } catch (err) {
                message.value = {
                    message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                    severity: "error",
                    closable: true,
                };
            } finally {
                approvalLoading.value = false;
                window.scrollTo(0, 0);
            }
        };
        const onDenyEdit = (event) => {
            denyPanel.value.toggle(event);
        };
        const onDenyConfirmEdit = async () => {
            denyPanel.value.hide();
            try {
                denialLoading.value = true;
                await store.dispatch("admin/denyEntryReview", {
                    entryId: props.entryId,
                    reason: denyReason.value,
                });
                await refreshEntry();
                message.value = {
                    message: "Termine revisionato.",
                    severity: "success",
                    closable: true,
                };
            } catch (err) {
                message.value = {
                    message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                    severity: "error",
                    closable: true,
                };
            } finally {
                denialLoading.value = false;
                window.scrollTo(0, 0);
            }
        };

        const onUpdateTempId = () => {
            // The components will emit an updateTempId event when they use the provided temporary
            // ID via the tempId prop, so we need to generate a new one
            nextTempIdCounter.value += 1;
        };

        const onSave = async () => {
            let newId = null;
            v$.value.$touch();
            if (v$.value.$errors.length === 0) {
                try {
                    submissionLoading.value = true;
                    if (props.entryId) {
                        await store.dispatch("admin/updateEntry", {
                            entryId: props.entryId,
                            entry,
                        });
                        await refreshEntry();
                        if (!isUserReviewer.value) {
                            message.value = {
                                message: "Proposta di aggiornamento del termine inviata.",
                                severity: "success",
                                closable: true,
                            };
                        } else {
                            message.value = {
                                message: "Termine aggiornato.",
                                severity: "success",
                                closable: true,
                            };
                        }
                    } else {
                        const response = await store.dispatch("admin/createEntry", entry);
                        newId = response.data._id;
                    }
                    if (newId) {
                        // Redirect to the newly created ID page
                        await router.replace({
                            name: "admin.entries.detail", params: { entryId: newId },
                        });
                    }
                } catch (err) {
                    if (err.response.status === 304) {
                        message.value = {
                            message: "Nessuna modifica effettuata.",
                            severity: "warn",
                            closable: true,
                        };
                    } else {
                        message.value = {
                            message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                            severity: "error",
                            closable: true,
                        };
                    }
                } finally {
                    submissionLoading.value = false;
                    window.scrollTo(0, 0);
                }
            }
        };
        const onDelete = async () => {
            confirm.require({
                message: "Stai per cancellare un termine. Vuoi continuare?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                async accept() {
                    try {
                        await store.dispatch("admin/deleteEntry", props.entryId);
                        await router.replace({ name: "admin.entries" });
                        await refreshEntry();
                        message.value = {
                            message: "Termine cancellato.",
                            severity: "success",
                            closable: true,
                        };
                    } catch (err) {
                        message.value = {
                            message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                            severity: "error",
                            closable: true,
                        };
                    }
                },
            });
        };
        const onUndelete = async () => {
            try {
                await store.dispatch("admin/undeleteEntry", props.entryId);
                await router.replace({ name: "admin.entries" });
                await refreshEntry();
                message.value = {
                    message: "Termine ripristinato.",
                    severity: "success",
                    closable: true,
                };
            } catch (err) {
                message.value = {
                    message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                    severity: "error",
                    closable: true,
                };
            }
        };

        const showAssignToUserDialog = ref(false);
        const selectedCompiler = ref(null);
        const isAssignmentLoading = ref(false);
        const compilers = ref({});
        const closeAssignToUserDialog = () => {
            showAssignToUserDialog.value = false;
        };
        const sendAssignToUserDialog = async () => {
            isAssignmentLoading.value = true;
            const logged = store.getters.loggedUser;
            try {
                await store.dispatch("submitAssignment", {
                    entryId: entry._id,
                    assignerId: logged._id,
                    assigneeId: selectedCompiler.value,
                });
                message.value = {
                    message: "Assignment inviato con successo.",
                    severity: "success",
                    closable: true,
                };
            } catch (err) {
                message.value = {
                    message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                    severity: "error",
                    closable: true,
                };
            } finally {
                isAssignmentLoading.value = false;
                showAssignToUserDialog.value = false;
                window.scrollTo(0, 0);
            }
        };

        const onAssign = async () => {
            try {
                const res = await store.dispatch("admin/getCompilers");
                compilers.value = res.data;
                showAssignToUserDialog.value = true;
            } catch (err) {
                window.scrollTo(0, 0);
                message.value = {
                    message: "Si è verificato un problema. Ricarica la pagina e riprova.",
                    severity: "error",
                    closable: true,
                };
            }
        };

        const lockError = ref();

        const isUnlocking = ref(false);
        const unlockingError = ref();
        const confirmUnlock = () => {
            confirm.require({
                message: "Se entri, l'utente che sta visualizzando la pagina sarà costretto a uscirne perdendo eventuali modifiche non salvate. Sicuro di voler continuare?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                async accept() {
                    try {
                        isUnlocking.value = true;
                        await store.dispatch("forceRelease", props.entryId);
                        router.go();
                    } catch (err) {
                        unlockingError.value = {
                            title: "Oh no!",
                            text: "Si è verificato un problema nell'entrare nella pagina, riprova più tardi.",
                        };
                    } finally {
                        isUnlocking.value = false;
                    }
                },
            });
        };

        watch(() => props.entryId, async () => refreshEntry());
        onMounted(async () => {
            if (props.entryId) {
                loading.value = true;
                try {
                    try {
                        await store.dispatch("takeLock", props.entryId);
                    } catch (err) {
                        if (err.response.status === 423) {
                            lockError.value = {
                                title: "Termine in modifica",
                                text: "Qualcuno sta già modificando questo termine, riprova più tardi.",
                            };
                        } else {
                            lockError.value = {
                                title: "Oh no!",
                                text: "Si è verificato un problema nel caricare il termine.",
                            };
                        }
                        loading.value = false;
                        return;
                    }
                    await refreshEntry();
                } finally {
                    loading.value = false;
                }
            } else {
                error.value = null;
                loading.value = true;
                try {
                    const resolvedTags = await store.dispatch("admin/getTags");
                    tags.value = resolvedTags.data.map((tag) => ({
                        _id: tag._id,
                        name: tag.name,
                        prettyName: tag.prettyName,
                        label: tag.prettyName ?? tag.name,
                    }));
                } catch (err) {
                    error.value = {
                        title: "Oh no!",
                        text: "Si è verificato un errore durante il recupero del termine!",
                    };
                } finally {
                    loading.value = false;
                }
            }
        });
        onUnmounted(async () => {
            try {
                await store.dispatch("releaseLock", props.entryId);
            } catch (err) {
                if (err.response.status !== 403) {
                    throw err;
                } else {
                    await router.replace({ name: "home" });
                }
            }
        });

        return {
            v$,
            loading,
            entry,
            reports,
            reportsCount,
            onArchivedReport,
            tags,
            entryTitle,
            error,
            errorsCount,
            message,
            submissionLoading,
            approvalLoading,
            denialLoading,
            isDisabled,
            isReviewing,
            isTranslated,
            isSoftDeleted,
            history,
            nextTempId,
            isUserReviewer,
            isUserAdmin,
            isPendingReview,
            denyPanel,
            denyReason,
            showAssignToUserDialog,
            isAssignmentLoading,
            compilers,
            selectedCompiler,
            lockError,
            confirmUnlock,
            unlockingError,
            boolObjectToString,
            refreshEntry,
            onEntryUpdate,
            onApproveEdit,
            onDenyEdit,
            onDenyConfirmEdit,
            onUpdateTempId,
            onSave,
            onDelete,
            onUndelete,
            onAssign,
            closeAssignToUserDialog,
            sendAssignToUserDialog,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables";

    .p-badge {
        min-width: 1.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
    }

    hr {
        border: 1px solid $shade300;
    }
    .error-title {
        text-align: center;
        font-size: 2rem;
    }
    .error-text {
        text-align: center;
    }
    .errors-count {
        margin: 0 1rem 0 0;
    }
    textarea {
        resize: none;
    }
    .history-status-marker {
        display: flex;
        width: 1rem;
        height: 1rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;

        &.approved, &.created {
            background-color: $successButtonBg;
        }
        &.pending {
            background-color: #ffffff;
            border: 2px solid $warningButtonBg;
        }
        &.denied {
            background-color: $dangerButtonBg;
        }
        &.deleted {
            background-color: $secondaryButtonBg;
        }
    }
    ::v-deep(.p-timeline-event-content),
    ::v-deep(.p-timeline-event-opposite) {
        line-height: 1;
    }

    @media screen and (max-width: $lg) {
        ::v-deep(.history-timeline) {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;

                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0 !important;
                padding: 0 !important;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
    }
</style>
