<template>
    <progress-bar :style="{ visibility: loading ? 'visible' : 'hidden' }"
        mode="indeterminate" class="loading-bar"></progress-bar>
</template>

<script>
import ProgressBar from "primevue/progressbar";

export default {
    name: "JLoadingBar",
    components: { ProgressBar },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
    .loading-bar {
        border-radius: 0;
        height: 0.25rem;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
    }
</style>
