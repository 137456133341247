const tagModule = {
    actions: {
        async queryTags(context, query) {
            return context.rootGetters.getServerConnection.get("/admin/tag/query", {
                params: { query },
            });
        },
        async getTags(context) {
            return context.rootGetters.getServerConnection.get("/admin/tag");
        },
        async getTag(context, tagId) {
            return context.rootGetters.getServerConnection.get(`/admin/tag/${tagId}`);
        },
        async updateTag(context, { tagId, tag }) {
            return context.rootGetters.getServerConnection.put(`/admin/tag/${tagId}`, tag);
        },
        async deleteTag(context, tagId) {
            return context.rootGetters.getServerConnection.delete(`/admin/tag/${tagId}`);
        },
        async createTag(context, tag) {
            return context.rootGetters.getServerConnection.post("/admin/tag", tag);
        },
    },
};

export default tagModule;
