const entriesModule = {
    actions: {
        async getCollectionsWithEntry(context, entryId) {
            const response = await context.rootGetters.getServerConnection.get(`/collections/withEntry/${entryId}`);
            return response.data;
        },
        async addEntryToCollection(context, { collectionId, entry }) {
            await context.rootGetters.getServerConnection.put(`/collections/${collectionId}`, { entryId: entry._id });
        },
        async removeEntryFromCollection(context, { collectionId, entry }) {
            await context.rootGetters.getServerConnection.delete(`/collections/${collectionId}`, { data: { entryId: entry._id } });
        },
    },
};

export default entriesModule;
