<template>
        <main>
            <div class="p-grid no-margin">
                <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
                    <Card class="p-p-3 p-text-center">
                        <template #title>
                            <h1 class="p-col-12">Conferma registrazione</h1>
                        </template>
                        <template #content>
                            <p>{{ confirmMessage }}</p>
                        </template>
                    </Card>
                </div>
            </div>
        </main>
</template>

<script>

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import Card from "primevue/card";

export default {
    name: "AccountConfirmation",
    components: {
        Card,
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const confirmMessage = ref("");
        const userToken = route.params.token;

        onMounted(async () => {
            try {
                await store.dispatch("confirmUser", userToken);
                confirmMessage.value = "Conferma dell'account avvenuta con successo!";
            } catch (err) {
                confirmMessage.value = "Il link utilizzato non è valido, ma non ti preoccupare, puoi generarne uno nuovo nel tuo profilo utente";
            }
        });

        return {
            confirmMessage,
        };
    },
};
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 2rem;
    }
</style>
