<template>
    <div :class="labelClasses" v-if="forId !== undefined">
        <label :for="forId">
            {{ label }}
        </label>
        <div>
            <JHelpButton :helpId="helpId">
                <slot></slot>
            </JHelpButton>
        </div>
    </div>
    <div :class="labelClasses" v-else>
        <span class="label" :id="id">
            {{ label }}
        </span>
        <div>
            <JHelpButton :helpId="helpId">
                <slot></slot>
            </JHelpButton>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import JHelpButton from "@/components/JHelpButton.vue";

export default {
    name: "JLabelHelpButton",
    components: {
        JHelpButton,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        isRight: {
            type: Boolean,
            default: false,
        },
        forId: {
            type: String,
        },
        id: {
            type: String,
        },
    },
    setup(props) {
        const helpId = computed(() => (props.id !== undefined
            ? `${props.id}-help`
            : `${props.forId}-help`));
        const labelClasses = computed(() => ({
            "p-d-flex": true,
            "p-ai-center": true,
            "form-label": !props.isRight,
            "form-label-right": props.isRight,
        }));

        return {
            helpId,
            labelClasses,
        };
    },
};
</script>

<style lang="scss" scoped>
    .p-field {
        span.label, label {
            font-weight: bold;
        }

        .p-field-checkbox label, .p-field-radiobutton label {
            font-weight: bold;
        }

        .form-label {
            margin-bottom: 0.5rem;
        }
        .form-label-right {
            margin-left: 0.5rem;
        }
    }
</style>
