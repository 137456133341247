<template>
    <div>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
                <h1>Cafoscari Jisho
                    <span class="p-text-italic">Web</span>
                </h1>
                <j-search-bar></j-search-bar>
            </div>
        </div>
    </main>
    <img class="bg-bottom bg-right" src="@/assets/img/homepageShape.svg" alt=""/>
    <img class="bg-bottom bg-girl bg-left" src="@/assets/img/homepagePersonBig.svg" alt=""/>
    <img class="bg-bottom bg-girl bg-center" src="@/assets/img/homepagePersonSmall.svg" alt=""/>

    </div>
</template>

<script>
import JSearchBar from "@/components/JSearchBar.vue";
export default {
    name: "Home",
    components: { JSearchBar },
};
</script>

<style scoped lang="scss">
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables.scss";

    main {
        text-align: center;

        h1 {
            text-shadow: 1px 1px 5px $backgroundColor;
        }
    }

    .bg-bottom {
        position: fixed;
        bottom: 2.4rem;

        &.bg-right {
            right: 0;
            width: 33rem;
            z-index: -11;

            @media (max-width: $lg) {
                display: none;
            }
        }

        &.bg-girl {
            width: 45rem;
            z-index: -10;

            &.bg-left {
                left: 0;
                @media (max-width: $sm) {
                    display: none;
                }
            }

            &.bg-center {
                width: 35rem;
                left: 50%;
                transform: translate(-50%);

                @media (min-width: $sm) {
                    display: none;
                }
            }
        }
    }
</style>
