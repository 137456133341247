const reportModule = {
    actions: {
        async reportEntry(context, { entryId, report }) {
            return context.rootGetters.getServerConnection.post(`/reports/${entryId}`, { report });
        },
        async getEntryReports(context, entryId) {
            const response = await context.rootGetters.getServerConnection.get(`/reports/${entryId}`);
            return response.data;
        },
        async archiveReport(context, reportId) {
            return context.rootGetters.getServerConnection.delete(`/reports/${reportId}`);
        },
    },
};

export default reportModule;
