const PartOfSpeech = {
    ADJ_F: {
        label: "Nome o verbo posto prima di un sostantivo",
        value: "adj-f",
    },
    ADJ_I: {
        label: "Aggettivo (keiyōshi)",
        value: "adj-i",
    },
    ADJ_IX: {
        label: "Aggettivo (keiyōshi) - categoria yoi/ii ",
        value: "adj-ix",
    },
    ADJ_KARI: {
        label: "Aggettivo in \"kari\" (arcaico)",
        value: "adj-kari",
    },
    ADJ_KU: {
        label: "Aggettivo in \"ku\" (arcaico)",
        value: "adj-ku",
    },
    ADJ_NA: {
        label: "Sostantivo aggettivale o pseudo-aggettivo (keiyodōshi)",
        value: "adj-na",
    },
    ADJ_NARI: {
        label: "Forma arcaica/formale di un aggettivo in na",
        value: "adj-nari",
    },
    ADJ_NO: {
        label: "Sostantivo che può essere accompagnato dalla particella del genitivo \"no\"",
        value: "adj-no",
    },
    ADJ_PN: {
        label: "Aggettivo prenominali (rentaishi)",
        value: "adj-pn",
    },
    ADJ_SHIKU: {
        label: "Aggettivo in \"shiku\" (arcaico)",
        value: "adj-shiku",
    },
    ADJ_T: {
        label: "Aggettivo in \"taru\"",
        value: "adj-t",
    },
    ADV: {
        label: "Avverbio (fukushi)",
        value: "adv",
    },
    ADV_TO: {
        label: "Avverbio che prende la particella \"to\"",
        value: "adv-to",
    },
    AUX: {
        label: "Ausiliare",
        value: "aux",
    },
    AUX_ADJ: {
        label: "Aggettivo ausiliare",
        value: "aux-adj",
    },
    AUX_V: {
        label: "Verbo ausiliare",
        value: "aux-v",
    },
    CONJ: {
        label: "Congiunzione",
        value: "conj",
    },
    COP: {
        label: "Copula",
        value: "cop",
    },
    CTR: {
        label: "Contatore",
        value: "ctr",
    },
    EXP: {
        label: "Espressioni (frasi, proposizioni, ecc.)",
        value: "exp",
    },
    INT: {
        label: "Interiezione (kandōshi)",
        value: "int",
    },
    N: {
        label: "Sostantivo (comune) (futsūmeishi)",
        value: "n",
    },
    N_ADV: {
        label: "Sostantivo avverbiale (fukushiteki meishi)",
        value: "n-adv",
    },
    N_PR: {
        label: "Nome proprio",
        value: "n-pr",
    },
    N_PREF: {
        label: "Sostantivo, usato come prefisso",
        value: "n-pref",
    },
    N_SUF: {
        label: "Sostantivo, usato come suffisso",
        value: "n-suf",
    },
    N_T: {
        label: "Sostantivo (temporale) (jisōmeishi)",
        value: "n-t",
    },
    NUM: {
        label: "Numerico",
        value: "num",
    },
    PN: {
        label: "Pronome",
        value: "pn",
    },
    PREF: {
        label: "Prefisso",
        value: "pref",
    },
    PRT: {
        label: "Particella",
        value: "prt",
    },
    SUF: {
        label: "Suffisso",
        value: "suf",
    },
    UNC: {
        label: "Non classificato",
        value: "unc",
    },
    V_UNSPEC: {
        label: "Verbo non specificato",
        value: "v-unspec",
    },
    V1: {
        label: "Verbo ichidan",
        value: "v1",
    },
    V1_S: {
        label: "Verbo ichidan - categoria speciale di kureru",
        value: "v1-s",
    },
    V2A_S: {
        label: "Verbo shimo nidan con terminazione in \"u\" (arcaico)",
        value: "v2a-s",
    },
    V2B_K: {
        label: "Verbo kami nidan con terminazione in \"bu\" (arcaico)",
        value: "v2b-k",
    },
    V2B_S: {
        label: "Verbo shimo nidan con terminazione in \"bu\" (arcaico)",
        value: "v2b-s",
    },
    V2D_K: {
        label: "Verbo kami nidan con terminazione in \"dzu\" (arcaico)",
        value: "v2d-k",
    },
    V2D_S: {
        label: "Verbo shimo nidan con terminazione in \"dzu\" (arcaico)",
        value: "v2d-s",
    },
    V2G_K: {
        label: "Verbo kami nidan con terminazione in \"gu\" (arcaico)",
        value: "v2g-k",
    },
    V2G_S: {
        label: "Verbo shimo nidan con terminazione in \"gu\" (arcaico)",
        value: "v2g-s",
    },
    V2H_K: {
        label: "Verbo kami nidan con terminazione in \"hu/fu\" (arcaico)",
        value: "v2h-k",
    },
    V2H_S: {
        label: "Verbo shimo nidan con terminazione in \"hu/fu\" (arcaico)",
        value: "v2h-s",
    },
    V2K_K: {
        label: "Verbo kami nidan con terminazione in \"ku\" (arcaico)",
        value: "v2k-k",
    },
    V2K_S: {
        label: "Verbo shimo nidan con terminazione in \"ku\" (arcaico)",
        value: "v2k-s",
    },
    V2M_K: {
        label: "Verbo kami nidan con terminazione in \"mu\" (arcaico)",
        value: "v2m-k",
    },
    V2M_S: {
        label: "Verbo shimo nidan con terminazione in \"mu\" (arcaico)",
        value: "v2m-s",
    },
    V2N_S: {
        label: "Verbo shimo nidan con terminazione in \"nu\" (arcaico)",
        value: "v2n-s",
    },
    V2R_K: {
        label: "Verbo kami nidan con terminazione in \"ru\" (arcaico)",
        value: "v2r-k",
    },
    V2R_S: {
        label: "Verbo shimo nidan con terminazione in \"ru\" (arcaico)",
        value: "v2r-s",
    },
    V2S_S: {
        label: "Verbo shimo nidan con terminazione in \"su\" (arcaico)",
        value: "v2s-s",
    },
    V2T_K: {
        label: "Verbo kami nidan con terminazione in \"tsu\" (arcaico)",
        value: "v2t-k",
    },
    V2T_S: {
        label: "Verbo shimo nidan con terminazione in \"tsu\" (arcaico)",
        value: "v2t-s",
    },
    V2W_S: {
        label: "Verbo shimo nidan con terminazione in \"u\" e coniugazione in \"we\" (arcaico)",
        value: "v2w-s",
    },
    V2Y_K: {
        label: "Verbo kami nidan con terminazione in \"yu\" (arcaico)",
        value: "v2y-k",
    },
    V2Y_S: {
        label: "Verbo shimo nidan con terminazione in \"yu\" (arcaico)",
        value: "v2y-s",
    },
    V2Z_S: {
        label: "Verbo shimo nidan con terminazione in \"zu\" (arcaico)",
        value: "v2z-s",
    },
    V4B: {
        label: "Verbo yodan con terminazione in \"bu\" (arcaico)",
        value: "v4b",
    },
    V4G: {
        label: "Verbo yodan con terminazione in \"gu\" (arcaico)",
        value: "v4g",
    },
    V4H: {
        label: "Verbo yodan con terminazione in \"hu/fu\" (arcaico)",
        value: "v4h",
    },
    V4K: {
        label: "Verbo yodan con terminazione in \"ku\" (arcaico)",
        value: "v4k",
    },
    V4M: {
        label: "Verbo yodan con terminazione in \"mu\" (arcaico)",
        value: "v4m",
    },
    V4N: {
        label: "Verbo yodan con terminazione in \"nu\" (arcaico)",
        value: "v4n",
    },
    V4R: {
        label: "Verbo yodan con terminazione in \"ru\" (arcaico)",
        value: "v4r",
    },
    V4S: {
        label: "Verbo yodan con terminazione in  \"su\" (arcaico)",
        value: "v4s",
    },
    V4T: {
        label: "Verbo yodan con terminazione in \"tsu\" (arcaico)",
        value: "v4t",
    },
    V5ARU: {
        label: "Verbo godan - categoria speciale -aru",
        value: "v5aru",
    },
    V5B: {
        label: "Verbo godan con terminazione in \"bu\"",
        value: "v5b",
    },
    V5G: {
        label: "Verbo godan con terminazione in \"gu\"",
        value: "v5g",
    },
    V5K: {
        label: "Verbo godan con terminazione in \"ku\"",
        value: "v5k",
    },
    V5K_S: {
        label: "Verbo godan - categoria speciale di Iku/Yuku",
        value: "v5k-s",
    },
    V5M: {
        label: "Verbo godan con terminazione in \"mu\"",
        value: "v5m",
    },
    V5N: {
        label: "Verbo godan con terminazione in \"nu\"",
        value: "v5n",
    },
    V5R: {
        label: "Verbo godan con terminazione in \"ru\"",
        value: "v5r",
    },
    V5R_I: {
        label: "Verbo godan con terminazione in \"ru\" (verbo irregolare)",
        value: "v5r-i",
    },
    V5S: {
        label: "Verbo godan con terminazione in \"su\"",
        value: "v5s",
    },
    V5T: {
        label: "Verbo godan con terminazione in \"tsu\"",
        value: "v5t",
    },
    V5U: {
        label: "Verbo godan con terminazione in \"u\"",
        value: "v5u",
    },
    V5U_S: {
        label: "Verbo godan con terminazione in \"u\" (categoria speciale)",
        value: "v5u-s",
    },
    V5URU: {
        label: "Verbo godan - categoria di Uru (forma arcaica di Eru)",
        value: "v5uru",
    },
    VI: {
        label: "Verbo intransitivo",
        value: "vi",
    },
    VK: {
        label: "Verbo kuru - categoria speciale",
        value: "vk",
    },
    VN: {
        label: "Verbo irregolare in nu",
        value: "vn",
    },
    VR: {
        label: "Verbo irregolare in ru, forma piana termina con -ri",
        value: "vr",
    },
    VS: {
        label: "Sostantivo o particella che prende il verbo ausiliare suru",
        value: "vs",
    },
    VS_C: {
        label: "Verbo su - precursore del moderno suru",
        value: "vs-c",
    },
    VS_I: {
        label: "Verbo suru - incluso",
        value: "vs-i",
    },
    VS_S: {
        label: "Verbo suru - categoria speciale",
        value: "vs-s",
    },
    VT: {
        label: "Verbo transitivo",
        value: "vt",
    },
    VZ: {
        label: "Verbo ichidan - verbo con zuru (forma alternativa dei verbi in -jiru)",
        value: "vz",
    },
};
Object.freeze(PartOfSpeech);
export default PartOfSpeech;
