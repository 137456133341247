const preferencesModule = {
    state() {
        return {
            showEnglish: null,
        };
    },
    getters: {
        englishPreference: (state) => state.showEnglish,
    },
    mutations: {
        SET_ENGLISH_PREFERENCE: (state, showEnglish) => {
            state.showEnglish = showEnglish;
        },
    },
    actions: {
        async setEnglishPreference(context, showEnglish) {
            const response = context.rootGetters.getServerConnection.post("/preferences/english", { showEnglish });
            if (response.status === 200) {
                context.commit("SET_ENGLISH_PREFERENCE", showEnglish);
            }
            return response;
        },
        async loadEnglishPreference(context) {
            const response = await context.rootGetters.getServerConnection.get("/preferences/english");
            if (response.status === 200) {
                context.commit("SET_ENGLISH_PREFERENCE", response.data.showEnglish);
            }
            return response;
        },
    },
};
export default preferencesModule;
