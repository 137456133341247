<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12">
            <JLabelHelpButton :id="`reading-restrict-${itemKey}`"
                              label="Scrittura di riferimento">
                Se la lettura vale solo per alcune delle scritture del termine,
                selezionale qua. Se non imposti nessun valore, questa lettura vale per tutte
                le scritture.
            </JLabelHelpButton>
            <MultiSelect
                v-model="item.restrict" :filter="true"
                :options="renderIdTermItem(kanji)" :showToggleAll="!disabled && !reviewing"
                optionLabel="term" optionValue="_id"
                :optionDisabled="() => disabled || reviewing"
                placeholder="Tutte le scritture"
                :aria-labelledby="`reading-restrict-${itemKey}`"
                :aria-describedby="`reading-restrict-${itemKey}-help`"/>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :forId="`reading-term-${itemKey}`"
                              label="Lettura in kana*">
                Scrittura in kana del termine. Se nei kanji sono presenti caratteri
                in un alfabeto kana, nella lettura deve essere usato lo stesso alfabeto.
            </JLabelHelpButton>
            <InputText :id="`reading-term-${itemKey}`"
                       type="text" :disabled="disabled || reviewing"
                       :aria-describedby="boolObjectToString({
                           [`reading-term-${itemKey}-help`]: true,
                           [`reading-term-${itemKey}-err`]: v$.term.$error,
                       })"
                       :class="{ 'p-invalid': v$.term.$error }"
                       v-model="v$.term.$model" @input="onTermUpdate"/>
            <small class="p-error" :id="`reading-term-${itemKey}-err`" v-if="v$.term.$error">
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'required')">
                    Il campo è obbligatorio.
                </template>
                <template v-if="v$.term.$errors.length > 1">
                    <br>
                </template>
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'isKana')
                                && v$.term.$model.length > 0">
                    Il campo deve contenere solo kana.
                </template>
            </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :forId="`reading-romaji-${itemKey}`"
                              label="Traslitterazione Hepburn*">
                Traslitterazione in Hepburn modificato della lettura in kana.
                La modifica della lettura comporta un ricalcolo della traslitterazione,
                che dovrà essere controllata ed eventualmente corretta.
            </JLabelHelpButton>
            <InputText :id="`reading-romaji-${itemKey}`"
                       type="text" :disabled="reviewing"
                       :aria-describedby="boolObjectToString({
                           [`reading-romaji-${itemKey}-help`]: true,
                           [`reading-romaji-${itemKey}-err`]: v$.romaji.$error,
                       })"
                       :class="{ 'p-invalid': v$.romaji.$error }"
                       v-model="v$.romaji.$model"/>
            <small class="p-error" :id="`reading-romaji-${itemKey}-err`" v-if="v$.romaji.$error">
                Il campo è obbligatorio.
            </small>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :id="`reading-readingInfo-${itemKey}`"
                              label="Informazioni sulla lettura">
                Inserisci qua eventuali informazioni aggiuntive da segnalare
                sulla lettura.
            </JLabelHelpButton>
            <MultiSelect
                v-model="item.readingInfo" :filter="true"
                :options="readingInfo" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled || reviewing"
                :showToggleAll="!disabled && !reviewing"
                placeholder="Nessuna informazione sulla lettura"
                :aria-labelledby="`reading-readingInfo-${itemKey}`"
                :aria-describedby="`reading-readingInfo-${itemKey}-help`"/>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <JLabelHelpButton :id="`reading-priorities-${itemKey}`"
                              label="Priorità">
                Inserisci qua la priorità della lettura.<br>
                La lista <em>wordfreq</em> è composta da 24000 parole prese dal
                Mainichi Shimbun, ordinate per frequenza decrescente. Ciascuno dei 48
                gruppi disponibili è composto da 500 parole.
            </JLabelHelpButton>
            <MultiSelect
                v-model="item.priorities" :filter="true"
                :options="priority" optionLabel="label" optionValue="value"
                :optionDisabled="() => disabled || reviewing"
                :showToggleAll="!disabled && !reviewing"
                placeholder="Nessuna priorità"
                :aria-labelledby="`reading-priorities-${itemKey}`"
                :aria-describedby="`reading-priorities-${itemKey}-help`"/>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <div class="p-field-checkbox">
                <Checkbox :id="`reading-noKanji-${itemKey}`" :binary="true"
                          :aria-describedby="`reading-noKanji-${itemKey}-help`"
                          :disabled="disabled || reviewing" v-model="item.noKanji"/>
                <JLabelHelpButton :forId="`reading-noKanji-${itemKey}`" :isRight="true"
                                  label="Slegato a una scrittura?">
                    Spunta questa casella se la lettura, sebbene associata a una scrittura,
                    non può esserne considerata una. In genere viene usato per
                    parole come nomi di luoghi stranieri, gairaigo che possono essere in
                    kanji o katakana, eccetera.
                </JLabelHelpButton>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toRomaji } from "wanakana";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import ReadingInfo from "@/enums/readingInfo";
import Priority from "@/enums/priority";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JReadingEditor",
    components: {
        Checkbox,
        InputText,
        MultiSelect,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        reviewing: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        kanji: {
            type: Object,
            required: true,
        },
        modelValue: {
            type: Object,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const isKana = (value) => value
            ?.match(/^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uffef]+$/) !== null ?? false;
        const rules = {
            term: { required, isKana },
            romaji: { required },
        };

        const v$ = useVuelidate(rules, item);

        const readingInfo = ref(Object.values(ReadingInfo));
        const priority = ref(Object.values(Priority));

        const renderIdTermItem = (kanji) => kanji.map((k) => ({
            _id: k._id,
            term: k.term,
        }));
        const onTermUpdate = (event) => {
            item.romaji = toRomaji(event.target.value)
                .replace(/aa/g, "ā")
                .replace(/ee/g, "ē")
                .replace(/oo/g, "ō")
                .replace(/ou/g, "ō")
                .replace(/uu/g, "ū");
        };

        return {
            v$,
            item,
            readingInfo,
            priority,
            boolObjectToString,
            renderIdTermItem,
            onTermUpdate,
        };
    },
};
</script>
