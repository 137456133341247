<template>
    <Dialog header="Termini correlati" :visible="visible"
            @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid" :dismissableMask="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <Message v-if="error !== null" severity="error">{{ error }}</Message>
        <div class="p-fluid p-formgrid p-grid entry-detail">
            <div class="p-field p-col-12">
                <p>
                    Un termine correlato è un termine con campi semantici simili o correlati a
                    questo. Per avere riferimenti più precisi, puoi specificare una scrittura,
                    una lettura e/o un campo semantico nello specifico.
                    Si può specificare un campo semantico solo se è stata specificata una scrittura
                    e/o lettura.
                    Se non si specifica niente, il termine sarà correlato nella sua interezza.
                </p>
            </div>
            <div class="p-field p-col-12">
                <JLabelHelpButton :id="`xref-${senseKey}-search`"
                                  label="Cerca un termine">
                    Cerca un termine correlato per aggiungerlo alla lista.
                </JLabelHelpButton>
                <AutoComplete v-model="selectedEntry" :suggestions="searchResults" forceSelection
                              :aria-labelledby="`xref-${senseKey}-search`"
                              :aria-describedby="`xref-${senseKey}-search-help`"
                              :disabled="disabled"
                              field="label" @complete="onSearchEntry"
                              @itemSelect="onAddCrossReference">
                    <template #item="slotProps">
                        {{ renderCrossReferenceChoice(slotProps.item) }}
                        <ol>
                            <li v-for="sense of renderCrossReferenceChoiceSenses(slotProps.item)"
                                :key="sense._id">
                                {{ renderCrossReferenceChoiceGlosses(sense) }}
                            </li>
                        </ol>
                    </template>
                </AutoComplete>
            </div>
        </div>
        <JOrderList v-model="crossReferences" :enableInsert="false" keyField="_id"
                    :disabled="disabled" @itemRemoved="onRemoveCrossReference">
            <template #header="slotProps">
                <template v-if="crossReferences.length > 0 && referencedEntries.length === 0">
                    <Skeleton width="25%" />
                </template>
                <template v-else>
                    <h4>
                        {{ renderCrossReferenceTitle(slotProps.item) }}
                    </h4>
                </template>
            </template>
            <template #item="slotProps">
                <template v-if="crossReferences.length > 0 && referencedEntries.length === 0">
                    <Skeleton height="5rem" />
                </template>
                <div class="p-fluid p-formgrid p-grid entry-detail" v-else>
                    <div class="p-field p-col-12 p-lg-6"
                         v-if="getKanjiChoicesForEntryId(slotProps.item.entry).length > 0">
                        <JLabelHelpButton :id="`xref-${senseKey}-kanji-${slotProps.key}`"
                                          label="Scrittura di riferimento">
                            Inserisci qui la scrittura di riferimento per questo termine.
                        </JLabelHelpButton>
                        <Dropdown
                            :aria-labelledby="`xref-${senseKey}-kanji-${slotProps.key}`"
                            :aria-describedby="`xref-${senseKey}-kanji-${slotProps.key}-help`"
                            :options="getKanjiChoicesForEntryId(slotProps.item.entry)"
                            optionLabel="label" optionValue="value" :showClear="!disabled"
                            :optionDisabled="() => disabled" v-model="slotProps.item.kanji"/>
                    </div>
                    <div class="p-field p-col-12 p-lg-6"
                         v-if="getReadingsChoicesForEntryId(slotProps.item.entry).length > 0">
                        <JLabelHelpButton :id="`xref-${senseKey}-readings-${slotProps.key}`"
                                          label="Lettura di riferimento">
                            Inserisci qui la lettura di riferimento per questo termine.
                        </JLabelHelpButton>
                        <Dropdown
                            :aria-labelledby="`xref-${senseKey}-readings-${slotProps.key}`"
                            :aria-describedby="`xref-${senseKey}-readings-${slotProps.key}-help`"
                            :options="getReadingsChoicesForEntryId(slotProps.item.entry)"
                            optionLabel="label" optionValue="value" :showClear="!disabled"
                            :optionDisabled="() => disabled" v-model="slotProps.item.reading"/>
                    </div>
                    <div class="p-field p-col-12"
                         v-if="(slotProps.item.kanji !== null || slotProps.item.reading !== null)
                               && getSensesChoicesForEntryId(slotProps.item.entry).length > 0">
                        <JLabelHelpButton :id="`xref-${senseKey}-senses-${slotProps.key}`"
                                          label="Campo semantico di riferimento">
                            Inserisci qui il campo semantico di riferimento per questo termine.
                        </JLabelHelpButton>
                        <Dropdown
                            :aria-labelledby="`xref-${senseKey}-senses-${slotProps.key}`"
                            :aria-describedby="`xref-${senseKey}-senses-${slotProps.key}-help`"
                            :options="getSensesChoicesForEntryId(slotProps.item.entry)"
                            optionLabel="label" optionValue="value" :showClear="!disabled"
                            :optionDisabled="() => disabled" v-model="slotProps.item.sense"/>
                    </div>
                </div>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Message from "primevue/message";
import Skeleton from "primevue/skeleton";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import JOrderList from "@/components/JOrderList.vue";

export default {
    name: "JCrossReferenceEditor",
    components: {
        AutoComplete,
        Button,
        Dialog,
        Dropdown,
        Message,
        Skeleton,
        JLabelHelpButton,
        JOrderList,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        senseLang: {
            type: String,
            required: true,
        },
        tempId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "updateTempId", "onDialogClose"],
    setup(props, { emit }) {
        const store = useStore();

        const v$ = useVuelidate();

        const crossReferences = ref(props.modelValue);
        const referencedEntries = ref([]);
        const error = ref(null);
        const selectedEntry = ref(null);
        const searchResults = ref([]);

        const findReferencedEntryById = (entryId) => referencedEntries.value
            .filter((entry) => entry._id === entryId)?.[0] ?? null;
        const renderCrossReferenceChoice = (entry) => {
            const primaryKanji = entry.kanji?.[0]?.term;
            const primaryReading = entry.readings?.[0]?.term;
            return primaryKanji !== undefined
                ? `${primaryKanji}【${primaryReading}】`
                : primaryReading;
        };
        const renderCrossReferenceTitle = (crossReference) => {
            const referencedEntry = findReferencedEntryById(crossReference.entry);
            const kanji = referencedEntry?.kanji
                ?.find((k) => k._id === crossReference.kanji)
                ?.term ?? referencedEntry.kanji?.[0]?.term ?? null;
            const reading = referencedEntry?.readings
                ?.find((r) => r._id === crossReference.reading)
                ?.term ?? referencedEntry.readings?.[0]?.term ?? null;
            return kanji ? `${kanji}【${reading}】` : reading;
        };
        const renderCrossReferenceChoiceSenses = (entry) => {
            const sameLanguageSenses = entry.senses.filter((sense) => sense === props.senseLang);
            return sameLanguageSenses.length > 0 ? sameLanguageSenses : entry.senses;
        };
        const renderCrossReferenceChoiceGlosses = (sense) => {
            const glosses = sense.glosses.map((gloss) => gloss.term).join("; ");
            return glosses.length > 50 ? `${glosses.substring(0, 50)}...` : glosses;
        };
        const getKanjiChoicesForEntryId = (entryId) => findReferencedEntryById(entryId)
            ?.kanji
            ?.map((kanji) => ({ label: kanji.term, value: kanji._id })) ?? [];
        const getReadingsChoicesForEntryId = (entryId) => findReferencedEntryById(entryId)
            ?.readings
            ?.map((reading) => ({ label: reading.term, value: reading._id })) ?? [];
        const getSensesChoicesForEntryId = (entryId) => findReferencedEntryById(entryId)
            ?.senses
            ?.filter((sense) => sense.lang === props.senseLang)
            ?.map((sense) => ({
                label: renderCrossReferenceChoiceGlosses(sense),
                value: sense._id,
            })) ?? [];
        const fetchEntry = async (entryId) => {
            const response = await store.dispatch("admin/getEntry", entryId);
            return response.data;
        };

        const onSearchEntry = async (event) => {
            error.value = null;
            try {
                const responsesPromises = [];
                const pagesCount = 5;
                for (let i = 1; i < pagesCount; i += 1) {
                    responsesPromises.push(
                        store.dispatch("searchEntries", { query: event.query, page: i }),
                    );
                }
                const responses = await Promise.all(responsesPromises);
                searchResults.value = responses
                    .map((response) => response.data)
                    .flat()
                    .map((entry) => {
                        // eslint-disable-next-line no-param-reassign
                        entry.label = renderCrossReferenceChoice(entry);
                        return entry;
                    });
            } catch (err) {
                error.value = "Si è verificato un errore durante la ricerca!";
                searchResults.value = [];
            }
        };
        const onAddCrossReference = async (event) => {
            try {
                referencedEntries.value.push(await fetchEntry(event.value._id));
            } catch (err) {
                error.value = "Si è verificato un errore durante la raccolta dei dati sul termine!";
            }
            crossReferences.value.push({
                _id: props.tempId,
                entry: event.value._id,
                kanji: undefined,
                reading: undefined,
                sense: undefined,
            });
            selectedEntry.value = null;
            emit("updateTempId");
            emit("update:modelValue", crossReferences.value);
        };
        const onRemoveCrossReference = (item) => {
            const toRemove = referencedEntries.value
                .filter((entry) => entry._id === item.entry)?.[0] ?? null;
            const toRemoveIndex = referencedEntries.value.indexOf(toRemove);
            referencedEntries.value.splice(toRemoveIndex, 1);
        };

        onMounted(async () => {
            const referencedEntriesPromises = crossReferences.value
                ?.map((xref) => fetchEntry(xref.entry)) ?? [];
            try {
                referencedEntries.value = await Promise.all(referencedEntriesPromises);
            } catch (err) {
                error.value = "Si è verificato un errore durante la raccolta dei dati sul termine!";
            }
        });

        return {
            v$,
            crossReferences,
            referencedEntries,
            error,
            selectedEntry,
            searchResults,
            findReferencedEntryById,
            renderCrossReferenceChoice,
            renderCrossReferenceTitle,
            renderCrossReferenceChoiceSenses,
            renderCrossReferenceChoiceGlosses,
            getKanjiChoicesForEntryId,
            getReadingsChoicesForEntryId,
            getSensesChoicesForEntryId,
            onSearchEntry,
            onAddCrossReference,
            onRemoveCrossReference,
        };
    },
};
</script>
