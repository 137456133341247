<template>
    <Dialog header="Letture" :visible="visible" @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)" :draggable="false"
            :closable="!v$.$invalid || (readings?.length ?? 0) === 0"
            :closeOnEscape="!v$.$invalid || (readings?.length ?? 0) === 0"
            :dismissableMask="!v$.$invalid || (readings?.length ?? 0) === 0"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <JOrderList :modelValue="readings" @update:modelValue="$emit('update:readings', $event)"
                    :emptyItem="emptyReading" keyField="_id" addButtonLabel="Aggiungi lettura"
                    :disabled="disabled || reviewing" :enableInsert="!disabled && !reviewing"
                    @emptyItemAdded="onEmptyReadingAdded">
            <template #description>
                <small class="p-error" v-if="v$.readings.$error">
                    Almeno una lettura deve essere presente.
                </small>
                <p>
                    Inserisci qui le letture che compongono il termine.
                    La prima è considerata la lettura principale, mentre le altre sono considerate
                    come forme alternative. Dev'essere presente almeno una lettura.
                    Questa sezione è modificabile solo se il termine non proviene da JMdict.
                </p>
            </template>
            <template #header="slotProps">
                <h3>{{ renderTitle(slotProps.item, slotProps.index) }}</h3>
            </template>
            <template #item="slotProps">
                <JReadingEditor :modelValue="slotProps.item"
                                @update:modelValue="onReadingChanged($event, slotProps.key)"
                                :kanji="kanji" :itemKey="slotProps.key" :disabled="disabled"
                                :reviewing="reviewing"/>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!reviewing ? 'Salva' : 'Chiudi'"
                    :icon="!reviewing ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !reviewing }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import { computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import JOrderList from "@/components/JOrderList.vue";
import JReadingEditor from "@/components/admin/entries/JReadingEditor.vue";

export default {
    name: "JReadingsListEditor",
    components: {
        Button,
        Dialog,
        JReadingEditor,
        JOrderList,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        reviewing: {
            type: Boolean,
            default: false,
        },
        kanji: {
            type: Object,
            required: true,
        },
        readings: {
            type: Object,
            required: true,
        },
        tempId: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:readings", "update:visible", "onDialogClose", "updateTempId"],
    setup(props, { emit }) {
        const readingsObject = computed(() => ({ readings: props.readings }));

        const readingsRules = {
            readings: { required, minLength: minLength(1) },
        };
        const v$ = useVuelidate(readingsRules, readingsObject);

        const emptyReading = computed(() => ({
            _id: props.tempId,
            term: null,
            noKanji: false,
            restrict: [],
            readingInfo: [],
            priorities: [],
        }));

        const renderTitle = (reading, index) => ((reading.term ?? "") !== ""
            ? reading.term
            : `Lettura ${index + 1}`);
        const onEmptyReadingAdded = () => {
            // We added a new element and we need to tell the EntryDetail component to give us
            // a new fresh temporary ID (we'll get it from the updated tempId prop)
            emit("updateTempId");
        };
        const onReadingChanged = (newReading, key) => {
            const clonedReading = cloneDeep(props.readings);
            const foundReading = props.readings.find((reading) => reading._id === key);
            const foundReadingIndex = props.readings.indexOf(foundReading);
            clonedReading[foundReadingIndex] = newReading;
            emit("update:readings", clonedReading);
        };

        return {
            v$,
            emptyReading,
            renderTitle,
            onEmptyReadingAdded,
            onReadingChanged,
        };
    },
};
</script>

<style lang="scss" scoped>
.p-fluid .p-button {
    width: unset;
}
</style>
