<template>
    <li tabindex="0" role="option" class="item-card p-mb-2" :key="modelValue._id">
        <div class="p-grid no-margin">
            <div class="p-col-12 counter">
                <JEntrySense :isBackoffice="true" :sense="modelValue" :tags="tags"
                             :kanji="kanji" :readings="readings"/>
            </div>
            <div class="p-col-12 p-py-0 p-d-flex p-ai-center p-flex-wrap-reverse">
                <div class="p-ml-auto">
                    <Button
                        label="Aggiungi traduzioni" icon="pi pi-plus"
                        class="p-m-1 p-button-sm p-button-success"
                        v-if="!hasGlosses"
                        @click="glossesEditorVisible = true"/>
                    <Button
                        :aria-label="menuTitles" :icon="disabled ? 'pi pi-eye' : 'pi pi-pencil'"
                        :class="{
                            'p-m-1': true,
                            'p-button-sm': true,
                            'p-button-success': !hasGlosses,
                        }"
                        :label="disabled ? 'Ispeziona campo semantico' : 'Modifica campo semantico'"
                        aria-haspopup="true"
                        :aria-controls="`menu-${modelValue._id}`"
                        v-else
                        @click="onToggleMenu"/>
                    <Button
                        aria-label="Muovi su" icon="pi pi-angle-up"
                        class="p-m-1 p-button-sm"
                        :disabled="!canMoveUp || disabled"
                        @click="$emit('onMoveUp')"/>
                    <Button
                        aria-label="Muovi giù" icon="pi pi-angle-down"
                        class="p-m-1 p-button-sm"
                        :disabled="!canMoveDown || disabled"
                        @click="$emit('onMoveDown')"/>
                    <Button
                        aria-label="Rimuovi" icon="pi pi-trash"
                        class="p-button-danger p-button-sm p-m-1"
                        :disabled="!canRemove || disabled"
                        @click="$emit('onRemove')"/>
                </div>
            </div>
        </div>
        <Menu class="menu-limited" :id="`menu-${item._id}`" ref="menu"
              :model="menuItems" :popup="true"/>
        <JAppliesToEditor v-model="item" v-model:visible="appliesToEditorVisible"
                          :kanji="kanji" :readings="readings" :senseKey="item._id"
                          :disabled="disabled"/>
        <JGlossesEditor v-model="item.glosses" v-model:visible="glossesEditorVisible"
                        :senseKey="item._id" :disabled="disabled"/>
        <JSenseNotesEditor v-model="item.notes" v-model:visible="notesEditorVisible"
                           :senseKey="item._id" :disabled="disabled"/>
        <JExamplesEditor v-model="item.examples" v-model:visible="examplesEditorVisible"
                         :senseKey="item._id" :disabled="disabled"/>
        <JCrossReferenceEditor v-model="item.crossReferences"
                               v-model:visible="crossReferencesEditorVisible"
                               :senseLang="item.lang" :senseKey="item._id" :tempId="tempId"
                               @updateTempId="$emit('updateTempId')" :disabled="disabled"/>
        <JCrossReferenceEditor v-model="item.antonyms" v-model:visible="antonymsEditorVisible"
                               :senseLang="item.lang" :senseKey="item._id" :tempId="tempId"
                               @updateTempId="$emit('updateTempId')" :disabled="disabled"/>
        <JSourceTermsEditor v-model="item.sourceTerms" v-model:visible="sourceTermsEditorVisible"
                            :glosses="item.glosses" :senseKey="item._id" :disabled="disabled"/>
        <JMiscEditor v-model="item" v-model:visible="miscEditorVisible" :tags="tags"
                     :partsOfSpeech="partsOfSpeech" :senseKey="item._id" :disabled="disabled"/>
    </li>
</template>

<script>
import { cloneDeep } from "lodash";
import { computed, ref, watch } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import JAppliesToEditor from "@/components/admin/entries/JAppliesToEditor.vue";
import JEntrySense from "@/components/JEntrySense.vue";
import JGlossesEditor from "@/components/admin/entries/JGlossesEditor.vue";
import JSenseNotesEditor from "@/components/admin/entries/JSenseNotesEditor.vue";
import JExamplesEditor from "@/components/admin/entries/JExamplesEditor.vue";
import JCrossReferenceEditor from "@/components/admin/entries/JCrossReferenceEditor.vue";
import JSourceTermsEditor from "@/components/admin/entries/JSourceTermsEditor.vue";
import JMiscEditor from "@/components/admin/entries/JMiscEditor.vue";

export default {
    name: "JOrderableSense",
    components: {
        Button,
        Menu,
        JAppliesToEditor,
        JEntrySense,
        JGlossesEditor,
        JSourceTermsEditor,
        JCrossReferenceEditor,
        JExamplesEditor,
        JSenseNotesEditor,
        JMiscEditor,
    },
    props: {
        modelValue: {
            type: Object,
        },
        kanji: {
            type: Object,
            required: true,
        },
        readings: {
            type: Object,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tempId: {
            type: String,
            required: true,
        },
        partsOfSpeech: {
            type: Array,
            required: true,
        },
        canMoveUp: {
            type: Boolean,
            default: false,
        },
        canMoveDown: {
            type: Boolean,
            default: false,
        },
        canRemove: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "updateTempId", "onMoveUp", "onMoveDown", "onRemove"],
    setup(props, { emit }) {
        const item = ref(props.modelValue);
        watch(() => cloneDeep(item), () => emit("update:modelValue", item.value));

        const appliesToEditorVisible = ref(false);
        const glossesEditorVisible = ref(false);
        const notesEditorVisible = ref(false);
        const examplesEditorVisible = ref(false);
        const crossReferencesEditorVisible = ref(false);
        const antonymsEditorVisible = ref(false);
        const sourceTermsEditorVisible = ref(false);
        const miscEditorVisible = ref(false);

        const hasGlosses = computed(() => (props.modelValue.glosses?.length ?? 0) > 0);

        const menu = ref();
        const menuTitles = computed(() => (props.disabled ? "Visualizza" : "Modifica"));
        const menuItems = computed(() => [
            {
                label: `${menuTitles.value} letture/scritture di riferimento`,
                command() {
                    appliesToEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} traduzioni`,
                command() {
                    glossesEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} note aggiuntive`,
                command() {
                    notesEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} esempi`,
                command() {
                    examplesEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} termini correlati`,
                command() {
                    crossReferencesEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} contrari`,
                command() {
                    antonymsEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} termini sorgente`,
                command() {
                    sourceTermsEditorVisible.value = true;
                },
            },
            {
                label: `${menuTitles.value} informazioni aggiuntive`,
                command() {
                    miscEditorVisible.value = true;
                },
            },
        ]);

        const onToggleMenu = (event) => {
            menu.value.toggle(event);
        };

        return {
            item,
            appliesToEditorVisible,
            glossesEditorVisible,
            notesEditorVisible,
            examplesEditorVisible,
            crossReferencesEditorVisible,
            antonymsEditorVisible,
            sourceTermsEditorVisible,
            miscEditorVisible,
            hasGlosses,
            menu,
            menuTitles,
            menuItems,
            onToggleMenu,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    .counter::before {
        counter-increment: senseNumber;
        content: counter(senseNumber) ". ";
    }
    .item-card {
        background: $panelContentBg;
        color: $panelContentTextColor;
        box-shadow: $cardShadow;
        border-radius: $borderRadius;
        padding: $cardBodyPadding;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
</style>
