const serverUrl = process.env.VUE_APP_SERVER_URL;

const CRUDModule = {
    actions: {
        async getCollections(context, entryId) {
            const response = await context.rootGetters.getServerConnection.get("/collections", { params: { entryId } });
            return response.data;
        },
        async createCollection(context, collectionName) {
            const response = await context.rootGetters.getServerConnection.post("/collections", { collectionName });
            return response.data._id;
        },
        async deleteCollection(context, collectionId) {
            await context.rootGetters.getServerConnection.delete("/collections", { data: { collectionId } });
        },
        async renameCollection(context, { collectionId, newName }) {
            await context.rootGetters.getServerConnection.put("/collections", { collectionId, newName });
        },
        async getCollectionEntries(context, { collectionId, page = 1 }) {
            const response = await context.rootGetters.getServerConnection
                .get(`/collections/${collectionId}`, { params: { page } });
            return response.data;
        },
        async getCollectionName(context, collectionId) {
            if (collectionId === "watchlist") {
                return "Watchlist";
            }
            const response = await context.rootGetters.getServerConnection
                .get(`/collections/${collectionId}/name`);
            return response.data.name;
        },
        downloadCollection(context, { collectionId, frontFields, backFields }) {
            const encodedFrontFields = JSON.stringify(frontFields);
            const encodedBackFields = JSON.stringify(backFields);
            window.open(`${serverUrl}collections/${collectionId}/anki?frontFields=${encodedFrontFields}&backFields=${encodedBackFields}`);
        },
    },
};

export default CRUDModule;
