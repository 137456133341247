const assignmentsModule = {
    actions: {
        async queryUserAssignments(context, { query, userId }) {
            return context.rootGetters.getServerConnection.get(`/assignments/user/${userId}/query`, {
                params: { query },
            });
        },
        async submitAssignment(context, { entryId, assignerId, assigneeId }) {
            return context.rootGetters.getServerConnection.post("/assignments/", {
                entryId,
                assignerId,
                assigneeId,
            });
        },
        async submitAssignments(context, { number, assignerId, assigneeId }) {
            return context.rootGetters.getServerConnection.post(`/assignments/${number}`, {
                assignerId,
                assigneeId,
            });
        },
        async deleteAssignment(context, assignmentId) {
            return context.rootGetters.getServerConnection.delete("/assignments/", { data: { assignmentId } });
        },
    },
};

export default assignmentsModule;
