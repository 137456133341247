const userModule = {
    state() {
        return {
            user: null,
            mediumRegex: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./,
            strongRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        };
    },
    getters: {
        loggedUser: (state) => state.user,
        isLoggedIn: (state) => state.user?.isAuthenticated ?? false,
        isPasswordSecure: (state) => (password) => state.mediumRegex.test(password)
                                                || state.strongRegex.test(password),
        hasRoleOrUpper: (state) => (role) => {
            // Roles are in descending order, i.e. an admin is a reviewer with extra permissions
            const roles = ["admin", "reviewer", "compiler", "user"];
            return state.user !== null
                && "role" in state.user
                && roles.includes(state.user.role)
                && roles.includes(role)
                && roles.indexOf(state.user.role) <= roles.indexOf(role);
        },
    },
    mutations: {
        SET_USER: (state, user) => {
            state.user = user;
        },
    },
    actions: {
        async loadLoggedUser(context) {
            const res = await context.rootGetters.getServerConnection.get("/users/user");
            if (res.status === 200) {
                context.commit("SET_USER", res.data);
                if (res.data.isAuthenticated) {
                    await context.dispatch("createSocket", {
                        socketToken: res.data.socketToken,
                        userId: res.data._id,
                    });
                    await context.dispatch("loadUnreadNotificationsCount");
                }
            }
        },
        async login(context, credentials) {
            const res = await context.rootGetters.getServerConnection.post("/users/login", credentials);
            if (res.status === 200) {
                await context.dispatch("loadLoggedUser");
            }
            return res;
        },
        async registerNewUser(context, user) {
            const res = await context.rootGetters.getServerConnection.post("/users/signup", user);
            await context.dispatch("login", { email: user.email, password: user.password });
            return res;
        },
        async logout(context, payload) {
            const res = await context.rootGetters.getServerConnection.post("users/logout", payload);
            if (res.status === 200) {
                context.dispatch("loadLoggedUser");
            }
            return res;
        },
        async generateConfirmationToken(context) {
            return context.rootGetters.getServerConnection.post("/users/confirmation");
        },
        async updateProfile(context, user) {
            const res = await context.rootGetters.getServerConnection.put("/users/user", user);
            if (res.status === 200) {
                context.dispatch("loadLoggedUser");
            }
            return res;
        },
        async generatePasswordToken(context, userEmail) {
            return context.rootGetters.getServerConnection.post("/users/password", { email: userEmail });
        },
        async confirmUser(context, token) {
            const res = await context.rootGetters.getServerConnection.post(`/users/confirmation/${token}`);
            if (res.status === 200) {
                await context.dispatch("loadLoggedUser");
            }
            return res;
        },
        async checkPasswordToken(context, token) {
            return context.rootGetters.getServerConnection.post(`/users/password/${token}`);
        },
        async resetPassword(context, param) {
            return context.rootGetters.getServerConnection.post("/users/passwordReset", param);
        },
        async getUserStatistics(context) {
            return context.rootGetters.getServerConnection.get("/users/statistics");
        },
        async deleteUser(context, email) {
            return context.rootGetters.getServerConnection.delete("/users/user", { data: { email } });
        },
    },
};

export default userModule;
