<template>
    <h1>Traduzioni</h1>
    <div v-if="userStats.numberOfTranslations === 0" class="p-my-6">
        <p class="p-text-center">
            Effettua una traduzione per poter visualizzare le tue statistiche!
        </p>
    </div>
    <div v-else>
        <p class="subtitle p-my-4 p-text-center" v-if="userRole !== 'compiler' &&
                userStats.numberOfReviewedTranslations === 0">
            Complimenti, hai effettuato <b>{{ userStats.numberOfTranslations }}</b>
            traduzioni!
        </p>
        <div class="p-grid no-margin" v-if="userStats.numberOfReviewedTranslations !== 0">
            <div class="p-col-12 p-xl-6 p-m-auto">
                <p class="subtitle p-m-0">
                    Complimenti, hai effettuato <b>{{ userStats.numberOfTranslations }}</b>
                    traduzioni!
                </p>
                <p v-if="userRole !== 'compiler'" class="">
                    Queste sono le statistiche di quando eri compilatore:
                </p>
                <p>Di queste <b>{{ userStats.numberOfTranslations }}</b> traduzioni,
                <b>{{ userStats.numberOfReviewedTranslations }}</b> sono state revisionate.
                <br /><br />
                In totale, le traduzioni approvate sono
                <b>{{ userStats.numberOfApprovedTranslations }}</b> mentre quelle rifiutate
                    <b>{{ userStats.numberOfRefusedTranslations }}</b>.</p>
            </div>
            <div class="p-col-12 p-xl-6 p-m-0">
                <Chart type="doughnut" :data="translations" :options="optionsDoughnut" />
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "primevue/chart";

export default {
    name: "JProfileStatistics",
    components: {
        Chart,
    },
    props: {
        userStats: {
            required: true,
        },
        userRole: {
            required: true,
        },
    },
    setup(props) {
        const translations = {
            labels: [
                `Approvate: ${props.userStats.numberOfApprovedTranslations}`,
                `Rifiutate: ${props.userStats.numberOfRefusedTranslations}`,
            ],
            datasets: [
                {
                    backgroundColor: ["#58a58c", "#d07272"],

                    data: [
                        props.userStats.numberOfApprovedTranslations,
                        props.userStats.numberOfRefusedTranslations,
                    ],
                },
            ],
        };

        const optionsDoughnut = {
            responsive: true,
            title: {
                display: false,
                text: "Traduzioni approvate e rifiutate",
            },
        };

        return {
            translations,
            optionsDoughnut,
        };
    },
};
</script>
<style lang="scss" scoped>
    @import "./src/assets/scss/theme/variables";
    .subtitle {
        font-size: 1.5em;
    }
</style>
