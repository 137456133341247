<template>
    <main>
        <j-loading-bar :loading="loading"></j-loading-bar>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-10 p-sm-offset-1 p-md-8 p-md-offset-2 p-py-5">
                <div class="p-mb-6">
                    <j-search-bar :initial-query="$route.query.q ?? ''"></j-search-bar>
                </div>
                <template v-if="error === null">
                    <j-term-card
                        v-if="term !== null"
                        :term="term"
                        :is-term-page="true"></j-term-card>
                </template>
                <template v-else>
                    <h2 class="error-title">{{ error.title }}</h2>
                    <p class="error-text">{{ error.text }}</p>
                </template>
            </div>
        </div>
    </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import JLoadingBar from "@/components/JLoadingBar.vue";
import JTermCard from "@/components/JTermCard.vue";
import JSearchBar from "@/components/JSearchBar.vue";

export default {
    name: "Entry",
    components: { JLoadingBar, JTermCard, JSearchBar },
    setup() {
        const route = useRoute();
        const store = useStore();

        const loading = ref(true);
        const term = ref(null);
        const error = ref(null);

        const getEntry = async () => {
            loading.value = true;
            error.value = null;
            try {
                const response = await store.dispatch("getEntry", route.params.entryId);
                term.value = response.data;
            } catch (err) {
                error.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore! Prova a ricaricare la pagina.",
                };
            }
            loading.value = false;
        };

        onMounted(getEntry);

        return {
            term,
            loading,
            error,
        };
    },
};
</script>
