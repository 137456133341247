import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import Signup from "@/views/Signup.vue";
import SearchResults from "@/views/SearchResults.vue";
import Entry from "@/views/Entry.vue";
import Error404 from "@/views/error/Error404.vue";
import Error403 from "@/views/error/Error403.vue";
import AccountConfirmation from "@/views/AccountConfirmation.vue";
import Profile from "@/views/Profile.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import NewPassword from "@/views/NewPassword.vue";
import Collections from "@/views/Collections.vue";
import JStartingCollection from "@/components/collections/JStartingCollection.vue";
import JCollection from "@/components/collections/JCollection.vue";
import About from "@/views/About.vue";
import Support from "@/views/Support.vue";
import Privacy from "@/views/Privacy.vue";
import AdminBase from "@/views/admin/Base.vue";
import AdminHome from "@/views/admin/Home.vue";
import AdminEntries from "@/views/admin/Entries.vue";
import EntryDetail from "@/views/admin/EntryDetail.vue";
import AdminTags from "@/views/admin/Tags.vue";
import TagDetail from "@/views/admin/TagDetail.vue";
import AdminCollaborators from "@/views/admin/Collaborators.vue";
import CollaboratorDetail from "@/views/admin/CollaboratorDetail.vue";
import AdminUsers from "@/views/admin/Users.vue";
import UserDetail from "@/views/admin/UserDetail.vue";
import Notifications from "@/views/Notifications.vue";
import Assignments from "@/views/Assignments.vue";
import Preferences from "@/views/admin/Preferences.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: "Home"
        }
    },
    {
        path: "/about",
        name: "about",
        component: About,
        meta: {
            title: "About"
        }
    },
    {
        path: "/support",
        name: "support",
        component: Support,
        meta: {
            title: "Support"
        }
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
        meta: {
            title: "Privacy"
        }
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            title: "Login",
            requireAnonymous: true
        }
    },
    {
        path: "/signup",
        name: "signup",
        component: Signup,
        meta: {
            title: "Registrati",
            requireAnonymous: true
        }
    },
    {
        path: "/search",
        name: "search",
        component: SearchResults,
        meta: {
            title: "Cerca"
        }
    },
    {
        path: "/entry/:slug-:entryId",
        name: "entry",
        component: Entry,
        meta: {
            title: "Termine"
        }
    },
    {
        path: "/users/confirmation/:token",
        name: "accountConfirmation",
        component: AccountConfirmation,
        props: true,
        meta: {
            title: "Conferma Account"
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: {
            title: "Profilo",
            requireLogin: true
        }
    },
    {
        path: "/resetpassword",
        name: "resetPassword",
        component: ResetPassword,
        meta: {
            title: "Password Dimenticata"
        }
    },
    {
        path: "/resetpassword/:token",
        name: "newPassword",
        component: NewPassword,
        props: true,
        meta: {
            title: "Nuova Password"
        }
    },
    {
        path: "/collections",
        name: "collections",
        component: Collections,
        meta: {
            title: "Collezioni",
            requireLogin: true
        },
        children: [
            {
                path: "",
                name: "collections.empty",
                component: JStartingCollection,
                meta: {
                    requireLogin: true
                }
            },
            {
                path: ":collectionId",
                name: "collections.detail",
                props: true,
                component: JCollection,
                meta: {
                    requireLogin: true
                }
            }
        ]
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminBase,
        meta: {
            requireRole: "compiler",
            title: "Pannello di amministrazione"
        },
        children: [
            {
                path: "",
                name: "admin.home",
                component: AdminHome,
                meta: {
                    title: "Dashboard"
                }
            },
            {
                path: "entries",
                name: "admin.entries",
                component: AdminEntries,
                meta: {
                    title: "Termini",
                    breadcrumbs: ["Termini"]
                }
            },
            {
                path: "entries/new",
                name: "admin.entries.new",
                component: EntryDetail,
                props: true,
                meta: {
                    requireRole: "reviewer",
                    title: "Nuovo termine",
                    breadcrumbs: [{ to: { name: "admin.entries" }, label: "Termini" }, "Nuovo"]
                }
            },
            {
                path: "entries/:entryId",
                name: "admin.entries.detail",
                component: EntryDetail,
                props: true,
                meta: {
                    title: "Dettaglio termine",
                    breadcrumbs: [{ to: { name: "admin.entries" }, label: "Termini" }, "Dettaglio"]
                }
            },
            {
                path: "tags",
                name: "admin.tags",
                component: AdminTags,
                meta: {
                    title: "Tag",
                    breadcrumbs: ["Tag"]
                }
            },
            {
                path: "tags/new",
                name: "admin.tags.new",
                component: TagDetail,
                props: true,
                meta: {
                    title: "Nuovo tag",
                    breadcrumbs: [{ to: { name: "admin.tags" }, label: "Tag" }, "Nuovo"]
                }
            },
            {
                path: "tags/:tagId",
                name: "admin.tags.detail",
                component: TagDetail,
                props: true,
                meta: {
                    title: "Dettaglio tag",
                    breadcrumbs: [{ to: { name: "admin.tags" }, label: "Tag" }, "Dettaglio"]
                }
            },
            {
                path: "collaborators",
                name: "admin.collaborators",
                component: AdminCollaborators,
                meta: {
                    requireRole: "admin",
                    title: "Collaboratori",
                    breadcrumbs: ["Collaboratori"]
                }
            },
            {
                path: "collaborators/new",
                name: "admin.collaborators.new",
                component: CollaboratorDetail,
                props: true,
                meta: {
                    title: "Nuovo collaboratore",
                    breadcrumbs: [
                        { to: { name: "admin.collaborators" }, label: "Collaboratori" },
                        "Nuovo"
                    ]
                }
            },
            {
                path: "collaborators/:collaboratorId",
                name: "admin.collaborators.detail",
                component: CollaboratorDetail,
                props: true,
                meta: {
                    requireRole: "admin",
                    title: "Dettaglio collaboratore",
                    breadcrumbs: [
                        { to: { name: "admin.collaborators" }, label: "Collaboratori" },
                        "Dettaglio"
                    ]
                }
            },
            {
                path: "users",
                name: "admin.users",
                component: AdminUsers,
                meta: {
                    requireRole: "admin",
                    title: "Utenti",
                    breadcrumbs: ["Utenti"]
                }
            },
            {
                path: "users/:userId",
                name: "admin.users.detail",
                component: UserDetail,
                props: true,
                meta: {
                    requireRole: "admin",
                    title: "Dettaglio utente",
                    breadcrumbs: [{ to: { name: "admin.users" }, label: "Utenti" }, "Dettaglio"]
                }
            }
        ]
    },
    {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
        meta: {
            title: "Notifiche",
            requireLogin: true
        }
    },
    {
        path: "/assignments",
        name: "assignments",
        component: Assignments,
        meta: {
            title: "Assignment",
            requireRole: "compiler"
        }
    },
    {
        path: "/preferences",
        name: "preferences",
        component: Preferences,
        meta: {
            title: "Impostazioni",
            requireRole: "admin"
        }
    },
    {
        path: "/403",
        name: "403",
        component: Error403,
        meta: {
            title: "Accesso vietato"
        }
    },
    {
        path: "/:pathMatch(.*)",
        component: Error404,
        name: "404",
        meta: {
            title: "Pagina inesistente"
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
