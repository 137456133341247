<template>
    <div class="p-grid no-margin">
        <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
            <j-profile></j-profile>
        </div>
    </div>
</template>

<script>

import JProfile from "@/components/JProfile.vue";

export default {
    name: "Profile",
    components: { JProfile },
};
</script>
