<template>
    <Dialog header="Informazioni aggiuntive" v-model:visible="dialogVisible"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid || !v$.$dirty"
            :closeOnEscape="!v$.$invalid || !v$.$dirty"
            :dismissableMask="!v$.$invalid || !v$.$dirty"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-pos-${senseKey}`"
                                  label="Parti del discorso">
                    Indica le parti del discorso per questo campo semantico del termine.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.partsOfSpeech" :filter="true"
                    :options="partsOfSpeech" :showToggleAll="!disabled"
                    optionLabel="label" optionValue="value"
                    placeholder="Nessuna parte del discorso"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-pos-${senseKey}`"
                    :aria-describedby="boolObjectToString({
                           [`sense-pos-${senseKey}-help`]: true,
                           [`sense-pos-${senseKey}-err`]: v$.partsOfSpeech.$error,
                       })"
                    :class="{ 'p-invalid': v$.partsOfSpeech.$error }"
                    @hide="v$.$touch"/>
                <small class="p-error" :id="`sense-pos-${senseKey}-err`"
                       v-if="v$.partsOfSpeech.$error">
                    Il campo è obbligatorio.
                </small>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-tags-${senseKey}`"
                                  label="Tag">
                    Indica eventuali tag per questo campo semantico del termine.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.tags" :filter="true"
                    :options="tags" :showToggleAll="!disabled"
                    optionLabel="label" optionValue="_id"
                    placeholder="Nessun tag"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-tags-${senseKey}`"
                    :aria-describedby="`sense-tags-${senseKey}-help`"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-dialects-${senseKey}`"
                                  label="Dialetti">
                    Indica se questo campo semantico è associato a uno o più dei seguenti dialetti.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.dialects" :filter="true"
                    :options="dialects" :showToggleAll="!disabled"
                    optionLabel="label" optionValue="value"
                    placeholder="Nessun dialetto"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-dialects-${senseKey}`"
                    :aria-describedby="`sense-dialects-${senseKey}-help`"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <JLabelHelpButton :id="`sense-misc-${senseKey}`"
                                  label="Informazioni aggiuntive">
                    Indica eventuali informazioni aggiuntive per questo campo semantico del termine.
                </JLabelHelpButton>
                <MultiSelect
                    v-model="sense.misc" :filter="true"
                    :options="miscellaneousEntryInfo" :showToggleAll="!disabled"
                    optionLabel="label" optionValue="value"
                    placeholder="Nessuna informazione aggiuntiva"
                    :optionDisabled="() => disabled"
                    :aria-labelledby="`sense-misc-${senseKey}`"
                    :aria-describedby="`sense-misc-${senseKey}-help`"/>
            </div>
        </div>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="dialogVisible = false" :disabled="v$.$invalid && v$.$dirty"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import MiscellaneousEntryInfo from "@/enums/misc";
import Dialect from "@/enums/dialect";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JMiscEditor",
    components: {
        Button,
        Dialog,
        MultiSelect,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        tags: {
            type: Array,
            required: true,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        partsOfSpeech: {
            type: Array,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "onDialogClose"],
    setup(props, { emit }) {
        const sense = ref(props.modelValue);

        const rules = {
            partsOfSpeech: { required },
        };

        const v$ = useVuelidate(rules, sense);

        const dialogVisible = ref(props.visible);
        watch(() => props.visible, (newValue) => {
            dialogVisible.value = newValue;
        });
        watch(dialogVisible, () => emit("update:visible", dialogVisible.value));

        const miscellaneousEntryInfo = ref(Object.values(MiscellaneousEntryInfo));
        const dialects = ref(Object.values(Dialect));

        return {
            v$,
            sense,
            dialogVisible,
            miscellaneousEntryInfo,
            dialects,
            boolObjectToString,
        };
    },
};
</script>
