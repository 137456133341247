<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
                <j-login></j-login>
            </div>
        </div>
    </main>
</template>

<script>
import JLogin from "@/components/JLogin.vue";

export default {
    name: "Login",
    components: { JLogin },
};
</script>
