<template>
    <div
        :class="containerClass"
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <div class="p-toast-message-content">
            <span :class="iconClass"></span>
            <div class="p-toast-message-text">
                <span class="p-toast-summary">{{ message.summary }}</span>
                <div>
                    <j-notification-details :details="message.details"/>
                </div>
                <div v-if="message.button" class="p-mt-1 button-container">
                    <j-notification-button :notification="message.button.forNotification"
                                           :class="buttonClass"/>
                </div>
            </div>
            <button class="p-toast-icon-close p-link"
                    @click="onCloseClick"
                    v-if="message.closable !== false"
                    type="button" v-ripple>
                <span class="p-toast-icon-close-icon pi pi-times"></span>
            </button>
        </div>
    </div>
</template>

<script>
import ToastMessage from "primevue/toast/ToastMessage.vue";
import JNotificationButton from "@/components/notification/JNotificationButton.vue";
import JNotificationDetails from "@/components/notification/JNotificationDetails.vue";

function severityToButtonClass(severity) {
    let buttonClassSuffix;
    switch (severity) {
        case "warn": { buttonClassSuffix = "warning"; break; }
        case "error": { buttonClassSuffix = "danger"; break; }
        default: { buttonClassSuffix = severity; }
    }
    return `p-button-${buttonClassSuffix}`;
}

export default {
    name: "JToastMessage",
    components: { JNotificationButton, JNotificationDetails },
    extends: ToastMessage,
    setup(props) {
        const buttonClass = severityToButtonClass(props.message.severity);
        return { buttonClass };
    },
};
</script>

<style scoped lang="scss">
    .button-container {
        text-align: right;
    }

    .p-toast-icon-close {
        flex-shrink: 0;
    }
</style>
