import getNotificationDisplayData from "@/utils/notificationsDisplayData";

const life = 10_000;

export function fromSimpleText(severity, summary, detail) {
    return {
        severity,
        summary,
        details: [{ type: "text", text: detail }],
        life,
    };
}

export function fromNotification(notification) {
    const notificationDisplayData = getNotificationDisplayData(notification);
    notificationDisplayData.life = life;
    return notificationDisplayData;
}
