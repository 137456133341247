<template>
    <Card class="p-p-3">
        <template #title>
            <h1 class="p-my-0">Accedi</h1>
        </template>
        <template #content>
            <form id="login-form" autocomplete="on">
                <div class="p-fluid">
                    <div class="p-field p-grid">
                        <label for="username" class="p-col-12 p-md-3 p-xl-2">Email</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <InputText id="username" type="email" autocomplete="email"
                                       v-model="v$.email.$model"
                                       :class="{ 'p-invalid': v$.email.$invalid && isSubmitted }" />
                        </div>
                        <small class="p-error p-d-block"
                               v-if="(v$.email.$error || v$.email.$invalid) && isSubmitted">
                            Email inserita non valida
                        </small>
                    </div>
                    <div class="p-field p-grid">
                        <label for="password" class="p-col-12 p-md-3 p-xl-2">Password</label>
                        <div class="p-col-12 p-md-9 p-xl-10">
                            <Password id="password" v-model="v$.password.$model"
                                      autocomplete="password" toggleMask :feedback="false"
                                      :class="{ 'p-invalid': v$.password.$invalid && isSubmitted }"
                                      maxlength="72"/>
                        </div>
                        <small v-if="v$.password.$invalid && isSubmitted"
                               class="p-error p-d-block">
                            Il campo è obbligatorio
                        </small>
                    </div>

                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <Checkbox id="rememberMe" name="rememberUser"
                                      v-model="v$.rememberUser.$model" :binary="true" />
                            <label for="rememberMe">Ricordami</label>
                        </div>
                    </div>
                    <Message v-if="successMessage !== ''" severity="success">
                        {{ successMessage }}
                    </Message>
                    <Message v-if="errorMessage !== ''" severity="error">
                        {{ errorMessage }}
                    </Message>

                    <p>Non hai un account? <router-link to="/signup">Registrati</router-link></p>
                    <p>Hai dimenticato la tua password?
                        <router-link to="/resetPassword">Clicca qui</router-link></p>
                </div>
            </form>
        </template>
        <template #footer>
            <Button label="Accedi" :loading="isLoading" class="p-button"
                    form="login-form" type="Submit" @click.prevent="submitLogin(!v$.$invalid)"/>
        </template>
    </Card>
</template>

<script>

import { onBeforeMount, reactive, ref } from "vue";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import Password from "primevue/password";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Message from "primevue/message";
import { useVuelidate } from "@vuelidate/core";

export default {
    name: "JLogin",
    components: {
        Password,
        InputText,
        Button,
        Card,
        Checkbox,
        Message,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        onBeforeMount(() => {
            if (store.getters.isLoggedIn) {
                router.go(-1);
            }
        });

        const state = reactive({
            email: "",
            password: "",
            rememberUser: false,
        });
        const rules = {
            email: { required, email },
            password: { required },
            rememberUser: {},
        };
        const v$ = useVuelidate(rules, state);

        const successMessage = ref("");
        const errorMessage = ref("");
        const isLoading = ref(false);
        const isSubmitted = ref(false);

        const submitLogin = async (isInputValid) => {
            isSubmitted.value = true;
            if (isInputValid) {
                isLoading.value = true;
                try {
                    await store.dispatch("login", state);
                    successMessage.value = "Accesso avvenuto con successo";
                    if (route.query?.next !== undefined) {
                        await router.push(route.query?.next);
                    } else {
                        await router.go(-1);
                    }
                } catch (err) {
                    errorMessage.value = "L'email o la password non sono corrette";
                } finally {
                    isLoading.value = false;
                }
            }
        };

        return {
            successMessage,
            errorMessage,
            v$,
            isLoading,
            isSubmitted,
            submitLogin,
        };
    },
};
</script>

<style scoped>
    h1 {
        font-size: 2rem;
    }
</style>
