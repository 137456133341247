<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-md-6 p-md-offset-3 p-py-5">
                <Card class="p-p-3">
                    <template #title>
                        <h1 class="p-col-12 p-my-0">Password dimenticata?</h1>
                    </template>
                    <template #content>
                        <p class="p-mt-0">Non ti preccupare, invieremo un link per
                            generarne una nuova al seguente indirizzo email:</p>
                        <form id="reset-password-form">
                            <div class="p-fluid">
                                <div class="p-field p-grid">
                                    <label class="p-col-12 p-md-3 p-xl-2">Email</label>
                                    <div class="p-col-12 p-md-9 p-xl-10">
                                        <InputText
                                               :class="{ 'p-invalid': v$.email.$invalid
                                               && isSubmitted }"
                                               type="text" v-model="v$.email.$model" />
                                    </div>
                                    <small class="p-error p-d-block"
                                           v-if="(v$.email.$error || v$.email.$invalid)
                                           && isSubmitted">
                                        Email inserita non valida
                                    </small>
                                </div>
                            </div>
                        </form>
                    </template>
                    <template #footer>
                        <Button class="p-button" type="submit"
                                :isLoading="isLoading" label="Invia"
                                form="reset-password-form"
                                v-on:click.prevent="generatePasswordToken(!v$.$invalid
                                        && !v$.error)" />
                    </template>
                </Card>
            </div>
        </div>

        <Dialog header="Conferma" v-model:visible="isDialogVisible" :closable="false" :modal="true">
            <p>Email inviata con successo</p>
            <template #footer>
                <Button label="Ok" @click="isDialogVisible = false"
                        class="p-button-text"/>
            </template>
        </Dialog>
    </main>
</template>
<script>
import { ref, reactive } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useStore } from "vuex";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Card from "primevue/card";

export default {
    name: "ResetPassword",
    components: {
        InputText, Button, Dialog, Card,
    },
    setup() {
        const store = useStore();

        const isDialogVisible = ref(false);
        const isSubmitted = ref(false);
        const isLoading = ref(false);

        const state = reactive({
            email: "",
        });

        const rules = {
            email: { required, email },
        };

        const v$ = useVuelidate(rules, state);

        const generatePasswordToken = async (isInputValid) => {
            isSubmitted.value = true;
            if (isInputValid) {
                isLoading.value = true;
                try {
                    await store.dispatch("generatePasswordToken", state.email);
                } finally {
                    isDialogVisible.value = true;
                    isLoading.value = false;
                }
            }
        };

        return {
            v$,
            state,
            rules,
            isDialogVisible,
            isSubmitted,
            generatePasswordToken,
        };
    },
};
</script>

<style scoped lang="scss">
    h1 {
        font-size: 2rem;
    }
</style>
