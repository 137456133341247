<template>
    <template v-if="error !== null && error !== undefined">
        <h2 class="error-title">{{ error.title }}</h2>
        <p class="error-text">{{ error.text }}</p>
    </template>
</template>

<script>
export default {
    name: "JError",
    props: {
        error: {
            type: Object,
            required: false,
            default() {
                return {
                    title: "",
                    text: "",
                };
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.error-title {
    text-align: center;
    font-size: 2rem;
}
.error-text {
    text-align: center;
}
</style>
