<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`example-${senseKey}-orig-${itemKey}`"
                              label="Giapponese*">
                Inserisci qui la frase di esempio in giapponese.
            </JLabelHelpButton>
            <InputText :id="`example-${senseKey}-orig-${itemKey}`"
                       :aria-describedby="boolObjectToString({
                           [`example-${senseKey}-orig-${itemKey}-help`]: true,
                           [`example-${senseKey}-orig-${itemKey}-err`]: v$.original.$error,
                       })"
                       :disabled="disabled" :class="{ 'p-invalid': v$.original.$error }"
                       type="text" v-model="v$.original.$model"/>
            <small class="p-error" :id="`example-${senseKey}-orig-${itemKey}-err`"
                   v-if="v$.original.$error">
                <template
                    v-if="v$.original.$errors.some((error) => error.$validator === 'required')">
                    Il campo è obbligatorio.
                </template>
                <template v-if="v$.original.$errors.length > 1">
                    <br>
                </template>
                <template
                    v-if="v$.original.$errors.some((error) => error.$validator === 'isJapanese')
                          && v$.original.$model.length > 0">
                    Il campo deve contenere una frase in giapponese.
                </template>
            </small>
        </div>
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`example-${senseKey}-trans-${itemKey}`"
                              label="Traduzione*">
                Inserisci qui la frase di esempio tradotta nella lingua di questo
                campo semantico.
            </JLabelHelpButton>
            <InputText :id="`example-${senseKey}-trans-${itemKey}`"
                       :aria-describedby="boolObjectToString({
                           [`example-${senseKey}-trans-${itemKey}-help`]: true,
                           [`example-${senseKey}-trans-${itemKey}-err`]: v$.translation.$error,
                       })"
                       :disabled="disabled" :class="{ 'p-invalid': v$.translation.$error }"
                       type="text" v-model="v$.translation.$model"/>
            <small class="p-error" :id="`example-${senseKey}-trans-${itemKey}-err`"
                   v-if="v$.translation.$error">
                Il campo è obbligatorio.
            </small>
        </div>
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`example-${senseKey}-source-${itemKey}`"
                              label="Fonte">
                Inserisci qui la fonte (se presente) dalla quale proviene l'esempio.
                Lascia questo campo vuoto se l'esempio è originale.
            </JLabelHelpButton>
            <InputText :id="`example-${senseKey}-source-${itemKey}`"
                       :aria-describedby="`example-${senseKey}-source-${itemKey}-help`"
                       :disabled="disabled" type="text" v-model="item.source"/>
        </div>
    </div>
</template>

<script>
import { reactive, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JExampleEditor",
    components: {
        InputText,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const japaneseRegex = new RegExp(
            "^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff"
            + "\uff00-\uffef\u4e00-\u9ffc\uf900-\ufaff\u2f00-\u2fdf]+$",
            "gmu",
        );
        const isJapanese = (value) => value?.match(japaneseRegex) !== null ?? false;
        const rules = {
            original: { required, isJapanese },
            translation: { required },
        };

        const v$ = useVuelidate(rules, item);

        return {
            v$,
            item,
            boolObjectToString,
        };
    },
};
</script>

<style scoped>

</style>
