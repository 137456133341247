const EntryStatus = {
    DELETED: {
        label: "Cancellato",
        value: "deleted",
        cssClass: "entry-status-deleted",
    },
    TO_TRANSLATE: {
        label: "Da tradurre",
        value: "toTranslate",
        cssClass: "entry-status-to-translate",
    },
    REVIEW_PENDING: {
        label: "In revisione",
        value: "reviewPending",
        cssClass: "entry-status-review-pending",
    },
    TRANSLATED: {
        label: "Tradotto",
        value: "translated",
        cssClass: "entry-status-translated",
    },
};
Object.freeze(EntryStatus);
export default EntryStatus;
