<template>
  <div>
    <div class="p-d-flex p-ai-center">
      <h1>Termini</h1>
      <div class="p-ml-auto" v-if="isUserReviewer">
        <Button
          label="Aggiungi termine"
          icon="pi pi-plus"
          class="p-button-success p-m-1"
          @click="onOpenEntry()"
        />
      </div>
    </div>
    <DataTable
      ref="entriesTable"
      :loading="loading"
      :value="entries"
      :lazy="true"
      :paginator="true"
      :totalRecords="totalEntries"
      dataKey="_id"
      responsiveLayout="scroll"
      :row-hover="true"
      v-model:filters="filters"
      filter-display="menu"
      :rows="10"
      :rows-per-page-options="[10, 25, 50, 100]"
      @page="onPageChanged"
      @sort="onSort"
      @filter="onFilter"
    >
      <Column :exportable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-raised"
            aria-label="Modifica"
            @click="onOpenEntry(slotProps.data._id)"
          />
        </template>
      </Column>
      <Column
        field="jmdictId"
        header="ID JMdict"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="number"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per ID JMdict"
          />
        </template>
      </Column>
      <Column field="kanji" header="Scrittura" sortable>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per kanji"
          />
        </template>
      </Column>
      <Column field="readings" header="Lettura" sortable>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per lettura"
          />
        </template>
      </Column>
      <Column
        field="meta.version"
        header="Versione"
        sortable
        dataType="numeric"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="number"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per versione"
          />
        </template>
      </Column>
      <Column
        field="state"
        header="Stato"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        filterMenuClass="limit-width"
      >
        <template #body="slotProps">
          <Tag
            :value="slotProps.data.state.label"
            :class="slotProps.data.state.cssClass"
          >
          </Tag>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Qualunque"
            class="p-column-filter"
          >
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="hasExamples"
        header="Esempi"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        filterMenuClass="limit-width"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.hasExamples">
            <i class="pi pi-check" style="color: #138762;"></i> <!-- Icona di spunta verde -->
          </span>
          <span v-else>
            <i class="pi pi-times" style="color: #AC0033;"></i> <!-- Icona di x rossa -->
          </span>
        </template>
      </Column>
      <Column
        style="white-space: pre-wrap"
        field="assignee"
        header="Compilatori"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per compilatore"
          />
        </template>
      </Column>
      <Column
        style="white-space: pre-wrap"
        field="reviewers"
        header="Revisori"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per revisore"
          />
        </template>
      </Column>
      <div v-if="hasFilters">
        Record Filtrati: <span>{{ totalEntries }} </span>
      </div>
    </DataTable>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import {
    onMounted, ref, computed, unref,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Tag from "primevue/tag";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import EntryStatus from "@/enums/entryStatus";

export default {
    name: "Entries",
    components: {
        DataTable,
        Column,
        Button,
        Tag,
        MultiSelect,
        InputText,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const statuses = ref(Object.values(EntryStatus));
        const isUserReviewer = computed(() => store.getters.hasRoleOrUpper("reviewer"));

        const entriesTable = ref();
        const loading = ref(false);
        const filters = ref({
            jmdictId: {
                value: null,
                matchMode: FilterMatchMode.EQUALS,
            },
            kanji: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            readings: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            state: {
                value: null,
                matchMode: FilterMatchMode.IN,
            },
            "meta.version": {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
                    },
                ],
            },
            assignee: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
            reviewers: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
        });
        const queryParams = ref({});
        const totalEntries = ref(0);
        const entries = ref([]);
        const displayNames = (entry) => {
            /*
            const h = [];
            entry.forEach((a) => {
                h.push(a.fullName);
            });
            return h.join("<br>"); */
            const h = [];
            entry.forEach((a) => {
                h.push(a.fullName);
            });
            return h.join("\n");
        };

        const hasFilters = ref(0);

        const renderEntry = (entry) => {
            const isDeleted = !!entry.meta?.deletedAt;
            const hasItalianSenses = entry.senses.some(
                (sense) => sense.lang === "ita",
            );
            const hasPendingReviews = !isEmpty(entry.meta?.pendingReview) ?? false;
            let state;
            if (isDeleted) {
                state = EntryStatus.DELETED;
            } else if (hasItalianSenses) {
                state = EntryStatus.TRANSLATED;
            } else if (hasPendingReviews) {
                state = EntryStatus.REVIEW_PENDING;
            } else {
                state = EntryStatus.TO_TRANSLATE;
            }
            const hasExamples = entry.senses.some(sense => sense.examples && sense.examples.length > 0);
            return {
                _id: entry._id,
                jmdictId: entry.jmdictId,
                kanji: entry.kanji.length > 0 ? entry.kanji[0].term : "-",
                readings: entry.readings.length > 0 ? entry.readings[0].term : "-",
                meta: {
                    version: (entry.meta?.history.length ?? 0) + 1,
                },
                state,
                hasExamples,
                assignee: entry.assignee.length ? displayNames(entry.assignee) : "",
                reviewers: entry.reviewers.length ? displayNames(entry.reviewers) : "",
            };
        };

        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch(
                    "admin/queryEntries",
                    queryParams.value,
                );
                entries.value = response.data.entries.map((entry) => renderEntry(entry));
                totalEntries.value = response.data.totalEntries;
                entries.value[0]
            } catch (err) {
                entries.value = [];
            }
            loading.value = false;
        };

        const onPageChanged = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onFilter = async () => {
            queryParams.value.filters = filters.value;
            await reloadData();

            const d = unref(filters);
            hasFilters.value = 0;
            for (const [value] of Object.entries(d)) {
                if (value != null) {
                    hasFilters.value = 1;
                }
            }
        };
        const onOpenEntry = (entryId) => {
            if (entryId) {
                router.push({ name: "admin.entries.detail", params: { entryId } });
            } else {
                router.push({ name: "admin.entries.new" });
            }
        };

        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: entriesTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();
        });

        return {
            statuses,
            isUserReviewer,
            hasFilters,
            entriesTable,
            loading,
            totalEntries,
            entries,
            filters,
            queryParams,
            onPageChanged,
            onSort,
            onFilter,
            onOpenEntry,
        };
    },
};
</script>
