const searchModule = {
    actions: {
        async searchEntries(context, { query, page = 1 }) {
            return context.rootGetters.getServerConnection.get(`/search/${query}`, {
                params: { q: query, p: page }
            });
        }
    }
};

export default searchModule;
