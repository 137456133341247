function detailFromText(text) {
    return {
        type: "text",
        text,
    };
}

function linkDetail(label, route) {
    return { type: "link", label, route };
}

export default function getNotificationDisplayData(notification) {
    switch (notification.type) {
        case "textual":
            return {
                summary: notification.title,
                severity: "info",
                details: [detailFromText(notification.description)],
                button: undefined,
            };
        case "entryUpdated":
            return {
                summary: "Aggiornamento di un termine",
                severity: "info",
                details: [
                    detailFromText("Il termine "),
                    linkDetail(notification.entryTitle, {
                        name: "entry",
                        params: {
                            slug: notification.entryTitle,
                            entryId: notification.entry,
                        },
                    }),
                    detailFromText(" nella tua watchlist è stato aggiornato."),
                ],
                button: {
                    label: "Visualizza il termine",
                    route: {
                        name: "entry",
                        params: {
                            slug: notification.entryTitle,
                            entryId: notification.entry,
                        },
                    },
                    forNotification: notification,
                },
            };
        case "reviewTodo":
            return {
                summary: "Nuova revisione da svolgere",
                severity: "info",
                details: [
                    detailFromText("L'utente "),
                    linkDetail(notification.userDisplayName, {
                        name: "admin.users.detail",
                        params: { userId: notification.userToReview },
                    }),
                    detailFromText(" ha effettuato una modifica da revisionare."),
                ],
                button: {
                    label: "Svolgi la revisione",
                    route: {
                        name: "admin.entries.detail",
                        params: { entryId: notification.entryToReview },
                    },
                    forNotification: notification,
                },
            };
        case "reviewed":
            return {
                summary: "Nuova revisione",
                severity: notification.isPositive ? "success" : "error",
                details: [
                    notification.isPositive
                        ? detailFromText("Il tuo lavoro è stato revisionato con esito positivo.")
                        : detailFromText(`Il tuo lavoro è stato revisionato con esito negativo. Il revisore ha commentato: "${notification.reason}"`),
                ],
                button: notification.isPositive ? undefined
                    : {
                        label: "Correggi gli errori",
                        route: {
                            name: "admin.entries.detail",
                            params: { entryId: notification.reviewedEntry },
                        },
                        forNotification: notification,
                    },
            };
        case "backofficeUserMisconduct":
            return {
                summary: "Segnalazione di comportamento anomalo",
                severity: "warn",
                details: [
                    detailFromText("L'utente "),
                    linkDetail(notification.misbehavingUserDisplayName, {
                        name: "admin.users.detail",
                        params: { userId: notification.misbehavingUser },
                    }),
                    detailFromText(` ha commesso ${notification.errorsCount} errori!`),
                ],
                button: {
                    label: "Controlla l'utente",
                    route: {
                        name: "admin.users.detail",
                        params: { userId: notification.misbehavingUser },
                    },
                    forNotification: notification,
                },
            };
        case "backofficeUserJobDone":
            return {
                summary: "Termini assegnati terminati",
                severity: "success",
                details: [
                    detailFromText("L'utente "),
                    linkDetail(notification.userDisplayName, {
                        name: "admin.users.detail",
                        params: { userId: notification.user },
                    }),
                    detailFromText(" ha terminato i termini che gli sono stati assegnati."),
                ],
                button: {
                    label: "Assegnane di nuovi",
                    route: {
                        name: "admin.users.detail",
                        params: { userId: notification.user },
                    },
                    forNotification: notification,
                },
            };
        case "newAssignment":
            return {
                summary: "Nuovi termini su cui lavorare",
                severity: "info",
                details: [detailFromText(notification.termsCount === 1
                    ? "Ti è stato assegnato un termine su cui lavorare."
                    : `Ti sono stati assegnati ${notification.termsCount} termini su cui lavorare.`)],
                button: {
                    label: "Vai agli assignment",
                    route: { name: "assignments" },
                    forNotification: notification,
                },
            };
        case "report":
            return {
                summary: "Nuovo errore segnalato",
                severity: "warn",
                details: [
                    detailFromText("È stato segnalato il seguente errore per il termine "),
                    linkDetail(notification.entryTitle, {
                        name: "entry",
                        params: {
                            slug: notification.entryTitle,
                            entryId: notification.entry,
                        },
                    }),
                    detailFromText(`: "${notification.report}"`),
                ],
                button: {
                    label: "Visualizza il termine",
                    route: {
                        name: "admin.entries.detail",
                        params: { entryId: notification.entry },
                    },
                    forNotification: notification,
                },
            };
        default:
            return {
                summary: "Notifica",
                severity: "info",
                details: [],
                button: undefined,
            };
    }
}
