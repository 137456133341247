<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12">
            <JLabelHelpButton :forId="`note-${senseKey}-${itemKey}`"
                              label="Testo*">
                Inserisci qui il testo della nota.
            </JLabelHelpButton>
            <InputText :id="`note-${senseKey}-${itemKey}`"
                       :aria-describedby="boolObjectToString({
                            [`note-${senseKey}-${itemKey}-help`]: true,
                            [`note-${senseKey}-${itemKey}-err`]: v$.text.$error,
                        })"
                       :disabled="disabled" :class="{ 'p-invalid': v$.text.$error }"
                       type="text" v-model="v$.text.$model"/>
            <small class="p-error" :id="`note-${senseKey}-${itemKey}-err`"
                   v-if="v$.text.$error">
                Il campo è obbligatorio.
            </small>
        </div>
    </div>
</template>

<script>
import { reactive, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JSenseNoteEditor",
    components: {
        InputText,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        senseKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const rules = {
            text: { required },
        };

        const v$ = useVuelidate(rules, item);

        return {
            v$,
            item,
            boolObjectToString,
        };
    },
};
</script>
