import { isArray, isObject } from "lodash";
import OrthographicInfo from "@/enums/orthographicInfo";
import ReadingInfo from "@/enums/readingInfo";
import Priority from "@/enums/priority";
import Dialect from "@/enums/dialect";
import LanguageCode from "@/enums/iso639";
import MiscellaneousEntryInfo from "@/enums/misc";
import PartOfSpeech from "@/enums/partOfSpeech";
import GlossType from "@/enums/glossType";

const opNames = {
    add: "aggiunto",
    remove: "rimosso",
    replace: "cambiato",
};

const firstLevelNames = {
    kanji: "Scritture",
    readings: "Letture",
    senses: "Campi semantici",
};

const kanjiNames = {
    term: "Scrittura",
    orthographicInfo: "Informazioni ortografiche",
    priorities: "Priorità",
};

const readingsNames = {
    term: "Lettura",
    romaji: "Traslitterazione Hepburn modificato",
    noKanji: "Slegato a una scrittura?",
    restrict: "Validità sulle scritture",
    readingInfo: "Informazioni sulla lettura",
    priorities: "Priorità",
};

const sensesNames = {
    lang: "Lingua",
    glosses: "Definizioni",
    notes: "Note aggiuntive",
    examples: "Esempi",
    appliesToKanji: "Vale per le scritture",
    appliesToReadings: "Vale per le letture",
    crossReferences: "Termini correlati",
    antonyms: "Antonimie",
    partsOfSpeech: "Parti del discorso",
    misc: "Informazioni aggiuntive",
    tags: "Tag",
    sourceTerms: "Termini sorgente",
    dialects: "Dialetti",
};

const glossesNames = {
    term: "Definizione",
    type: "Tipo di definizione",
};

const notesNames = {
    note: "Nota",
};

const examplesNames = {
    original: "Giapponese",
    translation: "Tradotto",
};

const sourceTermsNames = {
    lang: "Lingua del termine sorgente",
    type: "Livello di descrizione del termine",
    waseieigo: "È waseieigo?",
    term: "Termine sorgente",
};

const crossReferencesNames = {
    entry: "Termine",
    kanji: "Scrittura",
    reading: "Lettura",
    sense: "Campo semantico",
};

function prettyPath(path) {
    let currentNames = firstLevelNames;
    let result = "";
    // Consume each part and build a string
    path.forEach((part) => {
        if (currentNames[part]) {
            result += `${currentNames[part]} / `;
            // See if we need to change the current names
            // eslint-disable-next-line default-case
            switch (part) {
                case "kanji": {
                    currentNames = kanjiNames;
                    break;
                }
                case "readings": {
                    currentNames = readingsNames;
                    break;
                }
                case "senses": {
                    currentNames = sensesNames;
                    break;
                }
                case "glosses": {
                    currentNames = glossesNames;
                    break;
                }
                case "notes": {
                    currentNames = notesNames;
                    break;
                }
                case "crossReferences":
                case "antonyms": {
                    currentNames = crossReferencesNames;
                    break;
                }
                case "sourceTerms": {
                    currentNames = sourceTermsNames;
                    break;
                }
                case "examples": {
                    currentNames = examplesNames;
                    break;
                }
            }
        } else if (!Number.isNaN(Number(part))) {
            const base = 10;
            const index = parseInt(part, base);
            result += `Elemento ${index + 1} / `;
        }
    });
    // Remove the trailing excess " / " from the resulting string
    const separatorLength = 3;
    return result.slice(0, -separatorLength);
}

function isValueDisplayable(path, value) {
    let [lastPart] = path.slice(-1);
    // Never show IDs
    if (lastPart === "_id") {
        return false;
    }
    // The part we're interested in could be the last (in case of array) or second to last
    // (in case the array is edited, then the last part is the index)
    if (!Number.isNaN(Number(lastPart))) {
        [lastPart] = path.slice(-2);
    }
    // Never show paths that contain IDs
    const lastPathsContainingIDs = [
        "restrict",
        "appliesToKanji",
        "appliesToReadings",
        "tags",
    ];
    if (lastPathsContainingIDs.includes(lastPart)) {
        return false;
    }
    // We can display dates even though they're objects
    if (value instanceof Date) {
        return true;
    }
    return isArray(value) || !isObject(value);
}

function prettyValue(path, value) {
    if (!isValueDisplayable(path, value)) {
        return "valore";
    }
    if (typeof value === "boolean") {
        return value ? "sì" : "no";
    }
    if (value instanceof Date) {
        return value.toISOString();
    }
    const listify = (obj) => (Array.isArray(obj) ? obj : [obj]);
    const joinLabelValueArray = (arr, values) => {
        const joined = Object.values(arr)
            .filter((elem) => listify(values).includes(elem.value))
            .map((elem) => elem.label)
            .join(", ");
        return `"${joined}"`;
    };
    let [lastPart] = path.slice(-1);
    // The part we're interested in could be the last (in case of array) or second to last
    // (in case the array is edited, then the last part is the index)
    if (!Number.isNaN(Number(lastPart))) {
        [lastPart] = path.slice(-2);
    }
    switch (lastPart) {
        case "orthographicInfo": {
            return joinLabelValueArray(OrthographicInfo, value);
        }
        case "readingInfo": {
            return joinLabelValueArray(ReadingInfo, value);
        }
        case "priorities": {
            return joinLabelValueArray(Priority, value);
        }
        case "dialects": {
            return joinLabelValueArray(Dialect, value);
        }
        case "lang": {
            return Object.values(LanguageCode)
                .find((lang) => lang.value === value)
                ?.label ?? "Indeterminata";
        }
        case "misc": {
            return joinLabelValueArray(MiscellaneousEntryInfo, value);
        }
        case "partsOfSpeech": {
            return joinLabelValueArray(PartOfSpeech, value);
        }
        case "type": {
            if (path.includes("sourceTerms")) {
                return value === "full" ? "Totale" : "Parziale";
            }
            if (path.includes("glosses")) {
                return Object.values(GlossType)
                    .find((lang) => lang.value === value)
                    ?.label ?? "Sconosciuto";
            }
            break;
        }
        default: {
            if (typeof value === "string") {
                return `"${value}"`;
            }
        }
    }
    return "valore";
}

export default function prettyCommit({ op, path, value }) {
    // Every path starts with / so ignore the first character
    const splitPath = path.slice(1).split("/");
    const pathName = prettyPath(splitPath);
    const opName = opNames[op];
    const valueName = value !== undefined ? ` ${prettyValue(splitPath, value)}` : "";
    const addInReplace = op === "replace" && isValueDisplayable(path, value);
    return `${pathName}: ${opName}${addInReplace ? " in" : ""}${valueName}`;
}
