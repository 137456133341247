<template>
    <div class="p-d-flex p-ai-center">
        <h1>Tag</h1>
        <div class="p-ml-auto">
            <Button label="Aggiungi tag" icon="pi pi-plus" class="p-button-success p-m-1"
                    @click="onOpenTag()"/>
        </div>
    </div>
    <DataTable ref="tagsTable" :loading="loading" :value="tags" :lazy="true" :paginator="true"
               :totalRecords="totalTags" dataKey="_id"
               responsiveLayout="scroll" :row-hover="true"
               v-model:filters="filters" filter-display="menu"
               :rows="10" :rows-per-page-options="[10, 25, 50, 100]"
               @page="onPageChanged" @sort="onSort" @filter="onFilter">
        <Column :exportable="false" v-if="isUserAdmin">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-raised"
                        aria-label="Modifica" @click="onOpenTag(slotProps.data._id)"/>
            </template>
        </Column>
        <Column field="name" header="Nome" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per nome"/>
            </template>
        </Column>
        <Column field="prettyName" header="Nome completo" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per nome completo"/>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
    name: "Tags",
    components: {
        DataTable,
        Column,
        Button,
        InputText,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const isUserAdmin = computed(() => store.getters.hasRoleOrUpper("admin"));

        const tagsTable = ref();
        const loading = ref(false);
        const filters = ref({
            name: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            prettyName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
        });
        const queryParams = ref({});
        const totalTags = ref(0);
        const tags = ref([]);

        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch("admin/queryTags", queryParams.value);
                tags.value = response.data.tags;
                totalTags.value = response.data.totalTags;
            } catch (err) {
                tags.value = [];
            }
            loading.value = false;
        };

        const onPageChanged = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onFilter = async () => {
            queryParams.value.filters = filters.value;
            await reloadData();
        };
        const onOpenTag = (tagId) => {
            if (tagId) {
                router.push({ name: "admin.tags.detail", params: { tagId } });
            } else {
                router.push({ name: "admin.tags.new" });
            }
        };

        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: tagsTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();
        });

        return {
            isUserAdmin,
            tagsTable,
            loading,
            totalTags,
            tags,
            filters,
            queryParams,
            onPageChanged,
            onSort,
            onFilter,
            onOpenTag,
        };
    },
};
</script>
