const statsModule = {
    actions: {
        async getEntriesStats(context) {
            return context.rootGetters.getServerConnection.get("/admin/stats/entries");
        },
        async getUsersStats(context) {
            return context.rootGetters.getServerConnection.get("/admin/stats/users");
        },
        async getTagsStats(context) {
            return context.rootGetters.getServerConnection.get("/admin/stats/tags");
        },
        async getCollaboratorsStats(context) {
            return context.rootGetters.getServerConnection.get("/admin/stats/collaborators");
        },
        async getAssignmentsStats(context, userId) {
            return context.rootGetters.getServerConnection.get(`/admin/stats/assignments/${userId}`);
        },
    },
};

export default statsModule;
