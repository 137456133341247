<template>
    <template v-if="!loading && error === null">
        <Message severity="error" :closable="false" v-if="!submissionSuccess">
            Si è verificato un errore durante l'aggiornamento.
        </Message>
        <Message severity="success" v-if="isSubmitted && submissionSuccess">
            Utente aggiornato.
        </Message>
        <TabView>
            <TabPanel header="Dettagli">
                <h1 class="p-d-flex p-ai-center">{{ user.firstName }} {{ user.lastName }}</h1>
                <Message severity="warn" v-if="isUserRoleNotModifiable">
                    Per poter modificare il ruolo di questo utente è necessario che lui inserisca
                    <strong>Regione</strong>, <strong>Università</strong> e
                    <strong>Matricola</strong> all'interno delle informazioni del suo profilo.
                </Message>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 ">
                        <label for="id" class="form-label p-text-bold">ID dell'utente</label>
                        <InputText id="id" type="text" :value="user._id" disabled />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="firstName" class="form-label p-text-bold">Nome</label>
                        <InputText id="firstName" type="text" :value="user.firstName" disabled />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="lastName" class="form-label p-text-bold">Cognome</label>
                        <InputText id="lastName" type="text" :value="user.lastName" disabled />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="role" class="form-label p-text-bold">Ruolo</label>
                        <Dropdown id="role" v-model="v$.role.$model" :options="roles"
                                  optionValue="value" optionLabel="label"
                                  :class="{ 'p-invalid': v$.role.$error }"
                                  :disabled="isSelfUserPage || isUserRoleNotModifiable"
                                  :aria-describedby="v$.role.$error"/>
                        <small class="p-error" id="role-err" v-if="v$.role.$error">
                            Il campo è obbligatorio.
                        </small>
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="user.role !== 'user'">
                        <label for="role" class="form-label p-text-bold">Punteggio</label>
                        <InputNumber id="stacked" v-model="v$.score.$model" showButtons
                                     :disabled="user.role === 'admin'" :min="0"
                                     :class="{ 'p-invalid': v$.score.$error }"
                                     :aria-describedby="v$.score.$error"/>
                        <small class="p-error" id="score-err" v-if="v$.score.$error">
                            Il campo è obbligatorio.
                        </small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="lastLoginAt" class="form-label p-text-bold">
                            Ultimo login
                        </label>
                        <InputText id="lastLoginAt" type="text"
                                   :value="renderDate(user.lastLoginAt)" disabled />
                    </div>
                    <div class="p-field p-col-12 p-md-6" v-if="user.role !== 'user'">
                        <label for="lastBackOfficeLoginAt" class="form-label p-text-bold">
                            Ultimo accesso al backend
                        </label>
                        <InputText id="lastBackOfficeLoginAt" type="text"
                                   :value="user.lastBackOfficeLoginAt ?
                                   renderDate(user.lastBackOfficeLoginAt) : 'Nessuno'" disabled />
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="region" class="form-label p-text-bold">Regione</label>
                        <Dropdown :options="regions" optionLabel="name" optionValue="value"
                                  placeholder="Nessuna regione specificata"
                                  :disabled="true" id="region"
                                  :filter="true" v-model="user.region"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="university" class="form-label p-text-bold">Università</label>
                        <Dropdown :options="universities" optionLabel="name" optionValue="name"
                                  placeholder="Nessuna università specificata"
                                  :disabled="true" id="university"
                                  :filter="true" v-model="user.university"/>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="badgeNumber" class="form-label p-text-bold">Matricola</label>
                        <InputText id="badgeNumber" v-model="user.badgeNumber" :disabled="true"
                               placeholder="Nessuna matricola specificata" :useGrouping="false"/>
                    </div>
                </div>
                <div class="p-d-flex p-dir-rev p-mt-3">
                    <Button label="Salva" icon="pi pi-save" class="p-ml-2 p-button-success"
                            :loading="submissionLoading" @click.prevent="onSave"
                            :disabled="(initialRole === v$.role.$model
                                        && v$.score.$model === initialScore)
                                        || v$.score.$error"/>
                    <Button label="Elimina" icon="pi pi-trash" class="p-button-danger"
                            :loading="submissionLoading"
                            @click.prevent="onDelete" :disabled="isSelfUserPage"/>
                </div>
                <Divider />
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <h2>Traduzioni</h2>
                        <div v-if="user.numberOfTranslations === 0" class="form-label">
                            <p>L'utente non ha effettuato traduzioni</p>
                        </div>
                        <j-profile-statistics v-else :user-role="user.role" :user-stats="user"/>
                    </div>
                    <Divider />
                    <div class="p-field p-col-12 p-md-6">
                        <p class="form-label">
                            <span class="p-text-bold">
                                Numero di errori nei termini segnalati dall'utente:
                            </span>
                              {{ user.numberOfReportedErrors }}
                        </p>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Assignment" v-if="user.role === 'compiler'">
                <j-user-assignments-table :user="user" />
            </TabPanel>
            <TabPanel header="Storia" v-if="user.role === 'compiler' || user.role === 'reviewer'">
                <Timeline :value="history" :align="'alternate'" class="history-timeline"
                        v-if="history.length > 0">
                    <template #marker="slotProps">
                        <span class="history-status-marker p-shadow-2"
                              :class="slotProps.item.commit.reviewStatus ?? 'pending'"></span>
                    </template>
                    <template #content="slotProps">
                        <JUserHistoryCard :history-item="slotProps.item" />
                    </template>
                </Timeline>
                <div v-else class="p-text-center p-mt-2">
                    L'utente non ha effettuato operazioni nel backend.
                </div>
            </TabPanel>
        </TabView>
    </template>
    <template v-else-if="error !== null">
        <h1 class="error-title">{{ error.title }}</h1>
        <p class="error-text">{{ error.text }}</p>
    </template>
</template>

<script>
import {
    onMounted, reactive, ref, computed,
} from "vue";
import { useStore } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import TabView from "primevue/tabview";
import Timeline from "primevue/timeline";
import TabPanel from "primevue/tabpanel";
import InputNumber from "primevue/inputnumber";
import { useConfirm } from "primevue/useconfirm";
import Message from "primevue/message";
import renderDate from "@/utils/prettyDate";
import { useRouter } from "vue-router";
import JProfileStatistics from "@/components/JProfileStatistics.vue";
import UserRole from "@/enums/userRole";
import JUserAssignmentsTable from "@/components/admin/user/JUserAssignmentsTable.vue";
import Universities from "@/enums/universities";
import Regions from "@/enums/regions";
import JUserHistoryCard from "@/components/admin/user/JUserHistoryCard.vue";

export default {
    name: "UserDetail",
    components: {
        JUserHistoryCard,
        JUserAssignmentsTable,
        JProfileStatistics,
        Button,
        InputText,
        Message,
        Divider,
        Timeline,
        Dropdown,
        InputNumber,
        TabView,
        TabPanel,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const confirm = useConfirm();

        const store = useStore();
        const router = useRouter();
        const user = reactive({
            _id: "",
            email: "",
            firstName: "",
            lastName: "",
            role: "",
            score: 0,
            numberOfTranslations: 0,
            numberOfApprovedTranslations: 0,
            numberOfRefusedTranslations: 0,
            numberOfReviewedTranslations: 0,
            numberOfReportedErrors: 0,
            lastLoginAt: "",
            lastBackOfficeLoginAt: "",
            region: "",
            university: "",
            badgeNumber: null,
        });
        const userRules = {
            role: { required },
            score: { required },
        };
        const v$ = useVuelidate(userRules, user);
        const error = ref(null);
        const loading = ref(true);
        const isSubmitted = ref(false);
        const submissionSuccess = ref(true);
        const submissionLoading = ref(false);
        const loggedUser = computed(() => store.getters.loggedUser);
        const isSelfUserPage = computed(() => user._id === loggedUser.value?._id);
        const roles = ref(Object.values(UserRole));
        const initialRole = ref("");
        const initialScore = ref(0);
        const deleteConfirmVisible = ref(false);
        const isUserRoleNotModifiable = ref(false);

        const universities = ref(Object.values(Universities));
        const regions = ref(Object.values(Regions));

        const onSave = async () => {
            isSubmitted.value = true;
            if (v$.value.$errors.length === 0) {
                try {
                    submissionLoading.value = true;
                    await store.dispatch("admin/updateUser", {
                        userId: props.userId,
                        user,
                    });
                    submissionSuccess.value = true;
                } catch (err) {
                    submissionSuccess.value = false;
                } finally {
                    submissionLoading.value = false;
                }
            }
        };
        const onConfirmDelete = async () => {
            isSubmitted.value = true;
            try {
                submissionLoading.value = true;
                await store.dispatch("deleteUser", user.email);
                await router.replace({ name: "admin.users" });
                submissionSuccess.value = true;
            } catch (err) {
                submissionSuccess.value = false;
            } finally {
                submissionLoading.value = false;
                confirm.close();
            }
        };

        const onDelete = async () => {
            confirm.require({
                message: "Sei sicuro di voler eliminare questo utente? "
                    + "L'operazione non è reversibile",
                header: "Conferma cancellazione",
                icon: "pi pi-exclamation-triangle",
                accept: () => onConfirmDelete(),
                reject: () => confirm.close(),
            });
        };

        const history = ref();

        onMounted(async () => {
            loading.value = true;
            error.value = null;
            isSubmitted.value = false;
            submissionSuccess.value = true;
            try {
                error.value = null;
                isSubmitted.value = false;
                submissionSuccess.value = true;
                const result = await store.dispatch("admin/getUser", props.userId);
                user._id = result.data._id;
                user.email = result.data.email;
                user.firstName = result.data.firstName;
                user.lastName = result.data.lastName;
                user.role = result.data.role;
                initialRole.value = user.role;
                user.numberOfTranslations = result.data.numberOfTranslations;
                user.numberOfApprovedTranslations = result.data.numberOfApprovedTranslations;
                user.numberOfRefusedTranslations = result.data.numberOfRefusedTranslations;
                user.numberOfReviewedTranslations = result.data.numberOfReviewedTranslations;
                user.numberOfReportedErrors = result.data.numberOfReportedErrors;
                user.lastLoginAt = result.data.lastLoginAt;
                user.lastBackOfficeLoginAt = result.data.lastBackOfficeLoginAt ?? "";
                user.score = result.data.score;
                user.region = result.data.region;
                user.badgeNumber = result.data.badgeNumber;
                user.university = result.data.university;
                initialScore.value = user.score;
                if (!result.data.confirmed || user.university === undefined
                        || user.region === undefined || user.badgeNumber === undefined) {
                    isUserRoleNotModifiable.value = true;
                }
            } catch (err) {
                error.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore durante il recupero dell'utente!",
                };
            }

            try {
                const response = await store.dispatch("admin/getHistory", props.userId);
                history.value = response.data;
            } catch (err) {
                error.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore durante il recupero della storia!",
                };
            } finally {
                loading.value = false;
            }
        });
        return {
            v$,
            user,
            roles,
            initialRole,
            initialScore,
            isSelfUserPage,
            error,
            loading,
            isSubmitted,
            submissionSuccess,
            submissionLoading,
            isUserRoleNotModifiable,
            regions,
            universities,
            history,
            onSave,
            onDelete,
            onConfirmDelete,
            deleteConfirmVisible,
            renderDate,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables";
    .p-divider-solid.p-divider-horizontal:before {
        border-top-style: solid !important;
    }
    .p-divider-dashed.p-divider-horizontal:before {
        border-top-style: dashed !important;
    }
    .form-label {
        margin-bottom: 0.5rem;
        color: #495057;
    }
    .history-status-marker {
        display: flex;
        width: 1rem;
        height: 1rem;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;
        z-index: 1;

        &.approved, &.created {
            background-color: $successButtonBg;
        }
        &.pending {
            background-color: #ffffff;
            border: 2px solid $warningButtonBg;
        }
        &.denied {
            background-color: $dangerButtonBg;
        }
        &.deleted {
            background-color: $secondaryButtonBg;
        }
    }
</style>
