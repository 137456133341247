const AssignmentStatus = {
    ASSIGNED: {
        label: "Da Svolgere",
        value: "assigned",
        cssClass: "entry-status-deleted",
    },
    REVIEWING: {
        label: "In revisione",
        value: "reviewing",
        cssClass: "entry-status-review-pending",
    },
    REFUSED: {
        label: "Rifiutato",
        value: "refused",
        cssClass: "entry-status-to-translate",
    },
    COMPLETED: {
        label: "Completato",
        value: "completed",
        cssClass: "entry-status-translated",
    },
};
Object.freeze(AssignmentStatus);
export default AssignmentStatus;
