const UserRole = {
    ADMIN: {
        label: "Amministratore",
        value: "admin",
    },
    REVIEWER: {
        label: "Revisore",
        value: "reviewer",
    },
    COMPILER: {
        label: "Compilatore",
        value: "compiler",
    },
    USER: {
        label: "Utente",
        value: "user",
    },
};
Object.freeze(UserRole);
export default UserRole;
