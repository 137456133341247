<template>
    <Dialog header="Esporta come mazzo Anki" :visible="visible" :modal="true"
            @update:visible="$emit('update:visible', $event)"
            :dismissableMask="true" @hide="updateIsVisible(false)">
        <p class="p-mt-0">
            La tua collezione verrà scaricata come mazzo di carte da importare su
             <a href="https://apps.ankiweb.net">Anki</a> per poterle memorizzare.
            <br/>
            Scegli quali campi verranno mostrati sul fronte e quali sul retro delle carte.
        </p>
        <form :id="`anki${collection._id}`">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="frontFields" class="form-label p-text-bold">
                        Fronte della carta
                    </label>
                    <div id="frontFields" class="p-multiselect-panel p-component">
                        <div class="p-multiselect-items-wrapper">
                            <ul class="p-multiselect-items p-component">
                                <li v-for="field in fields" :key="`front-${field.label}`"
                                    :class="{
                                        'p-highlight': frontFields.indexOf(field.label) >= 0
                                    }"
                                    class="p-multiselect-item">
                                    <Checkbox name="collection" :value="field.label"
                                              v-model="frontFields" />
                                    {{ field.value }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="backFields" class="form-label p-text-bold">
                        Retro della carta
                    </label>
                    <div id="backFields" class="p-multiselect-panel p-component">
                        <div class="p-multiselect-items-wrapper">
                            <ul class="p-multiselect-items p-component">
                                <li v-for="field in fields" :key="`back-${field.label}`"
                                    :class="{
                                        'p-highlight': backFields.indexOf(field.label) >= 0
                                    }"
                                    class="p-multiselect-item">
                                    <Checkbox name="collection" :value="field.label"
                                              v-model="backFields" />
                                    {{ field.value }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <template #footer>
            <Button type="Submit" label="Scarica" icon="pi pi-download" class="p-button-secondary"
                    @click.prevent="downloadCollection" :form="`anki${collection._id}`"
                    :disabled="frontFields.length <= 0 || backFields.length <= 0"/>
        </template>
    </Dialog>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import AllowedAnkiFields from "@/enums/allowedAnkiFields";

export default {
    name: "JCollectionDownloadDialog",
    components: { Button, Checkbox, Dialog },
    props: {
        collection: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
        },
    },
    emit: ["update:visible"],
    setup(props, { emit }) {
        const updateIsVisible = (value) => emit("update:visible", value);

        const fields = ref(AllowedAnkiFields);
        const frontFields = ref([]);
        const backFields = ref([]);

        const store = useStore();
        const downloadCollection = async () => {
            await store.dispatch("downloadCollection", {
                collectionId: props.collection._id,
                frontFields: frontFields.value,
                backFields: backFields.value,
            });
        };

        return {
            updateIsVisible,
            fields,
            frontFields,
            backFields,
            downloadCollection,
        };
    },
};
</script>

<style scoped lang="scss">
.p-multiselect-panel {
    display: block;
    position: static;
    border-radius: 0;
    border: none;
    box-shadow: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 30vh;
    overflow-y: auto;
}
</style>
