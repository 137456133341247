<template>
    <slot v-if="!!$slots.title" name="title"></slot>
    <slot name="description" v-if="!!$slots.description"></slot>
    <transition-group ref="list" name="orderlist-flip" tag="ul" class="orderlist-list"
                      role="listbox" aria-multiselectable="false" v-if="nonHiddenItems.length > 0">
        <template v-for="(item, i) of items">
            <li tabindex="0" role="option" class="item-card p-mb-2" :key="getKey(item, i)"
                v-if="!isHidden(item, i)">
                <div class="p-d-flex p-flex-wrap-reverse p-ai-center">
                    <slot name="header" :item="item" :index="i"></slot>
                    <div class="p-ml-auto" v-if="!isLocked(item, i) && !disabled">
                        <Button
                            aria-label="Muovi su" icon="pi pi-angle-up" class="p-m-1"
                            :disabled="!canMoveUp(item, i)"
                            @click="onMoveUp($event, item, i)"/>
                        <Button
                            aria-label="Muovi giù" icon="pi pi-angle-down" class="p-m-1"
                            :disabled="!canMoveDown(item, i)"
                            @click="onMoveDown($event, item, i)"/>
                        <Button
                            aria-label="Rimuovi" icon="pi pi-trash" class="p-button-danger p-m-1"
                            :disabled="items.length <= minItems || isLocked(item, i)"
                            @click="onRemove($event, item, i)"/>
                    </div>
                </div>
                <slot name="item" :item="item" :key="getKey(item, i)" :index="i"></slot>
            </li>
        </template>
    </transition-group>
    <template v-else>
        <p class="no-items" v-if="!disabled">Nessun elemento presente. Aggiungine uno!</p>
        <p class="no-items" v-else>Nessun elemento presente.</p>
    </template>
    <div class="p-d-flex p-dir-rev" v-if="enableInsert">
        <Button :label="addButtonLabel" icon="pi pi-plus" class="p-button-success p-m-1"
                :disabled="disabled" @click="onAdd"/>
    </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useConfirm } from "primevue/useconfirm";
import Button from "primevue/button";

export default {
    name: "JOrderList",
    components: {
        Button,
    },
    props: {
        modelValue: {
            type: Array,
        },
        addButtonLabel: {
            type: String,
            default: "Aggiungi",
        },
        emptyItem: {
            type: Object,
        },
        enableInsert: {
            type: Boolean,
            default: true,
        },
        keyField: {
            type: String,
        },
        minItems: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        lockedKeys: {
            type: Array,
            default() {
                return [];
            },
        },
        hiddenKeys: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ["emptyItemAdded", "itemRemoved", "update:modelValue"],
    setup(props, { emit }) {
        const confirm = useConfirm();

        const nextKey = ref(0);
        const itemsKeys = ref(props.modelValue.map(() => {
            const key = nextKey.value;
            nextKey.value += 1;
            return key;
        }));
        const items = ref(props.modelValue);
        const isInternalChange = ref(false);
        watch(props.modelValue, (newModelValue) => {
            if (!isInternalChange.value) {
                isInternalChange.value = false;
                if (items.value.length !== newModelValue.length) {
                    itemsKeys.value = newModelValue.map(() => {
                        const key = nextKey.value;
                        nextKey.value += 1;
                        return key;
                    });
                }
                items.value = newModelValue;
            }
        });

        const getKey = (item, index) => (props.keyField !== undefined
            ? item[props.keyField]
            : `key-${itemsKeys.value[index]}`);
        const isHidden = (item, index) => props.hiddenKeys.includes(getKey(item, index));
        const isLocked = (item, index) => props.lockedKeys.includes(getKey(item, index))
            || isHidden(item, index);
        const getPreviousIndex = (item, index) => {
            if (index === 0) {
                return null;
            }
            if (!isLocked(items.value[index - 1], index - 1)) {
                return index - 1;
            }
            // Find the last non-locked item before the specified one
            const nonLocked = items.value
                .map((elem, elemIndex) => ({ elem, elemIndex }))
                .filter(({ elem, elemIndex }) => !isLocked(elem, elemIndex))
                .filter(({ elemIndex }) => elemIndex < index);
            return nonLocked.length > 0 ? nonLocked[nonLocked.length - 1].elemIndex : null;
        };
        const getNextIndex = (item, index) => {
            if (index === items.value.length - 1) {
                return null;
            }
            if (!isLocked(items.value[index + 1], index + 1)) {
                return index + 1;
            }
            // Find the first non-locked item after the specified one
            const nonLocked = items.value
                .map((elem, elemIndex) => ({ elemIndex, elem }))
                .filter(({ elem, elemIndex }) => !isLocked(elem, elemIndex))
                .filter(({ elemIndex }) => elemIndex > index);
            return nonLocked.length > 0 ? nonLocked[0].elemIndex : null;
        };
        const canMoveUp = (item, index) => !props.disabled
            && getPreviousIndex(item, index) !== null;
        const canMoveDown = (item, index) => !props.disabled
            && getNextIndex(item, index) !== null;

        const nonHiddenItems = computed(() => items.value
            .filter((item, index) => !isHidden(item, index)));

        const onAdd = () => {
            // Dirty trick to create an immutable copy of the emptyItem prop
            // (which otherwise would be mutable)
            const frozenEmptyItem = JSON.parse(JSON.stringify(props.emptyItem));
            items.value.push(frozenEmptyItem);
            itemsKeys.value.push(nextKey.value);
            nextKey.value += 1;
            isInternalChange.value = true;
            emit("update:modelValue", items.value);
            emit("emptyItemAdded", frozenEmptyItem);
        };
        const onRemove = (event, item, index) => {
            confirm.require({
                message: "Vuoi cancellare questo elemento?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                accept() {
                    items.value.splice(index, 1);
                    itemsKeys.value.splice(index, 1);
                    isInternalChange.value = true;
                    emit("itemRemoved", item);
                    emit("update:modelValue", items.value);
                },
            });
        };
        const onMoveUp = (event, item, index) => {
            items.value[index] = items.value[index - 1];
            items.value[index - 1] = item;
            const currentKey = itemsKeys.value.indexOf(index);
            itemsKeys.value[index] = itemsKeys.value[index - 1];
            itemsKeys.value[index - 1] = currentKey;
            isInternalChange.value = true;
            emit("update:modelValue", items.value);
        };
        const onMoveDown = (event, item, index) => {
            items.value[index] = items.value[index + 1];
            items.value[index + 1] = item;
            const currentKey = itemsKeys.value.indexOf(index);
            itemsKeys.value[index] = itemsKeys.value[index + 1];
            itemsKeys.value[index + 1] = currentKey;
            isInternalChange.value = true;
            emit("update:modelValue", items.value);
        };

        return {
            items,
            itemsKeys,
            getKey,
            isHidden,
            isLocked,
            canMoveUp,
            canMoveDown,
            nonHiddenItems,
            onAdd,
            onRemove,
            onMoveUp,
            onMoveDown,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    .orderlist-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    .orderlist-flip-move {
        transition: transform 0.25s ease;
    }
    .no-items {
        text-align: center;
        font-size: 1.25rem;
    }
    .item-card {
        background: $panelContentBg;
        color: $panelContentTextColor;
        box-shadow: $cardShadow;
        border-radius: $borderRadius;
        padding: $cardBodyPadding;
    }
</style>
