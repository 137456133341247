<template>
    <div>
        <Message severity="error" :closable="false"
                 v-if="showStatsError">
            Si è verificato un problema nello scaricare le statistiche degli assignment,
            prova a ricaricare la pagina
        </Message>
        <Message severity="error" :closable="false"
                 v-if="isAssignmentSubmitted && !submitAssignmentSuccess">
            Si è verificato un errore, aggiorna la pagina e riprova
        </Message>
        <Message severity="success" v-if="isAssignmentSubmitted && submitAssignmentSuccess">
            Assignment inviato con successo
        </Message>
        <Message severity="success" v-if="isSubmitted && submissionSuccess">
            Assignment eliminato
        </Message>
        <Dialog header="Scegli un numero di traduzioni da assegnare all'utente"
                v-model:visible="showAssignToUserDialog"
                :modal="true">
            <div class="p-grid p-jc-center p-mt-2">
                <InputNumber id="stacked" v-model="numberOfEntries" showButtons :min="1" :max="5000"
                mode="decimal"/>
            </div>
            <template #footer>
                <Button label="Cancella" icon="pi pi-times" @click="closeAssignToUserDialog"
                        class="p-button-text"/>
                <Button label="Invia" icon="pi pi-check" @click="sendAssignToUserDialog" autofocus
                        :disabled="numberOfEntries > 5000" :loading="isAssignmentLoading"/>
            </template>
        </Dialog>
        <div class="p-my-3" v-if="isUserAdmin">
            <div class="p-grid p-jc-center">
                <Button label="Assegna traduzioni" icon="pi pi-plus"
                        class="p-button-primary"
                        :loading="assignmentLoading"
                        @click="onAssign" v-if="isUserAdmin" />
            </div>
            <p v-if="stats.total > 1">
                Questo utente ha <strong>{{ stats.total }}</strong> termini assegnati.
            </p>
            <p v-else-if="stats.total === 1">
                Questo utente ha un termine assegnato
            </p>
        </div>
        <div class="p-my-3" v-else>
            <p v-if="stats.total > 1">Hai <strong>{{ stats.total }}</strong> termini assegnati.</p>
            <p v-else-if="stats.total === 1">Hai un termine assegnato.</p>
        </div>
        <p v-if="stats.total > 1">
            Di questi, <strong>{{ stats.assigned }}</strong>
            <span v-if="stats.assigned === 1"> è</span>
            <span v-else> sono</span> da tradurre,
            <strong>{{ stats.reviewing }}</strong>
            <span v-if="stats.reviewing === 1"> è</span>
            <span v-else> sono</span> in fase di revisione,
            <strong>{{ stats.completed }}</strong>
            <span v-if="stats.completed === 1"> è stato tradotto</span>
            <span v-else> sono stati tradotti</span> con successo e
            <strong>{{ stats.refused }}</strong>
            <span v-if="stats.refused === 1"> termine è stato rifiutato</span>
            <span v-else> termini sono stati rifiutati</span>.</p>
        <DataTable ref="assignmentsTable" :loading="loading" :value="assignments" :lazy="true"
                   :paginator="true" :totalRecords="totalAssignments" dataKey="_id"
                   responsiveLayout="scroll" :row-hover="true"
                   v-model:filters="filters" filter-display="menu"
                   :rows="10" :rows-per-page-options="[10, 25, 50, 100]"
                   @page="onPageChanged" @sort="onSort" @filter="onFilter">
            <template #empty>
                Non ci sono assignment
            </template>
            <template #loading>
                Caricamento degli assignment in corso. Attendi.
            </template>
            <Column :exportable="false">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-raised"
                            aria-label="Visualizza termine"
                            @click="onOpenEntry(slotProps.data.entryId)"/>
                </template>
            </Column>
            <Column :exportable="false">
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-raised"
                            aria-label="Visualizza termine" v-if="isUserAdmin"
                            @click="onDeleteAssignment(slotProps.data._id)"/>
                </template>
            </Column>
            <Column field="kanji" header="Termine" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.entry.kanji.length > 0 ?
                    slotProps.data.entry.kanji[0].term : slotProps.data.entry.readings[0].term }}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value"
                               class="p-column-filter" placeholder="Cerca per termine"/>
                </template>
            </Column>
            <Column field="fullName" header="Assegnato da" sortable>
                <template #body="slotProps">
                    {{ slotProps.data.fullName }}
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value"
                               class="p-column-filter" placeholder="Cerca per assegnatore"/>
                </template>
            </Column>
            <Column field="assignedAt" header="Data" sortable dataType="date">
                <template #body="slotProps">
                    {{ renderDate(slotProps.data.assignedAt) }}
                </template>
                <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy" />
                </template>
            </Column>
            <Column field="state" header="Stato"
                    :showFilterOperator="false"
                    :showFilterMatchModes="false">
                <template #body="slotProps">
                    <Tag :class="slotProps.data.state.cssClass">
                        {{ slotProps.data.state.label }}
                    </Tag>
                </template>
                <template #filter="{filterModel}">
                    <MultiSelect v-model="filterModel.value" :options="statuses" optionLabel="label"
                             optionValue="value" placeholder="Qualunque" class="p-column-filter">
                    </MultiSelect>
                </template>
            </Column>
            <Column field="completedAt" header="Data completamento" sortable dataType="date">
                <template #body="slotProps">
                    {{ slotProps.data.state.value === "completed" ?
                    renderDate(slotProps.data.completedAt) : "" }}
                </template>
                <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Message from "primevue/message";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import renderDate from "@/utils/prettyDate";
import { useConfirm } from "primevue/useconfirm";
import AssignmentStatus from "@/enums/assignmentStatus";

export default {
    name: "JUserAssignmentsTable",
    components: {
        DataTable,
        Column,
        Button,
        InputText,
        Dialog,
        InputNumber,
        MultiSelect,
        Tag,
        Calendar,
        Message,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const confirm = useConfirm();
        const store = useStore();
        const isUserAdmin = computed(() => store.getters.hasRoleOrUpper("admin"));
        const assignmentsTable = ref();
        const loading = ref(false);
        const filters = ref({
            fullName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.CONTAINS,
                }],
            },
            assignedAt: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                }],
            },
            kanji: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            completedAt: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.DATE_IS,
                }],
            },
            state: {
                value: null,
                matchMode: FilterMatchMode.IN,
            },
        });
        const queryParams = ref({});
        const totalAssignments = ref(0);
        const assignments = ref([]);
        const statuses = ref(Object.values(AssignmentStatus));
        const renderEntry = (entry) => {
            const param = entry;
            param.state = Object.values(AssignmentStatus)
                .find((state) => entry.state === state.value);
            return param;
        };

        const showStatsError = ref(false);
        const stats = ref({});
        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch("queryUserAssignments", {
                    query: queryParams.value,
                    userId: props.user._id,
                });
                assignments.value = response.data.assignments.map((entry) => renderEntry(entry));
                totalAssignments.value = response.data.totalAssignments;
            } catch (err) {
                assignments.value = [];
            } finally {
                loading.value = false;
            }

            try {
                const res = await store.dispatch("admin/getAssignmentsStats", props.user._id);
                stats.value = res.data;
            } catch (err) {
                showStatsError.value = true;
            }
        };
        const onPageChanged = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onFilter = async () => {
            queryParams.value.filters = filters.value;
            await reloadData();
        };
        const onOpenEntry = (entryId) => {
            router.push({
                name: "admin.entries.detail",
                params: {
                    entryId,
                },
            });
        };

        const isSubmitted = ref(false);
        const submissionSuccess = ref(false);
        const onConfirmDelete = async (assignmentId) => {
            isSubmitted.value = true;
            try {
                await store.dispatch("deleteAssignment", assignmentId);
                await reloadData();
                submissionSuccess.value = true;
            } catch (err) {
                submissionSuccess.value = false;
            } finally {
                confirm.close();
            }
        };
        const onDeleteAssignment = (assignmentId) => {
            confirm.require({
                message: "Sei sicuro di voler eliminare questo assignment? "
                    + "L'operazione non è reversibile",
                header: "Conferma cancellazione",
                icon: "pi pi-exclamation-triangle",
                accept: () => onConfirmDelete(assignmentId),
                reject: () => confirm.close(),
            });
        };
        const assignmentLoading = ref(false);
        const numberOfEntries = ref(1);
        const submitAssignmentSuccess = ref(true);
        const isAssignmentSubmitted = ref(false);
        const isAssignmentLoading = ref(false);
        const showAssignToUserDialog = ref(false);
        const sendAssignToUserDialog = async () => {
            isAssignmentSubmitted.value = false;
            isAssignmentLoading.value = true;
            const assigner = store.getters.loggedUser;
            try {
                await store.dispatch("submitAssignments", {
                    number: numberOfEntries.value,
                    assignerId: assigner._id,
                    assigneeId: props.user._id,
                });
                submitAssignmentSuccess.value = true;
            } catch (err) {
                submitAssignmentSuccess.value = false;
            } finally {
                isAssignmentSubmitted.value = true;
                isAssignmentLoading.value = false;
                showAssignToUserDialog.value = false;
                await reloadData();
                window.scrollTo(0, 0);
            }
        };
        const onAssign = () => {
            showAssignToUserDialog.value = true;
        };
        const closeAssignToUserDialog = () => {
            showAssignToUserDialog.value = false;
        };

        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: assignmentsTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();
        });

        return {
            isUserAdmin,
            assignmentsTable,
            loading,
            totalAssignments,
            assignments,
            filters,
            queryParams,
            isSubmitted,
            submissionSuccess,
            assignmentLoading,
            showAssignToUserDialog,
            submitAssignmentSuccess,
            numberOfEntries,
            isAssignmentSubmitted,
            isAssignmentLoading,
            stats,
            statuses,
            showStatsError,
            onAssign,
            closeAssignToUserDialog,
            sendAssignToUserDialog,
            onDeleteAssignment,
            onPageChanged,
            onSort,
            onFilter,
            onOpenEntry,
            renderDate,
        };
    },
};
</script>
