<template>
    <Dialog :header="header" :visible="visible" :modal="true" :dismissableMask="true"
            @show="name = previousName" @hide="updateIsVisible(false)"
            @update:visible="$emit('update:visible', $event)">
        <form :id="id">
            <div class="p-fluid">
                <div class="p-field">
                    <label :for="id + 'Field'" class="p-sr-only">{{ placeholder }}</label>
                    <InputText
                        :id="id + 'Field'" type="text" v-model="v$.name.$model"
                        :class="{ 'p-invalid': v$.name.$invalid && isSubmitted }"
                        :placeholder="placeholder" @keydown.enter.prevent="emitSubmittedEvent"
                        autofocus/>
                    <small class="p-error p-d-block"
                           v-if="(v$.name.$error || v$.name.$invalid) && isSubmitted">
                        {{ errorMessage }}
                    </small>
                </div>
            </div>
        </form>
        <template #footer>
            <Button type="Submit" :label="submitLabel" :icon="submitIcon" class="p-button-success"
                    @click.prevent="emitSubmittedEvent" :form="id"/>
        </template>
    </Dialog>
</template>

<script>
import { ref } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";

export default {
    name: "JSimplePopupForm",
    components: { Button, Dialog, InputText },
    props: {
        id: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
        },
        header: {
            type: String,
            required: true,
        },
        submitLabel: {
            type: String,
            required: true,
        },
        submitIcon: {
            type: String,
            required: false,
            default: "pi pi-plus",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        previousName: {
            type: String,
            required: false,
            default: "",
        },
        errorMessage: {
            type: String,
            required: false,
            default: "Il campo non può essere vuoto",
        },
    },
    emits: ["submit", "update:visible"],
    setup(props, { emit }) {
        const isSubmitted = ref(false);
        const updateIsVisible = (value) => emit("update:visible", value);

        const name = ref(props.previousName);
        const rules = { name: { required } };
        const v$ = useVuelidate(rules, { name });

        const emitSubmittedEvent = () => {
            isSubmitted.value = true;
            if (name.value.length > 0) {
                emit("submit", name.value);
                isSubmitted.value = false;
                updateIsVisible(false);
                if (props.previousName === "") {
                    name.value = "";
                }
            }
        };

        return {
            updateIsVisible,
            isSubmitted,
            v$,
            emitSubmittedEvent,
        };
    },
};
</script>
