<template>
<Card>
    <template #subtitle>
        <div class="p-d-flex p-ai-center p-flex-wrap">
            {{ renderDate(report.meta.createdAt) }}
            <Tag v-if="report.meta.deletedAt" value="Archiviata"/>
        </div>
    </template>
    <template #content>
        <p class="p-my-0">{{ report.content }}</p>
    </template>
    <template #footer>
        <div class="p-text-right" v-if="!report.meta.deletedAt">
            <Button label="Archivia segnalazione" icon="pi pi-inbox"
                    :loading="isArchiving" @click="showConfirmDialog"/>
        </div>
    </template>
</Card>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { fromSimpleText } from "@/utils/toastFactory";
import renderDate from "@/utils/prettyDate";
import Button from "primevue/button";
import Card from "primevue/card";
import Tag from "primevue/tag";

export default {
    name: "JEntryReportCard",
    components: { Button, Card, Tag },
    emits: ["archivedReport"],
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const store = useStore();
        const confirm = useConfirm();
        const toast = useToast();
        const isArchiving = ref(false);
        const showConfirmDialog = () => {
            confirm.require({
                message: "Sicuro di voler archiviae questa segnalazione? Sono già stati corretti tutti gli errori riportati?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                async accept() {
                    isArchiving.value = true;
                    try {
                        await store.dispatch("archiveReport", props.report._id);
                        emit("archivedReport", props.report._id);
                    } catch (error) {
                        toast.add(
                            fromSimpleText(
                                "error",
                                "Errore",
                                "Si è verificato un problema nell'archiviare la segnalazione, riprova più tardi",
                            ),
                        );
                    }
                    isArchiving.value = false;
                },
            });
        };
        return {
            renderDate,
            isArchiving,
            showConfirmDialog,
        };
    },
};
</script>

<style scoped lang="scss">
    h3 {
        font-size: inherit;
        font-weight: inherit;
        margin: inherit;
    }
    p {
        color: inherit;
    }
    .p-card-subtitle > div {
        font-weight: inherit;
        margin: inherit;
        column-gap: 0.5rem;
    }
</style>
