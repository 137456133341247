<template>
    <main>
        <div class="p-grid no-margin">
            <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-py-5">
                <Card class="p-p-3">
                    <template #title>
                        <h1 class="p-my-0">Cafoscari Jisho Project</h1>
                    </template>
                    <template #content>
                        <h2>Contacts</h2>
                        <div class='support-element'>
                            <div class='info-message'>For inquiries about the Jisho project dissemination</div>
                            <div class='info-block'>
                                <div class='info-name'><i class='pi pi-user'></i> Marcella Mariotti</div>
                                <div class='info-element'><i class='pi pi-home'></i> <a href='https://www.unive.it/data/persone/5591307' target="_blank">Personal page</a></div>
                            </div>
                        </div>

                        <div class='support-element'>
                            <div class='info-message'>If you are having issues with the native apps (Iphone or Android)</div>
                            <div class='info-block'>
                                <div class='info-name'><i class='pi pi-user'></i> Giuseppe Garassino</div>
                                <div class='info-element'><i class='pi pi-envelope'></i> <a href="mailto:info@marguareis.eu">info@marguareis.eu</a></div>  
                                <div class='info-element'><i class='pi pi-home'></i> <a target="_blank" href="https://www.marguareis.eu">www.marguareis.eu</a></div>
                            </div>
                        </div>

                        <div class='support-element'>
                            <div class='info-message'>If you are having issues with the web app or the database</div>
                            <div class='info-block'>
                                <div class='info-name'><i class='pi pi-user'></i> Alessandro Mantelli</div>
                                <div class='info-element'><i class='pi pi-home'></i> <a href='https://www.unive.it/data/persone/7076506' target="_blank">Personal page</a></div>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
    </main>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import Divider from "primevue/divider";
// import Skeleton from "primevue/skeleton";

export default {
    name: "Support",
    components: { Card, Divider },
    setup() {
        const store = useStore();


        onMounted(async () => {

        });
    },
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.7rem;
    color:#656565;
    margin-bottom:1rem;
}
.support-element {
    margin-bottom:2rem;
}
.info-block {
    margin-top:0.5rem;
    border-top:1px solid #efefef;
    padding-top:0.5rem;
}
.info-name,.info-element {
    margin-bottom:0.3rem;
}
</style>
