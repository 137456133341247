<template>
  <div class="p-input-icon-left">
    <i class="pi pi-search" />
    <InputText type="search" v-model="query" placeholder="Cerca una parola..." @keydown.enter.prevent="doSearch" />
  </div>
  <Button class="screen-reader-only" aria-label="Cerca" @click.prevent="doSearch" />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

export default {
  name: 'JSearchBar',
  components: { InputText, Button },
  props: {
    initialQuery: {
      type: String,
    },
  },
  setup(props) {
    const router = useRouter()
    const query = ref(props.initialQuery ?? '')
    const doSearch = () => {
      router.push({
        name: 'search',
        query: {
          q: query.value,
        },
      })
    }
    return {
      query,
      doSearch,
    }
  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/theme/_variables';

$searchBarRounding: 3rem;

.p-inputtext {
  border-radius: $searchBarRounding;
  border: none;
  width: 100%;
  background-color: $shade200;
  padding-left: 1rem;

  &:focus,
  &:hover {
    background-color: $shade300;
  }
}

input[type='search'] {
  -webkit-appearance: textfield;
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.p-input-icon-left {
  width: 100%;
}

.p-button.screen-reader-only {
  position: absolute !important;
  height: 0;
  width: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}
</style>
