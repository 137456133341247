const collaboratorsModule = {
    actions: {
        async getCollaborators(context) {
            return context.rootGetters.getServerConnection.get("/collaborators");
        },
        async queryCollaborators(context, query) {
            return context.rootGetters.getServerConnection.get("/collaborators/query", {
                params: { query },
            });
        },
        async getCollaborator(context, collaboratorId) {
            return context.rootGetters.getServerConnection.get(`/collaborators/${collaboratorId}`);
        },
        async addCollaborator(context, data) {
            return context.rootGetters.getServerConnection.post("/collaborators", data);
        },
        async editCollaborator(context, { collaboratorId, collaborator }) {
            return context.rootGetters.getServerConnection
                .put(`/collaborators/${collaboratorId}`, collaborator);
        },
        async deleteCollaborator(context, collaboratorId) {
            return context.rootGetters.getServerConnection
                .delete(`/collaborators/${collaboratorId}`);
        },
    },
};

export default collaboratorsModule;
