<template>
    <Dialog header="Segnala un errore" :modal="true" :dismissableMask="true"
            :visible="visible" @update:visible="$emit('update:visible', $event)"  @hide="onHide">
        <j-error v-if="submittingError" :error="submittingError"/>
        <div v-else class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <Textarea id="report" v-model="report" rows="4"/>
            </div>
        </div>
        <template #footer>
            <Button label="Invia segnalazione" form="report"
                    v-if="!submittingError"
                    :disabled="(report?.trim().length ?? 0) === 0"
                    :loading="isSubmitting" @click="onReportClicked"/>
        </template>
    </Dialog>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { fromSimpleText } from "@/utils/toastFactory";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Textarea from "primevue/textarea";
import JError from "@/components/JError.vue";

export default {
    name: "JReportDialog",
    components: {
        JError,
        Button,
        Dialog,
        Textarea,
    },
    props: {
        visible: {
            type: Boolean,
        },
        entry: {
            type: Object,
            required: true,
        },
    },
    emits: ["update:visible"],
    setup(props, { emit }) {
        const updateIsVisible = (value) => emit("update:visible", value);

        const store = useStore();
        const toast = useToast();

        const report = ref("");
        const isSubmitting = ref(false);
        const submittingError = ref();
        const onReportClicked = async () => {
            isSubmitting.value = true;
            try {
                await store.dispatch("reportEntry", {
                    entryId: props.entry._id,
                    report: report.value,
                });
                updateIsVisible(false);
                report.value = "";
                toast.add(
                    fromSimpleText(
                        "success",
                        "Segnalazione effettuata",
                        "La tua segnalazione è stata inviata con successo, grazie per l'aiuto!",
                    ),
                );
            } catch (error) {
                submittingError.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore nell'effettuare la segnalazione. Riprova più tardi.",
                };
            }
            isSubmitting.value = false;
        };

        const onHide = () => {
            updateIsVisible(false);
            submittingError.value = undefined;
        };

        return {
            updateIsVisible,
            report,
            isSubmitting,
            submittingError,
            onReportClicked,
            onHide,
        };
    },
};
</script>

<style scoped lang="scss">
    ::v-deep(.p-component) {
        max-width: 90vw;
        overflow-x: hidden;
    }

    .p-multiselect-panel {
        display: block;
        position: static;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 30vh;
        overflow-y: auto;
    }
</style>
