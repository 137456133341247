<template>
    <main>
        <j-loading-bar :loading="isLoadingCollections || isLoadingCollection"/>
        <div class="p-grid no-margin">
            <div class="full-height-scrollable p-col-10 p-offset-1
                        p-md-3 p-md-offset-0 p-py-5 p-ml-md-4"
                :class="selectedCollectionId ? 'desktop-only' : ''">
                <h1>Collezioni</h1>
                <j-collection-creation-button
                    @newCollection="handleNewCollection"
                    @creationError="setCreationError"/>
                <Message v-if="creationError" severity="error" :closable="false">
                    {{ creationError.text }}
                </Message>
                <JCollectionCard v-for="collection in collections" :key="collection._id"
                                 class="p-my-3" :collection="collection"
                                 :class="{ selected: isSelected(collection) }"
                                 @click="selectCollection(collection)"
                                 @renamed="renameCollection(collection._id, $event)"
                                 @removed="removeCollection(collection._id)"/>
            </div>
            <div class="full-width full-height-scrollable
                        p-col-10 p-offset-1 p-md-8 p-ml-md-4 p-py-5 p-pr-md-6"
                 ref="scrollComponent" :onscroll="onScrollComponent">
                <j-error v-if="loadingError" :error="loadingError"/>
                <router-view v-else @removedFromCollection="handleRemovedFromCollection"
                             @loadingStarted="setIsLoadingCollection"
                             @loadingEnded="unsetIsLoadingCollection"
                             @addedToCollection="handleAddedToCollection"
                             @newCollection="handleNewCollection"
                             :shouldLoadMore="shouldLoadMore"></router-view>
            </div>
        </div>
    </main>
</template>

<script>
import {
    computed,
    onMounted,
    onUnmounted,
    ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { remove } from "lodash";
import Message from "primevue/message";
import JCollectionCard from "@/components/collections/JCollectionCard.vue";
import JCollectionCreationButton from "@/components/collections/JCollectionCreationButton.vue";
import JError from "@/components/JError.vue";
import JLoadingBar from "@/components/JLoadingBar.vue";

export default {
    name: "Collections",
    components: {
        Message,
        JCollectionCard,
        JCollectionCreationButton,
        JError,
        JLoadingBar,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();

        const collections = ref([]);
        const isLoadingCollections = ref(false);
        const loadingError = ref();
        const selectedCollectionId = computed(() => route.params.collectionId);
        const isSelected = (collection) => collection._id === route.params.collectionId;
        const selectCollection = async (collection) => {
            if (!isSelected(collection)) {
                const path = {
                    name: "collections.detail",
                    params: { collectionId: collection._id },
                };
                if (route.params.collectionId) {
                    await router.replace(path);
                } else {
                    await router.push(path);
                }
            }
        };
        const isLoadingCollection = ref(false);
        const setIsLoadingCollection = () => { isLoadingCollection.value = true; };
        const unsetIsLoadingCollection = () => { isLoadingCollection.value = false; };

        const removeCollection = (collectionId) => remove(
            collections.value, (collection) => collection._id === collectionId,
        );
        const renameCollection = (collectionId, newName) => {
            const collection = collections.value.find((coll) => coll._id === collectionId);
            if (collection) {
                collection.name = newName;
            }
        };

        const creationError = ref();
        const setCreationError = () => {
            creationError.value = {
                title: "Oh no!",
                text: "Si è verificato un problema nel creare la collezione.",
            };
        };
        const handleNewCollection = (collection) => {
            collections.value.push({
                ...collection,
                count: 0,
            });
        };

        const handleRemovedFromCollection = (collectionId) => {
            const collection = collections.value.find((coll) => coll._id === collectionId);
            if (collection) {
                collection.count -= 1;
            }
        };

        const handleAddedToCollection = (collectionId) => {
            const collection = collections.value.find((coll) => coll._id === collectionId);
            if (collection) {
                collection.count += 1;
            }
        };

        const scrollComponent = ref();
        const shouldLoadMore = ref(0);
        const onScrollComponent = async () => {
            const element = scrollComponent.value;
            if (element?.scrollTop >= element?.scrollHeight - element?.offsetHeight - 200) {
                shouldLoadMore.value += 1;
            }
        };
        const onScrollWindow = async () => {
            const element = scrollComponent.value;
            if (element?.getBoundingClientRect().bottom < window.innerHeight
                && window.innerWidth <= 768) {
                shouldLoadMore.value += 1;
            }
        };
        onMounted(async () => {
            window.addEventListener("scroll", onScrollWindow);
            isLoadingCollections.value = true;
            try {
                collections.value = await store.dispatch("getCollections");
            } catch (error) {
                loadingError.value = {
                    title: "Oh no!",
                    text: "Si è verificato un errore nel caricare le collezioni! Prova a ricaricare la pagina.",
                };
            }
            isLoadingCollections.value = false;
        });
        onUnmounted(() => window.removeEventListener("scroll", onScrollWindow));

        return {
            collections,
            isLoadingCollections,
            loadingError,
            selectCollection,
            selectedCollectionId,
            isSelected,
            isLoadingCollection,
            setIsLoadingCollection,
            unsetIsLoadingCollection,

            renameCollection,
            removeCollection,

            creationError,
            setCreationError,
            handleNewCollection,
            handleRemovedFromCollection,
            handleAddedToCollection,

            scrollComponent,
            onScrollComponent,
            shouldLoadMore,
        };
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables.scss";

    .selected ::v-deep(.p-card) {
        background-color: $shade300;
    }

    .p-card:hover {
        cursor: pointer;
    }

    .full-height-scrollable {
        @media (min-width: $md) {
            height: calc(100vh - 6.6rem);
            overflow-y: auto;
        }
    }

    .desktop-only {
        @media (max-width: $md) {
            display: none;
        }
    }

    .full-width {
        @media (min-width: $md) {
            flex-grow: 1;
        }
    }
</style>
