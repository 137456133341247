const Universities = [
    { name: "Università Ca' Foscari Venezia" },
    { name: "Università Iuav di Venezia" },
    { name: "Università degli Studi di Verona" },
    { name: "Università degli Studi \"Gabriele d'Annunzio\"" },
    { name: "Università degli Studi dell'Aquila" },
    { name: "Università degli Studi di Teramo" },
    { name: "Università degli Studi della Basilicata" },
    { name: "Università degli Studi di Catanzaro \"Magna Græcia\"" },
    { name: "Università degli Studi della Calabria" },
    { name: "Università degli Studi di Reggio Calabria \"Mediterranea\"" },
    { name: "Università degli Studi di Napoli \"Federico II\"" },
    { name: "Università degli Studi di Napoli \"L'Orientale\"" },
    { name: "Università degli Studi di Napoli \"Parthenope\"" },
    { name: "Università degli Studi della Campania Luigi Vanvitelli" },
    { name: "Università degli Studi di Salerno" },
    { name: "Università degli Studi del Sannio" },
    { name: "Alma mater studiorum - Università di Bologna" },
    { name: "Università degli Studi di Ferrara" },
    { name: "Università degli Studi di Modena e Reggio Emilia" },
    { name: "Università degli Studi di Parma" },
    { name: "Università degli Studi di Trieste" },
    { name: "Università degli Studi di Udine" },
    { name: "Università degli Studi di Cassino" },
    { name: "Università degli Studi di Roma \"Foro Italico\"" },
    { name: "Università degli Studi di Roma \"La Sapienza\"" },
    { name: "Università degli Studi di Roma Tor Vergata" },
    { name: "Università degli Studi Roma Tre" },
    { name: "Università degli Studi della Tuscia" },
    { name: "Università degli Studi di Genova" },
    { name: "Università degli Studi di Bergamo" },
    { name: "Università degli Studi di Brescia" },
    { name: "Politecnico di Milano" },
    { name: "Università degli Studi di Milano" },
    { name: "Università degli Studi di Milano-Bicocca" },
    { name: "Istituto Universitario di Studi Superiori" },
    { name: "Università degli Studi di Pavia" },
    { name: "Università degli Studi dell'Insubria" },
    { name: "Università Politecnica delle Marche" },
    { name: "Università degli Studi di Camerino" },
    { name: "Università degli Studi di Macerata" },
    { name: "Università degli Studi di Urbino \"Carlo Bo\"" },
    { name: "Università degli Studi del Molise" },
    { name: "Università degli Studi del Piemonte Orientale \"Amedeo Avogadro\"" },
    { name: "Università degli Studi di Torino" },
    { name: "Politecnico di Torino" },
    { name: "Politecnico di Bari" },
    { name: "Università degli Studi di Bari Aldo Moro" },
    { name: "Università degli Studi di Foggia" },
    { name: "Università del Salento" },
    { name: "Università degli Studi di Cagliari" },
    { name: "Università degli Studi di Sassari" },
    { name: "Università degli Studi di Catania" },
    { name: "Università degli Studi di Messina" },
    { name: "Università degli Studi di Palermo" },
    { name: "Università degli Studi di Firenze" },
    { name: "Scuola Normale Superiore" },
    { name: "Scuola superiore di studi universitari e di perfezionamento Sant'Anna" },
    { name: "Università di Pisa" },
    { name: "Università degli Studi di Siena" },
    { name: "Università per stranieri di Siena" },
    { name: "Università degli Studi di Trento" },
    { name: "Università degli Studi di Perugia" },
    { name: "Università per stranieri di Perugia" },
    { name: "Università degli Studi di Padova" },
    { name: "Università della Valle d'Aosta" },
    { name: "Libera Università di Bolzano" },
    { name: "Università degli Studi di Enna \"Kore\"" },
    { name: "Università degli Studi di scienze gastronomiche" },
    { name: "Libera Università Mediterranea Giuseppe Degennaro" },
    { name: "Università Carlo Cattaneo LIUC" },
    { name: "Libera università di lingue e comunicazione IULM" },
    { name: "Università Cattolica del Sacro Cuore" },
    { name: "Università commerciale Luigi Bocconi" },
    { name: "Università Vita-Salute San Raffaele" },
    { name: "Istituto Clinico Humanitas Università" },
    { name: "Università degli Studi \"Suor Orsola Benincasa\"" },
    { name: "Libera università internazionale degli studi sociali Guido Carli" },
    { name: "Libera Università Maria Santissima Assunta" },
    { name: "Università Campus Bio-Medico" },
    { name: "Università degli Studi Internazionali di Roma" },
    { name: "Università degli Studi Link Campus University" },
    { name: "Università Europea di Roma" },
    { name: "Saint Camillus International University of Health Sciences" },
    { name: "ESCP Business School" },
    { name: "International University College of Turin" },
    { name: "Istituto d'Arte Applicata e Design IAAD" },
    { name: "Istituto Universitario Salesiano di Venezia IUSVE" },
    { name: "Università telematica Giustino Fortunato" },
    { name: "Università telematica \"Italian University Line\"" },
    { name: "Università telematica \"Pegaso\"" },
    { name: "Università telematica e-Campus" },
    { name: "Università telematica \"Guglielmo Marconi\"" },
    { name: "Università degli Studi \"Niccolò Cusano\"" },
    { name: "Università telematica internazionale \"UniNettuno\"" },
    { name: "Università telematica San Raffaele" },
    { name: "Università telematica UNITELMA Sapienza" },
    { name: "Università telematica Universitas Mercatorum" },
    { name: "Università telematica \"Leonardo da Vinci\"" },
];

export default Universities;
