<template>
    <Button
        icon="pi pi-info-circle" aria-label="Aiuto"
        class="p-button-link link-secondary p-ml-2"
        @click="$refs.kanjiHelp.toggle($event)"/>
    <div class="p-sr-only" :id="helpId">
        <p class="help-text" v-if="!isRaw">
            <slot></slot>
        </p>
        <slot v-else></slot>
    </div>
    <OverlayPanel ref="kanjiHelp" ariaCloseIcon="Chiudi" :style="{ 'max-width': '25rem' }">
        <p class="help-text" v-if="!isRaw">
            <slot></slot>
        </p>
        <slot v-else></slot>
    </OverlayPanel>
</template>

<script>
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

export default {
    name: "JHelpButton",
    components: {
        Button,
        OverlayPanel,
    },
    props: {
        helpId: {
            type: String,
        },
        isRaw: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/theme/_variables";

    .p-button-link.link-secondary {
        color: $secondaryButtonBg;
        &:enabled:hover, &:enabled:active {
            color: $secondaryButtonBg;
        }
    }
    .help-text {
        margin: 0;
    }
</style>
