<template>
    <div class="p-fluid p-formgrid p-grid entry-detail">
        <div class="p-field p-col-12 p-lg-6">
            <JLabelHelpButton :forId="`gloss-${senseKey}-term-${itemKey}`"
                              label="Traduzione*">
                Inserisci qui la traduzione del campo semantico.
            </JLabelHelpButton>
            <InputText :id="`gloss-${senseKey}-term-${itemKey}`"
                       :aria-describedby="boolObjectToString({
                            [`gloss-${senseKey}-term-${itemKey}-help`]: true,
                            [`gloss-${senseKey}-term-${itemKey}-err`]: v$.term.$error,
                        })"
                       :class="{ 'p-invalid': v$.term.$error }"
                       :disabled="disabled" type="text" v-model="v$.term.$model"/>
            <small class="p-error" :id="`gloss-${senseKey}-term-${itemKey}-err`"
                   v-if="v$.term.$error">
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'required')">
                    Il campo è obbligatorio.
                </template>
                <template v-if="v$.term.$errors.length > 1">
                    <br>
                </template>
                <template v-if="v$.term.$errors.some((error) => error.$validator === 'hasNoCommas')
                                && v$.term.$model.length > 0">
                    Il campo non può contenere virgole o punti e virgola.
                    Volevi creare una nuova traduzione appartenente allo stesso campo semantico?
                </template>
            </small>
        </div>
        <div class="p-field p-col-12 p-lg-6">
            <JLabelHelpButton :id="`gloss-${senseKey}-type-${itemKey}`"
                              label="Tipo di traduzione">
                Specifica qui se la traduzione è una letterale, una spiegazione, figurativa,
                un marchio registrato, etc.
            </JLabelHelpButton>
            <Dropdown
                :aria-labelledby="`gloss-${senseKey}-type-${itemKey}`"
                :aria-describedby="`gloss-${senseKey}-type-${itemKey}-help`"
                :options="glossTypes" optionLabel="label" optionValue="value"
                placeholder="Generico"
                :optionDisabled="() => disabled" :showClear="!disabled"
                dataKey="value" v-model="item.type"/>
        </div>
    </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import JLabelHelpButton from "@/components/JLabelHelpButton.vue";
import GlossType from "@/enums/glossType";
import boolObjectToString from "@/utils/boolObjectToString";

export default {
    name: "JGlossEditor",
    components: {
        Dropdown,
        InputText,
        JLabelHelpButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        itemKey: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const item = reactive(props.modelValue);
        watch(item, (newItem) => emit("update:modelValue", newItem));

        const hasNoCommas = (value) => !value.includes(",") && !value.includes(";");
        const rules = {
            term: { required, hasNoCommas },
        };

        const v$ = useVuelidate(rules, item);

        const glossTypes = ref(Object.values(GlossType));

        return {
            v$,
            item,
            glossTypes,
            boolObjectToString,
        };
    },
};
</script>
