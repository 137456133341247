<template>
    <Dialog header="Note del campo semantico" :visible="visible"
            @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid" :dismissableMask="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p>
                    Inserisci qui eventuali note aggiuntive su questo campo semantico che non
                    rientrano negli altri campi.
                </p>
            </div>
        </div>
        <JOrderList v-model="notes" :emptyItem="emptyNote" :disabled="disabled"
                    addButtonLabel="Aggiungi nota" :enableInsert="!disabled">
            <template #header="slotProps">
                <h4>{{ `Nota ${slotProps.index + 1}` }}</h4>
            </template>
            <template #item="slotProps">
                <JSenseNoteEditor v-model="slotProps.item" :itemKey="slotProps.key"
                                  :disabled="disabled" :senseKey="senseKey"/>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import JOrderList from "@/components/JOrderList.vue";
import JSenseNoteEditor from "@/components/admin/entries/JSenseNoteEditor.vue";

export default {
    name: "JSenseNotesEditor",
    components: {
        Button,
        Dialog,
        JOrderList,
        JSenseNoteEditor,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "onDialogClose"],
    setup(props) {
        const notes = ref(props.modelValue);

        const v$ = useVuelidate();

        const emptyNote = ref({
            text: null,
        });

        return {
            v$,
            notes,
            emptyNote,
        };
    },
};
</script>
