<template>
    <Card>
        <template #title v-if="!loading">
            <h3 v-if="compilerFullName">{{ titlePrefix }} di {{ compilerFullName }}</h3>
            <h3 v-else>{{ titlePrefix }}</h3>
        </template>
        <template #title v-else>
            <Skeleton height="1.5rem"/>
        </template>
        <template #subtitle v-if="!loading">
            <p>Azione eseguita in data {{ renderDate(historyItem.createdAt) }}</p>
            <p v-if="historyItem.reviewedAt && historyItem.reviewedBy !== historyItem.createdBy"
               class="p-text-italic">
                {{ reviewStatus }}
                <template v-if="historyItem.reviewedBy && reviewerFullName">
                    da {{ reviewerFullName }}
                </template>
                in data {{ renderDate(historyItem.reviewedAt) }}
            </p>
            <p v-else-if="historyItem.reviewStatus === 'pending'" class="p-text-italic">
                Questa modifica è in attesa di revisione.
            </p>
        </template>
        <template #subtitle v-else>
            <Skeleton height="4rem"/>
        </template>
        <template #content>
            <template v-if="historyItem.reviewComment">
                <p class="p-m-0">Il revisore ha lasciato il seguente commento:</p>
                <p class="p-ml-3 p-mt-3 p-mb-3">{{ historyItem.reviewComment }}</p>
            </template>
            <p class="p-m-0" v-if="creation">Questo termine è stato creato.</p>
            <p class="p-m-0" v-else-if="deletion">Questo termine è stato cancellato.</p>
            <template v-else>
                <p class="p-m-0">Cambiamenti dalla versione precedente:</p>
                <ol>
                    <li v-for="(commit, i) of commits" :key="i">
                        {{ prettyCommit(commit) }}
                    </li>
                </ol>
            </template>
        </template>
    </Card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import Skeleton from "primevue/skeleton";
import prettyCommit from "@/utils/prettyCommit";
import renderDate from "@/utils/prettyDate";

export default {
    name: "JEntryHistoryCard",
    components: {
        Card,
        Skeleton,
    },
    props: {
        creation: {
            type: Boolean,
            default: false,
        },
        deletion: {
            type: Boolean,
            default: false,
        },
        historyItem: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();

        const loading = ref(false);
        const compilerFullName = ref(null);
        const reviewerFullName = ref(null);
        const titlePrefix = computed(() => {
            if (props.creation) {
                return "Creazione";
            }
            if (props.deletion) {
                return "Cancellazione";
            }
            return "Modifica";
        });
        const commits = computed(() => props.historyItem.commit);
        const reviewStatus = computed(() => {
            switch (props.historyItem.reviewStatus) {
                case "approved": {
                    return "Approvato";
                }
                case "denied": {
                    return "Rifiutato";
                }
                default: {
                    return null;
                }
            }
        });

        onMounted(async () => {
            loading.value = true;
            try {
                const createdByPromise = props.historyItem.createdBy
                    ? store.dispatch("admin/getUser", props.historyItem.createdBy)
                    : null;
                const reviewedByPromise = props.historyItem.reviewedBy
                    ? store.dispatch("admin/getUser", props.historyItem.reviewedBy)
                    : null;
                // Resolve the two promises in one shot
                const [createdByResponse, reviewedByResponse] = await Promise.all([
                    createdByPromise,
                    reviewedByPromise,
                ]);
                if (createdByResponse) {
                    const { firstName, lastName } = createdByResponse.data;
                    compilerFullName.value = `${firstName} ${lastName}`;
                }
                if (reviewedByResponse) {
                    const { firstName, lastName } = reviewedByResponse.data;
                    reviewerFullName.value = `${firstName} ${lastName}`;
                }
            } catch (err) {
                compilerFullName.value = null;
                reviewerFullName.value = null;
            } finally {
                loading.value = false;
            }
        });

        return {
            loading,
            compilerFullName,
            reviewerFullName,
            titlePrefix,
            commits,
            reviewStatus,
            renderDate,
            prettyCommit,
        };
    },
};
</script>

<style lang="scss" scoped>
    h3 {
        font-size: inherit;
        font-weight: inherit;
        margin: inherit;
    }
    p {
        color: inherit;
    }
    .p-card-subtitle > p {
        font-weight: inherit;
        margin: inherit;
    }
</style>
