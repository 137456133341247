<template>
    <Teleport to="body">
        <div ref="container" :class="containerClass" v-bind="$attrs">
            <transition-group name="p-toast-message" tag="div">
                <j-toast-message
                    v-for="message of messages"
                    :key="message.id"
                    :message="message"
                    @close="remove($event)"/>
            </transition-group>
        </div>
    </Teleport>
</template>

<script>
import Toast from "primevue/toast";
import JToastMessage from "@/components/toast/JToastMessage.vue";

export default {
    name: "JToast",
    extends: Toast,
    components: { JToastMessage },
};
</script>

<style scoped>
.p-toast {
    opacity: 1;
    z-index: 9999;
}
</style>
