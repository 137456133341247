<template>
    <j-error :error="{
        title: 'Oh no!',
        text: 'Non hai i permessi necessari per accedere a questa pagina.',
    }"/>
</template>

<script>
import JError from "@/components/JError.vue";

export default {
    name: "Error403",
    components: { JError },
};
</script>
