<template>
  <main>
    <j-loading-bar :loading="loading"></j-loading-bar>
    <div class="p-grid no-margin">
      <div class="p-col-10 p-offset-1 p-sm-10 p-sm-offset-1 p-md-8 p-md-offset-2 p-py-5" ref="scrollComponent">
        <div class="p-mb-6">
          <j-search-bar :initial-query="$route.query.q ?? ''"></j-search-bar>
        </div>
        <template v-if="results !== null && results.length > 0 && error === null">
          <j-term-card class="p-mb-3" v-for="result of results" :term="result" :key="result._id"></j-term-card>
          <template v-if="resultsOver">
            <j-error
              :error="{
                title: 'Trovato niente?',
                text: 'Ho finito i risultati che potevo mostrarti\n Se non hai ' + 'trovato ciò che ti serve, prova ad affinare la ricerca!',
              }"
            />
          </template>
        </template>
        <j-error v-else-if="error !== null" :error="error" />
        <template v-else-if="results.length === 0 && resultsOver">
          <j-error
            :error="{
              title: 'Oh no!',
              text: 'Non ho trovato niente. Prova a cambiare la tua ricerca!',
            }"
          />
        </template>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import JSearchBar from '@/components/JSearchBar.vue'
import JTermCard from '@/components/JTermCard.vue'
import JLoadingBar from '@/components/JLoadingBar.vue'
import JError from '@/components/JError.vue'

export default {
  name: 'SearchResults',
  components: {
    JError,
    JLoadingBar,
    JTermCard,
    JSearchBar,
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const scrollComponent = ref(null)
    const loading = ref(false)
    const results = ref([])
    const resultsOver = ref(false)
    const currentPage = ref(0)
    const error = ref(null)

    const getResults = async () => {
      loading.value = true
      error.value = null
      try {
        const response = await store.dispatch('searchEntries', {
          query: route.query.q,
          page: currentPage.value + 1,
        })
        if (response.data.length === 0) {
          resultsOver.value = true
        } else {
          currentPage.value += 1
          results.value = results.value.concat(response.data)
        }
      } catch (err) {
        error.value = {
          title: 'Oh no!',
          text: 'Si è verificato un errore durante la ricerca!',
        }
      }
      loading.value = false
    }
    const onQueryChange = async () => {
      if (route.query.q !== undefined && route.query.q.length >= 1) {
        results.value = []
        resultsOver.value = false
        currentPage.value = 0
        await getResults()
      } else {
        error.value = {
          title: 'Oh no!',
          text: 'Digita qualcosa per effettuare una ricerca!',
        }
      }
    }
    const onScroll = async () => {
      const element = scrollComponent.value
      if (element?.getBoundingClientRect().bottom < window.innerHeight && !loading.value && !resultsOver.value) {
        await getResults()
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
      onQueryChange()
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })
    watch(() => route.query, onQueryChange)

    return {
      scrollComponent,
      loading,
      results,
      resultsOver,
      currentPage,
      error,
    }
  },
}
</script>
