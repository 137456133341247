<template>
    <transition-group ref="list" name="orderlist-flip" tag="ol" role="listbox"
                      class="orderlist-list" aria-multiselectable="false"
                      v-if="(modelValue?.length ?? 0) > 0">
        <template v-for="(item, i) of modelValue" :key="item._id">
            <JOrderableSense :modelValue="modelValue[i]"
                             @update:modelValue="onSenseUpdate($event, i)"
                             :index="i" :canMoveUp="canMoveUp(item, i)"
                             :kanji="kanji" :readings="readings"
                             :canMoveDown="canMoveDown(item, i)"
                             :canRemove="(modelValue?.length ?? 0) > minItems"
                             :tempId="tempId" :tags="tags"
                             :disabled="disabled"
                             :partsOfSpeech="partsOfSpeech"
                             @updateTempId="$emit('updateTempId')"
                             @onMoveUp="onMoveUp($event, item, i)"
                             @onMoveDown="onMoveDown($event, item, i)"
                             @onRemove="onRemove($event, item, i)"/>
        </template>
    </transition-group>
    <template v-else>
        <p class="no-items" v-if="!disabled">Nessun campo semantico presente. Aggiungine uno!</p>
        <p class="no-items" v-else>Nessun campo semantico presente.</p>
    </template>
    <div class="p-d-flex p-dir-rev" v-if="enableInsert">
        <Button label="Aggiungi campo semantico" icon="pi pi-plus" class="p-button-success p-m-1"
                :disabled="disabled" @click="onAdd"/>
    </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import Button from "primevue/button";
import JOrderableSense from "@/components/admin/entries/JOrderableSense.vue";
import PartOfSpeech from "@/enums/partOfSpeech";

export default {
    name: "JOrderableSenses",
    components: {
        Button,
        JOrderableSense,
    },
    props: {
        modelValue: {
            type: Array,
        },
        kanji: {
            type: Array,
            required: true,
        },
        readings: {
            type: Array,
            required: true,
        },
        emptyItem: {
            type: Object,
        },
        enableInsert: {
            type: Boolean,
            default: true,
        },
        keyField: {
            type: String,
        },
        minItems: {
            type: Number,
            default: 0,
        },
        tags: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        partsOfSpeech: {
            type: Array,
            default: Object.values(PartOfSpeech),
        },
        tempId: {
            type: String,
            required: true,
        },
        additionalActions: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: ["emptyItemAdded", "itemRemoved", "update:modelValue", "updateTempId"],
    setup(props, { emit }) {
        const confirm = useConfirm();

        const isInternalChange = ref(false);

        const getKey = (item) => item[props.keyField];
        const getPreviousIndex = (item, index) => {
            if (index === 0) {
                return null;
            }
            // Find the last non-locked item before the specified one
            const nonLocked = props.modelValue
                .map((elem, elemIndex) => ({ elem, elemIndex }))
                .filter(({ elemIndex }) => elemIndex < index);
            return nonLocked.length > 0 ? nonLocked[nonLocked.length - 1].elemIndex : null;
        };
        const getNextIndex = (item, index) => {
            if (index === props.modelValue.length - 1) {
                return null;
            }
            // Find the first non-locked item after the specified one
            const nonLocked = props.modelValue
                .map((elem, elemIndex) => ({ elemIndex, elem }))
                .filter(({ elemIndex }) => elemIndex > index);
            return nonLocked.length > 0 ? nonLocked[0].elemIndex : null;
        };
        const canMoveUp = (item, index) => !props.disabled
            && getPreviousIndex(item, index) !== null;
        const canMoveDown = (item, index) => !props.disabled
            && getNextIndex(item, index) !== null;

        const onAdd = () => {
            // Dirty trick to create an immutable copy of the emptyItem prop
            // (which otherwise would be mutable)
            const frozenEmptyItem = JSON.parse(JSON.stringify(props.emptyItem));
            const newSenses = cloneDeep(props.modelValue);
            newSenses.push(frozenEmptyItem);
            isInternalChange.value = true;
            emit("update:modelValue", newSenses);
            emit("updateTempId");
            emit("emptyItemAdded", frozenEmptyItem);
        };
        const onRemove = (event, item, index) => {
            confirm.require({
                message: "Vuoi cancellare questo elemento?",
                header: "Conferma",
                icon: "pi pi-exclamation-triangle",
                accept() {
                    const newSenses = cloneDeep(props.modelValue);
                    newSenses.splice(index, 1);
                    isInternalChange.value = true;
                    emit("itemRemoved", item);
                    emit("update:modelValue", newSenses);
                },
            });
        };
        const onMoveUp = (event, item, index) => {
            const newSenses = cloneDeep(props.modelValue);
            newSenses[index] = newSenses[index - 1];
            newSenses[index - 1] = item;
            isInternalChange.value = true;
            emit("update:modelValue", newSenses);
        };
        const onMoveDown = (event, item, index) => {
            const newSenses = cloneDeep(props.modelValue);
            newSenses[index] = newSenses[index + 1];
            newSenses[index + 1] = item;
            isInternalChange.value = true;
            emit("update:modelValue", newSenses);
        };
        const onSenseUpdate = (event, i) => {
            const newSenses = cloneDeep(props.modelValue);
            newSenses[i] = event;
            emit("update:modelValue", newSenses);
        };

        return {
            getKey,
            canMoveUp,
            canMoveDown,
            onAdd,
            onRemove,
            onMoveUp,
            onMoveDown,
            onSenseUpdate,
        };
    },
};
</script>

<style lang="scss" scoped>
.orderlist-list {
    list-style-type: none;
    counter-reset: senseNumber;
    margin: 0;
    padding: 0;
}
.orderlist-flip-move {
    transition: transform 0.25s ease;
}
.no-items {
    margin: 0;
}
</style>
