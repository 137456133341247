<template>
<div>
    <div class="p-d-flex p-ai-center">
        <h1>Collaboratori</h1>
        <div class="p-ml-auto">
            <Button label="Aggiungi collaboratore" icon="pi pi-plus" class="p-button-success p-m-1"
                    @click="onOpenCollaborator()"/>
        </div>
    </div>
    <DataTable ref="collaboratorsTable" :loading="loading" :value="collaborators"
               :lazy="true" :paginator="true"
               :totalRecords="totalCollaborators" dataKey="_id"
               responsiveLayout="scroll" :row-hover="true"
               v-model:filters="filters" filter-display="menu"
               :rows="10" :rows-per-page-options="[10, 25, 50, 100]"
               @page="onPageChanged" @sort="onSort" @filter="onFilter">
        <Column :exportable="false">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-raised"
                        aria-label="Modifica" @click="onOpenCollaborator(slotProps.data._id)"/>
            </template>
        </Column>
        <Column field="firstName" header="Nome" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per nome"/>
            </template>
        </Column>
        <Column field="lastName" header="Cognome" sortable>
            <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value"
                           class="p-column-filter" placeholder="Cerca per cognome"/>
            </template>
        </Column>
        <Column field="startYear" header="Anno di inizio" sortable dataType="numeric">
            <template #filter="{filterModel}">
                <InputText type="number" v-model="filterModel.value"
                           class="p-column-filter"
                           placeholder="Cerca per anno di inizio collaborazione"/>
            </template>
        </Column>
        <Column field="endYear" header="Anno di fine" sortable dataType="numeric">
            <template #filter="{filterModel}">
                <InputText type="number" v-model="filterModel.value"
                           class="p-column-filter"
                           placeholder="Cerca per anno di fine collaborazione"/>
            </template>
        </Column>
    </DataTable>
</div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";

export default {
    name: "Collaborators",
    components: {
        DataTable,
        Column,
        Button,
        InputText,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const collaboratorsTable = ref();
        const loading = ref(false);
        const filters = ref({
            firstName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            lastName: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.EQUALS,
                }],
            },
            startYear: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
                }],
            },
            endYear: {
                operator: FilterOperator.OR,
                constraints: [{
                    value: null,
                    matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                }],
            },
        });
        const queryParams = ref({});
        const totalCollaborators = ref(0);
        const collaborators = ref([]);

        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch("queryCollaborators", queryParams.value);
                collaborators.value = response.data.collaborators;
                totalCollaborators.value = response.data.totalCollaborators;
            } catch (err) {
                collaborators.value = [];
            }
            loading.value = false;
        };

        const onPageChanged = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };
        const onFilter = async () => {
            queryParams.value.filters = filters.value;
            await reloadData();
        };
        const onOpenCollaborator = (collaboratorId) => {
            if (collaboratorId) {
                router.push({ name: "admin.collaborators.detail", params: { collaboratorId } });
            } else {
                router.push({ name: "admin.collaborators.new" });
            }
        };

        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: collaboratorsTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();
        });

        return {
            collaboratorsTable,
            loading,
            totalCollaborators,
            collaborators,
            filters,
            queryParams,
            onPageChanged,
            onSort,
            onFilter,
            onOpenCollaborator,
        };
    },
};
</script>
